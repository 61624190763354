import React, { useContext } from 'react';
import { Collapse, Form, Row, Col, Button, Select, Input, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useApplicationContext } from '@/context/application';
import { WorkerRestrictEnum } from '@/utils/enum';
import { textParseGender } from '@/utils/mask';

import { StyledCheckbox, StyledCol, Container, StyledColResponsive } from './styles';
import { Company, Sector, Line, Workstation } from './types';
import { ManagementContext } from './context';
import { useWorkersToCSV } from '@/hooks';
import { CSV } from '@/utils/csv';

export function Filter() {
	const [form] = Form.useForm();

	const {
		companies,
		selectCompanyId,
		setSelectCompanyId,
		sectors,
		selectSectorId,
		setSelectSectorId,
		lines,
		selectLineId,
		setSelectLineId,
		workstations,
		setSelectWorkstationId,
		onFilterWorker
	} = useContext(ManagementContext);
	const { organization: masterOrganization } = useApplicationContext();

	const workersToCSV = useWorkersToCSV();

	function resetFieldsForm(field: string): void {
		const options: Record<string, string[]> = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation']
		};

		form.resetFields(options[field] || []);
	}

	function onClearFilter(): void {
		form.resetFields();
		setSelectCompanyId(null);
		setSelectSectorId(null);
		setSelectLineId(null);
		onFilterWorker();
	}

	async function handleOnSubmit(): Promise<void> {
		const values = await form.validateFields();
		onFilterWorker(values);
	}

	async function handleExportToCSV(): Promise<void> {
		const organizationId = masterOrganization?.id;
		if (!organizationId) return;

		const values = await form.validateFields();
		const { company, sector, line, workstation, register_id, name, pcd, gender, restrict } = values || {};

		const params: { [key: string]: any } = {
			organization_id: organizationId,
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(register_id && { register_id }),
			...(name && { name }),
			...(pcd && { pcd }),
			...(gender && { gender }),
			...(restrict && { restrict })
		};

		const paramsMounting = new URLSearchParams(params);
		let query = paramsMounting.toString();

		try {
			const workers = await workersToCSV.mutateAsync(query);

			const normalizedWorkers = Array.isArray(workers) ? workers : [workers];

			let dataToCSV = normalizedWorkers.map(({ register_id, name, restrict, pcd, gender, is_active }: any) => ({
				Register: register_id,
				Name: name,
				Restriction: WorkerRestrictEnum[restrict as keyof typeof WorkerRestrictEnum],
				PWD: pcd ? 'Yes' : 'No',
				Gender: textParseGender(gender),
				Activity: is_active ? 'Active' : 'Disabled'
			}));

			const CSVFile = new CSV();
			CSVFile.download(dataToCSV, `kinebot_${I18n.get('workers')}-${moment().format('DD/MM/YYYY')}`);
		} catch (error: any) {
			const err = error?.response?.data?.message || 'Failed to export CSV';
			message.error(I18n.get(err));
		}
	}

	return (
		<Container>
			<Collapse defaultActiveKey={['1']}>
				<Collapse.Panel header={I18n.get('Filters')} key="1">
					<Form form={form} layout="vertical" onFinish={handleOnSubmit}>
						<Row gutter={[10, 0]}>
							<Col xs={24} sm={6}>
								<Form.Item name="company" label={I18n.get('Industrial site')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectCompanyId(value);
											resetFieldsForm('company');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a industrial site')}
									>
										{companies.map((company: Company) => (
											<Select.Option key={company.id} value={company.id}>
												{company.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="sector" label={I18n.get('Sector')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectSectorId(value);
											resetFieldsForm('sector');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										disabled={!selectCompanyId}
										placeholder={I18n.get('Select a sector')}
									>
										{sectors.map((sector: Sector) => (
											<Select.Option key={sector.id} value={sector.id}>
												{sector.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="line" label={I18n.get('Line')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectLineId(value);
											resetFieldsForm('line');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a line')}
										disabled={!selectSectorId}
									>
										{lines.map((line: Line) => (
											<Select.Option key={line.id} value={line.id}>
												{line.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="workstation" label={I18n.get('Workstation')}>
									<Select
										showSearch
										onChange={setSelectWorkstationId}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a workstation')}
										disabled={!selectLineId}
									>
										{workstations.map((workstation: Workstation) => (
											<Select.Option key={workstation.id} value={workstation.id}>
												{workstation.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item
									name="register_id"
									label={I18n.get('Register')}
									rules={[
										{
											pattern: new RegExp(/^[0-9]+$/),
											message: I18n.get('Please enter a valid registration id')
										},
										{
											min: 7,
											message: I18n.get('The record id must be 7 characters long')
										}
									]}
								>
									<Input placeholder={I18n.get('Register')} maxLength={7} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="name" label={I18n.get('Name')}>
									<Input placeholder={I18n.get('Name')} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="pcd" label={I18n.get('PWD')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										<Select.Option value="true">{I18n.get('Yes')}</Select.Option>
										<Select.Option value="false">{I18n.get('No')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="gender" label={I18n.get('Gender')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										<Select.Option value="MALE">{I18n.get('Male')}</Select.Option>
										<Select.Option value="FEMALE">{I18n.get('Female')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<label>{I18n.get('Status')}</label>
						</Row>
						<Row gutter={[16, 16]}>
							<StyledCol xs={24} sm={12} md={6} lg={4} xl={2}>
								<Form.Item name="active" valuePropName="checked" initialValue={true}>
									<StyledCheckbox className="custom-checkbox-indeterminate">
										<span className="label-checkbox">{I18n.get('Active')}</span>
									</StyledCheckbox>
								</Form.Item>
							</StyledCol>
							<StyledCol xs={24} sm={12} md={6} lg={4} xl={2}>
								<Form.Item name="inactive" valuePropName="checked" initialValue={true}>
									<StyledCheckbox className="custom-checkbox-indeterminate">
										<span className="label-checkbox">{I18n.get('Inactive')}</span>
									</StyledCheckbox>
								</Form.Item>
							</StyledCol>
							<StyledColResponsive xs={24} sm={12} md={6} lg={6} xl={4}>
								<Form.Item name="temporary" valuePropName="checked" initialValue={true}>
									<StyledCheckbox className="custom-checkbox-indeterminate">
										<span className="label-checkbox">{I18n.get('Temporary restriction')}</span>
									</StyledCheckbox>
								</Form.Item>
							</StyledColResponsive>
							<StyledColResponsive xs={24} sm={12} md={6} lg={6} xl={4}>
								<Form.Item name="indefinite" valuePropName="checked" initialValue={true}>
									<StyledCheckbox className="custom-checkbox-indeterminate">
										<span className="label-checkbox">{I18n.get('Indefinite restriction')}</span>
									</StyledCheckbox>
								</Form.Item>
							</StyledColResponsive>
						</Row>

						<Row gutter={8} style={{ paddingTop: '20px' }} justify={'start'}>
							<Col sm={24} md={12}>
								<Row gutter={12}>
									<Col sm={12} xl={7}>
										<Button
											type="primary"
											htmlType="submit"
											style={{ width: '100%' }}
											icon={<SearchOutlined />}
										>
											{I18n.get('Search')}
										</Button>
									</Col>
									<Col sm={12} md={12} xl={7}>
										<Button style={{ width: '100%' }} onClick={onClearFilter}>
											{I18n.get('Clear')}
										</Button>
									</Col>
								</Row>
							</Col>
							<Col sm={24} md={12}>
								<Row justify={'end'}>
									<Col>
										<Button type="link" onClick={handleExportToCSV}>
											{I18n.get('Export CSV')}
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</Collapse.Panel>
			</Collapse>
		</Container>
	);
}
