import React from 'react';
import { Col, Typography } from 'antd';
import { I18n } from '@aws-amplify/core';

export function Header() {
	return (
		<Col span={24}>
			<Typography.Title level={3}>{I18n.get('Files')}</Typography.Title>
		</Col>
	);
}
