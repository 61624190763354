import Api from '@/services/api';
import { GetCompaniesParams, CompanyDTO } from './dto/company.dto';

interface ICompanyService {
	index: (params: GetCompaniesParams) => Promise<CompanyDTO[]>;
}

class CompanyService implements ICompanyService {
	async index(params: GetCompaniesParams): Promise<CompanyDTO[]> {
		const { organization_id } = params;
		const { data } = await Api.get(`/company/user_list/${organization_id}`);
		return data;
	}
}

const Service = Object.freeze(new CompanyService());
export { Service };
