import React, { ReactNode, useContext, createContext, useState } from 'react';
import { Context, Methods, OrganizationDTO, States, ChecklistQueryParams } from './types';
import { useApplicationContext } from '@/context/application';

interface AETCreateProviderProps {
	children: ReactNode;
	previousStep: any;
	nextStep: any;
}

const AETCreateContext = createContext<Context>({} as Context);

const defaultState = (organization_id: string): States => ({
	currentStep: 0,
	loading: false,
	organization_id: organization_id,
	company_id: '',
	sector_id: '',
	data: {
		checklist: []
	},
	queryParams: {
		company_id: '',
		organization_id: '',
		clearFilter: false
	},
	workstation: [],
	analyst: '',
	file_name: '',
	collection_date: [],
	created_at: [],
	selectedChecklist: [],
	organizations: []
});

export function AETCreateProvider({ children, previousStep, nextStep }: Readonly<AETCreateProviderProps>) {
	const { organization } = useApplicationContext();
	const [state, setState] = useState<States>(defaultState(organization!.id));

	function handleLoading(isLoading: boolean): void {
		setState((prev) => ({ ...prev, loading: isLoading }));
	}

	function setOrganizations(organizations: OrganizationDTO[]): void {
		setState((prev) => ({ ...prev, organizations }));
	}

	function setOrganizationId(organization_id: string): void {
		setState((prev) => ({ ...prev, organization_id }));
	}

	function setCompanyId(company_id: string): void {
		setState((prev) => ({ ...prev, company_id }));
	}

	function setSectorId(sector_id: string): void {
		setState((prev) => ({ ...prev, sector_id }));
	}

	function setWorkstation(workstation: Array<string>): void {
		setState((prev) => ({ ...prev, workstation }));
	}

	function setAnalyst(analyst: string): void {
		setState((prev) => ({ ...prev, analyst }));
	}

	function setFileName(file_name: string): void {
		setState((prev) => ({ ...prev, file_name }));
	}

	function setCollectionDate(collection_date: string[]): void {
		setState((prev) => ({ ...prev, collection_date }));
	}

	function setSelectedChecklist(selectedChecklist: any[]): void {
		setState((prev) => ({ ...prev, selectedChecklist }));
	}

	function setData(data: any): void {
		setState((prev) => ({
			...prev,
			data: {
				...prev.data,
				...data
			}
		}));
	}

	function setQueryParams(changedQueryParam: ChecklistQueryParams): void {
		setState((prev) => ({
			...prev,
			queryParams: {
				...changedQueryParam
			}
		}));
	}

	const states: States = state;

	const methods: Methods = {
		previousStep,
		nextStep,
		setData,
		handleLoading,
		setOrganizations,
		setOrganizationId,
		setCompanyId,
		setSectorId,
		setCollectionDate,
		setWorkstation,
		setAnalyst,
		setFileName,
		setSelectedChecklist,
		setQueryParams
	};

	const context: Context = {
		...states,
		...methods
	};

	return <AETCreateContext.Provider value={context}>{children}</AETCreateContext.Provider>;
}

export function useAETCreateContext() {
	const context = useContext(AETCreateContext);
	return context;
}
