import React from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import { useAETCreateContext } from '../../context';

export const Analyst: React.FC = () => {
	const { organization_id, company_id, setAnalyst, queryParams, setQueryParams } = useAETCreateContext();

	function handleAnalystChange(analyst: string) {
		setAnalyst(analyst);
		setQueryParams({
			...queryParams,
			analyst
		});
	}

	return (
		<Form.Item name="analyst">
			<Input
				disabled={!organization_id || !company_id}
				placeholder={I18n.get('Analyst')}
				onChange={(event) => handleAnalystChange(event.target.value)}
			/>
		</Form.Item>
	);
};
