import React, { useContext, useState, useEffect } from 'react';
import * as Icons from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Modal, Row, Col } from 'antd';
import RestrictionsIcon from '@/assets/icons/Restrictions';


import Spinner from '@/components/SpinnerDeprecated';
import { CardButton, CardLabel } from './styles';
import { Workstation, Worker } from '../types';
import { ManagementContext } from '../context';
import Api from '@/services/api';

export function ComplaintOrCertificate() {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [workstationsWorker, setWorkstationsWorker] = useState<string[] | null>(null);

	const style = { fontSize: '55px' };

	const {
		worker,
		workstations,
		complaintOrCertificateModal,
		workstationsByWorker,
		closeComplaintOrCertificateModal,
		showComplaintModal,
		showMedicalCertificateModal,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setWorkstationsByWorker,
		showRestrictionModal
	} = useContext(ManagementContext);

	const organizationId = useSelector((state: any) => state.organization.organization?.id);

	function optionTree({ id, name }: Workstation) {
		return {
			title: name,
			value: id,
			key: id
		};
	}

	function onMountingTree(listWorker: string[] = [], workstations: Workstation[] = []): void {
		const canMountingTree = !complaintOrCertificateModal || !Array.isArray(workstations) || !workstations?.length;

		if (canMountingTree) return;

		if (!Array.isArray(listWorker) || !listWorker?.length) return;

		const treeValue = workstations.filter(({ id }) => listWorker.includes(id)).map(optionTree);

		setWorkstationsByWorker(treeValue);
	}

	function normalizeWorker({ company_id, sector_id, line_id, workstations }: Worker): void {
		setSelectCompanyId(company_id);
		setSelectSectorId(sector_id);
		setSelectLineId(line_id);
		setWorkstationsWorker(workstations);
	}

	async function getWorker(worker: any): Promise<void> {
		if (!complaintOrCertificateModal || !organizationId || !worker) return;
		const url = `/worker/${worker.id}?organization_id=${organizationId}`;
		try {
			setIsLoading(true);
			const { data } = await Api.get(url);
			normalizeWorker(data);
		} catch (errors) {
			console.error(errors);
		} finally {
			setIsLoading(false);
		}
	}
	useEffect(() => {
		getWorker(worker);
	}, [complaintOrCertificateModal, worker]);

	useEffect(() => {
		onMountingTree(workstationsWorker || [], workstations);
	}, [workstations]);

	const Loading = (): JSX.Element => (
		<div style={{ minHeight: '250px' }}>
			<Spinner />
		</div>
	);

	function showComplaint() {
		showComplaintModal(worker, workstationsByWorker);
	}

	function showCertificate() {
		showMedicalCertificateModal(worker, workstationsByWorker);
	}

	function showRestriction() {
		showRestrictionModal(worker, workstationsByWorker);
	}

	function handleOnCancel() {
		closeComplaintOrCertificateModal();
	}

	return (
		<Modal
			width={750}
			footer={null}
			destroyOnClose={true}
			centered
			onCancel={handleOnCancel}
			open={complaintOrCertificateModal}
			title={I18n.get('Select the desired option')}
		>
			{isLoading ? (
				<Loading />
			) : (
				<Row>
					<Col sm={24} style={{ padding: '10px 0px 10px 0px' }}>
						<Row gutter={[8, 8]} justify="center" style={{ padding: '15px 30px 0px 30px' }}>
							<Col lg={8}>
								<CardButton hoverable onClick={showCertificate}>
									<Icons.SnippetsOutlined style={style} />
									<CardLabel>{I18n.get('Medical Certificate')}</CardLabel>
								</CardButton>
							</Col>
							<Col lg={8}>
								<CardButton hoverable onClick={showRestriction}>
									<RestrictionsIcon style={style} />
									<CardLabel>{I18n.get('Restriction')}</CardLabel>
								</CardButton>
							</Col>
							<Col lg={8}>
								<CardButton hoverable onClick={showComplaint}>
									<Icons.FrownOutlined style={style} />
									<CardLabel>{I18n.get('Complaint')}</CardLabel>
								</CardButton>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</Modal>
	);
}
