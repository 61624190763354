import React from 'react';
import { Row, Col } from 'antd';
import { MultipleCassificationList } from './MultipleCassificationList';
import { ResumeReactions } from './ResumeReactions';
import { ResumeEffort } from './ResumeEffort';
import { ResumeVibrations } from './ResumeVibrations';
import { I18n } from '@aws-amplify/core';

export function Resume({ data, openActionPlan }) {
	return (
		<>
			<Row gutter={[24, 24]}>
				<Col span={12}>
					<ResumeEffort data={data} openActionPlan={openActionPlan} />
					<ResumeVibrations data={data} openActionPlan={openActionPlan} />
				</Col>
				<Col span={12}>
					<ResumeReactions data={data} openActionPlan={openActionPlan} />
					<MultipleCassificationList
						title={I18n.get('Complementary factors')}
						openActionPlan={openActionPlan}
						items={[
							{
								name: I18n.get('Percussion tools '),
								classification: data?.percussion
							},
							{
								name: I18n.get('Hit against '),
								classification: data?.hit_against,
								classificationText: {
									1: I18n.get('Without risk'),
									3: I18n.get('Present Risk')
								}[data?.hit_against]
							}
						]}
					/>
				</Col>
			</Row>
		</>
	);
}
