import React from 'react';
import { Menu } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';

import Logo from '@/assets/img/logo.png';
import history from '@/store/history';
import { LogoMenu } from './styles';
import Can from '@/components/Can';
import { Title } from './Title';
import EHSIcon from '@/assets/icons/EHS';
import Icon from '@ant-design/icons/lib/components/Icon';

interface SideMenuProps {
	selectKey: string;
}

export const SideMenu: React.FC<SideMenuProps> = ({ selectKey }) => {
	function onClick({ key }: MenuInfo) {
		history.push(key);
	}

	return (
		<>
			<LogoMenu src={Logo} />
			<Menu
				theme="light"
				mode="inline"
				onClick={onClick}
				selectedKeys={[selectKey]}
				defaultSelectedKeys={[selectKey]}
			>
				{Can('ehs') && (
					<Menu.Item key="/ehs/">
						<Icons.BarChartOutlined />
						<Title>Dashboard</Title>
					</Menu.Item>
				)}
				{Can('engineering') && (
					<Menu.Item key="/engineering/">
						<Icons.VideoCameraOutlined />
						<Title>Dashboard</Title>
					</Menu.Item>
				)}
				<Menu.Item key="/upload">
					<Icons.CloudUploadOutlined />
					<Title>Upload</Title>
				</Menu.Item>
				<Menu.Item key="/files">
					<Icons.FolderOpenOutlined />
					<Title>All files</Title>
				</Menu.Item>
				{Can('engineering') && (
					<Menu.SubMenu icon={<Icons.ToolOutlined />} title={I18n.get('Engenharia Industrial')}>
						<Menu.Item key="/engineering/files">
							<Icons.FolderOpenOutlined />
							<Title>EI Files</Title>
						</Menu.Item>
					</Menu.SubMenu>
				)}
				{Can('ehs') && (
					<Menu.SubMenu icon={<Icon component={EHSIcon} />} title={I18n.get('EHS')}>
						{/* <Menu.Item key="/ehs/files">
							<Icons.FolderOpenOutlined />
							<Title>EHS Files</Title>
						</Menu.Item> */}
						<Menu.Item key="/ehs/reporting/aet">
							<Icons.FileDoneOutlined />
							<Title>EAW</Title>
						</Menu.Item>
						<Menu.Item key="/ehs/action_plan">
							<Icons.ReconciliationOutlined />
							<Title>Action plans</Title>
						</Menu.Item>
						<Menu.SubMenu icon={<Icons.ToolOutlined />} title={I18n.get('Management')}>
							<Menu.Item key="/ehs/appointment">
								<Icons.BarChartOutlined />
								<Title>Appointment</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/detailed">
								<Icons.LineChartOutlined />
								<Title>Detailed</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/workers">
								<Icons.UserOutlined />
								<Title>Workers</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/health-matrix">
								<Icons.MedicineBoxOutlined />
								<Title>{I18n.get('Health Matrix')}</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/complaints">
								<Icons.TableOutlined />
								<Title>{I18n.get('Complaints')}</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/certificates">
								<Icons.DiffOutlined />
								<Title>{I18n.get('Medical Certificates')}</Title>
							</Menu.Item>
						</Menu.SubMenu>
					</Menu.SubMenu>
				)}
				<Menu.SubMenu title={I18n.get('Settings')} icon={<Icons.SettingOutlined />}>
					<Menu.Item key="/settings/account">
						<Icons.InfoCircleOutlined />
						<Title>My account</Title>
					</Menu.Item>
					{Can('ehs') && (
						<>
							{Can('settings', 'preferences') && (
								<Menu.Item key="/settings/preferences">
									<Icons.ControlOutlined />
									<Title>Preferences</Title>
								</Menu.Item>
							)}
							{Can('settings', 'management') && (
								<>
									<Menu.Item key="/settings/manage-users">
										<Icons.TeamOutlined />
										<Title>Manage users</Title>
									</Menu.Item>
									<Menu.Item key="/settings/companies">
										<Icons.PartitionOutlined />
										<Title>Manage companies</Title>
									</Menu.Item>
								</>
							)}
						</>
					)}
				</Menu.SubMenu>
			</Menu>
		</>
	);
};
