import React, { useContext, useState } from 'react';
import moment, { Moment } from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Modal, Select, Input, DatePicker, Button, message } from 'antd';
import { ManagementContext } from '../context';
import { useApplicationContext } from '@/context/application';
import { useCreateWorkerRestriction, CreateWorkerRestrictionDTO } from '@/hooks/useCreateWorkerRestriction';

const { TextArea } = Input;
const FORMAT_DATE = 'DD/MM/YYYY';

export function NewRestriction() {
	const [form] = Form.useForm();
	const { organization, company } = useApplicationContext();
	const { mutateAsync: createWorkerRestriction, isLoading } = useCreateWorkerRestriction();
	const { worker, closeNewRestrictionModal, newRestrictionModal, setTriggerReload } = useContext(ManagementContext);

	const [startDate, setStartDate] = useState<Moment | null>(moment());
	const [isUndeterminated, setIsUndeterminated] = useState<boolean>(false);

	async function onCreate(values: CreateWorkerRestrictionDTO): Promise<void> {
		const { type, description, start_date, end_date } = values;

		const body = {
			type,
			description,
			start_date,
			worker_id: worker!.id,
			company_id: company!.id,
			organization_id: organization!.id,
			...(end_date ? { end_date } : {})
		};

		await createWorkerRestriction(body);
		handleCancel();
	}

	async function handleSubmit() {
		try {
			const values = await form.validateFields();
			await onCreate(values);
		} catch (errInfo: any) {
			message.error(errInfo?.response?.data?.message || 'Oops... Something went wrong');
		}
	}

	function handleCancel() {
		form.resetFields();
		setIsUndeterminated(true);
		closeNewRestrictionModal();
		setTriggerReload();
	}

	return (
		<Modal
			centered
			width={700}
			onCancel={handleCancel}
			open={newRestrictionModal}
			title={I18n.get('New restriction')}
			destroyOnClose={true}
			footer={null}
		>
			<Form
				initialValues={{ start_date: moment() }}
				form={form}
				onFinish={handleSubmit}
				layout="vertical"
				name="create_worker"
			>
				<Row>
					<Col sm={24}>
						<Form.Item
							name="type"
							label={I18n.get('Type of restriction')}
							rules={[
								{
									required: true,
									message: I18n.get('Select an option')
								}
							]}
						>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder={I18n.get('Select the type of restriction')}
								optionFilterProp="children"
								onChange={(e) => {
									setIsUndeterminated(e === 'UNDETERMINED');
								}}
							>
								<Select.Option value="TEMPORARY">{I18n.get('Temporary')}</Select.Option>
								<Select.Option value="UNDETERMINED">{I18n.get('Undetermined')}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row style={{ justifyContent: 'space-between' }}>
					<Col sm={11}>
						<Form.Item
							name="start_date"
							label={I18n.get('Restriction start date')}
							rules={[
								{
									required: true,
									message: I18n.get('Select an start date')
								}
							]}
						>
							<DatePicker
								onChange={(e) => {
									setStartDate(e);
									form.setFieldsValue({ end_date: null });
								}}
								format={FORMAT_DATE}
								placeholder={I18n.get('Date')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					{!isUndeterminated && (
						<Col sm={11}>
							<Form.Item
								name="end_date"
								label={I18n.get('Restriction end date')}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid date')
									}
								]}
							>
								<DatePicker
									format={FORMAT_DATE}
									placeholder={I18n.get('Date')}
									style={{ width: '100%' }}
									disabledDate={(current: any) =>
										current && startDate && current.valueOf() <= startDate!.valueOf()
									}
								/>
							</Form.Item>
						</Col>
					)}
				</Row>
				<Row>
					<Col sm={24}>
						<Form.Item
							name="description"
							label={I18n.get('Description of the restriction')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description')
								}
							]}
						>
							<TextArea
								rows={4}
								showCount
								allowClear
								maxLength={250}
								placeholder={I18n.get('Description of the restriction')}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify={'end'} style={{ paddingTop: '20px' }}>
					<Col sm={24}>
						<Button
							type="primary"
							htmlType="submit"
							loading={isLoading}
							style={{ float: 'right', marginLeft: '2%' }}
						>
							{I18n.get('Save')}
						</Button>
						<Button onClick={handleCancel} style={{ float: 'right' }}>
							{I18n.get('Cancel')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
