import React from 'react';
import { TreeSelect } from 'antd';
import { TreePermissionsProps } from './types';

const { SHOW_PARENT } = TreeSelect;

export function TreePermissions({ value = [], onChange, tree, placeholder }: TreePermissionsProps) {
	const tProps = {
		treeData: tree,
		value,
		onChange,
		placeholder,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		style: {
			width: '100%'
		}
	};

	return (
		<TreeSelect
			{...tProps}
			filterTreeNode={(search: string, item: any) =>
				(item.title as string).toLowerCase().indexOf(search.toLowerCase()) >= 0
			}
		/>
	);
}
