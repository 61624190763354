import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';
import { BusinessInformations, WorkHour } from '@/types/entities';

export type BusinessInformationsResponse = Omit<
	BusinessInformations,
	'id' | 'organization_id' | 'company_id' | 'is_active' | 'created_at' | 'updated_at' | 'deleted_at'
>;
export type WorkHourResponse = Omit<WorkHour, 'id' | 'is_active' | 'created_at' | 'updated_at' | 'deleted_at'>;
export type BusinessInformationsWithWorkHours = BusinessInformationsResponse & { work_hours: WorkHourResponse };

type GetBusinessInformationParams = {
	organization_id?: string;
	company_id?: string;
};

async function getBusinessInformations(
	parameters: GetBusinessInformationParams
): Promise<BusinessInformationsWithWorkHours> {
	const { organization_id, company_id } = parameters;
	const url = `/business-informations/${organization_id}`;
	const { data } = await Api.get<BusinessInformationsWithWorkHours>(url, { params: { company_id } });
	return data;
}

export function useGetBusinessInformations(organization_id: string, company_id: string) {
	return useQuery<BusinessInformationsWithWorkHours, AxiosError<Response>>(
		[QUERY_KEYS.GET_BUSINESS_INFORMATIONS, organization_id, company_id],
		() => getBusinessInformations({ organization_id, company_id }),
		{
			enabled: !!organization_id && !!company_id,
			retry: 1
		}
	);
}
