import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio, Typography } from 'antd';

import { useAETCreateContext } from './context';
import { useGetCompanies } from '@/hooks/useGetCompanies';
import { useApplicationContext } from '@/context/application';

export function LocalApplication() {
	const { setCompanyId } = useAETCreateContext();
	const { organization } = useApplicationContext();
	const { data } = useGetCompanies({ organization_id: organization!.id });

	function handleOnChange(value: string): void {
		setCompanyId(value);
	}

	return (
		<Row>
			<Col span={24}>
				<Typography.Title level={5}>{I18n.get('Unit to be analyzed')}</Typography.Title>
			</Col>
			<Col span={24}>
				<Row>
					<Col sm={24}>
						<p>{I18n.get('Select the unit that will be included in the review')}</p>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="company"
							label={I18n.get('Factory')}
							rules={[{ required: true, message: I18n.get('Select the industrial site to be analyzed') }]}
						>
							<Radio.Group onChange={(e) => handleOnChange(e.target.value)}>
								<Row>
									{data?.map((item) => (
										<Col key={item.id} sm={8}>
											<Radio value={item.id}>{item.name}</Radio>
										</Col>
									))}
								</Row>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
