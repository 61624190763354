import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ContainerImage = styled.div`
	position: relative;
	width: 210px;
	height: 270px;
	border-radius: 6px;
	overflow: hidden;
	margin-right: 20px;
	background-color: #ffffff;

	.ant-skeleton-image {
		width: 210px;
		height: 270px;
	}

	&:hover div {
		opacity: 1;
		visibility: visible;
	}
`;

export const Image = styled.img`
	width: 210px;
	height: 270px;
	border-radius: 6px;
	margin-right: 20px;
	object-fit: cover;
`;

export const ContainerActions = styled.div`
	border-radius: 6px;
	position: absolute;
	padding: 40px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.95);
	display: flex;
	align-items: center;
	justify-content: space-between;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export const EyeOutlinedWrapper = styled(EyeOutlined)`
	font-size: 40px;
	color: #2f54eb;
	cursor: pointer;
`;

export const DeleteOutlinedWrapper = styled(DeleteOutlined)`
	font-size: 40px;
	color: #e74150;
	cursor: pointer;
`;

export const Error = styled.p`
	margin-top: 50%;
	text-align: center;
	color: red;
`;
