import styled from 'styled-components';

export const ContainerUpload = styled.div`
	width: 210px;
	height: 270px;
	background-color: #ffffff;

	border: dashed 2px #2f54eb;
	border-radius: 6px;

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`;

export const ContentUpload = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-size: 18px;
		margin-bottom: 0px;
		margin-top: 10px;
	}
`;
