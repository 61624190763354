import { Ability, AbilityBuilder } from '@casl/ability';
import { RoleUserEnum } from '@/utils/enum';
import store from '@/store';

const { ADMIN, MASTER, SUPERVISOR } = RoleUserEnum;

const EHS = 'ehs';
const ENGINEERING = 'engineering';

const ability = new Ability();
const { can, rules } = new AbilityBuilder();

store.subscribe(() => {
	let auth = store.getState().auth;
	ability.update(defineRulesFor(auth));
});

const defineRulesFor = (auth) => {
	const user = auth.user.user;

	user?.user_groups?.forEach((acl) => {
		if (acl === EHS) {
			can('ehs');

			if (user?.role === ADMIN) {
				can('management', 'action_plans');
				can('settings', 'preferences');
				can('settings', 'management');
				can('create', 'organization');
				can('create', 'company');
				can('delete', 'upload');
			}

			if (user?.role === MASTER) {
				can('management', 'action_plans');
				can('settings', 'preferences');
				can('settings', 'management');
				can('create', 'organization');
				can('management', 'workers');
				can('management', 'users');
				can('create', 'company');
				can('cancel', 'payment');
				can('delete', 'upload');
			}

			if (user?.role === SUPERVISOR) {
				can('management', 'action_plans');
				can('management', 'workers');
			}
		}

		if (acl === ENGINEERING) {
			can('engineering');
		}
	});

	return rules;
};

const isAuthorized = (action, subject) => ability.can(action, subject);
export default isAuthorized;
