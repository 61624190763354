import React, { useRef, useContext } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';

import { HealthMatrixContext } from '../context.js';

ChartJS.register(zoomPlugin);

export const CardChart = styled.div`
	padding: 25px;
	text-align: center;
	border-radius: 5px;
	background-color: white;
	min-height: ${(props) => props.minHeight}vw;
	max-height: ${(props) => props.maxHeight}vw;
`;

export function BarChart({ orientation = 'x', minHeight = 10 }) {
	const chartRef = useRef();
	const { lines, selectBarOnClick } = useContext(HealthMatrixContext);
	const data = lines.data;

	const options = {
		skipNull: true,
		responsive: true,
		indexAxis: orientation,
		maintainAspectRatio: false,
		scales: {
			x: {
				min: 0,
				max: 20,
				grid: {
					display: false
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false
				}
			},
			y: {
				min: 0,
				grid: {
					display: false
				},
				ticks: {
					source: 'data',
					autoSkip: false,
					crossAlign: 'far' // Align labels
				}
			}
		},
		plugins: {
			title: {
				display: true,
				text: I18n.get('Score by line')
			},
			datalabels: {
				display: false
			},
			legend: {
				display: false
			},
			zoom: {
				pan: {
					mode: 'x',
					enabled: true
				}
			}
		}
	};

	return (
		<CardChart minHeight={minHeight}>
			<Bar
				ref={chartRef}
				options={options}
				onClick={(e) => selectBarOnClick(e, chartRef.current, data)}
				data={{
					labels: [...data?.map((item) => item.name)],
					datasets: [
						{
							borderWidth: 1,
							maxBarThickness: 40,
							label: I18n.get('Score'),
							data: [...data?.map((item) => item.score)],
							borderColor: [...data?.map((item) => item.border)],
							backgroundColor: [...data?.map((item) => item.background)]
						}
					]
				}}
			/>
		</CardChart>
	);
}
