import React, { useContext } from 'react';
import { Col, Table, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HealthMatrixContext } from './context';

export function LineList() {
	const { lines } = useContext(HealthMatrixContext);

	const columns = [
		{
			title: I18n.get('Line'),
			dataIndex: 'name',
			key: 'name',
			align: 'left',
			width: '400px'
		},
		{
			title: I18n.get('Complaints with nexus'),
			dataIndex: 'complaint_with_nexus',
			key: 'complaint_with_nexus',
			align: 'center',
			width: '150px',
			sorter: (a, b) => b.complaint_with_nexus - a.complaint_with_nexus
		},
		{
			title: I18n.get('Complaints without nexus'),
			dataIndex: 'complaint_without_nexus',
			key: 'complaint_without_nexus',
			align: 'center',
			width: '150px',
			sorter: (a, b) => b.complaint_without_nexus - a.complaint_without_nexus
		},
		{
			title: I18n.get('Medical certificates'),
			dataIndex: 'medical_certificate',
			key: 'medical_certificate',
			align: 'center',
			width: '100px',
			sorter: (a, b) => b.medical_certificate - a.medical_certificate
		},
		{
			title: I18n.get('Ergonomic grade'),
			dataIndex: 'global_grade',
			key: 'global_grade',
			align: 'center',
			width: '100px',
			sorter: (a, b) => b.global_grade - a.global_grade
		},
		{
			title: (
				<Tooltip
					title={I18n.get(
						'Score value: (complaints with nexus * 50) + (certificates * 10) + ergonomic grade'
					)}
				>
					{`${I18n.get('Score')} `}
					<ExclamationCircleOutlined />
				</Tooltip>
			),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			width: '100px'
		}
	];

	return (
		<Col span={24}>
			<Table
				rowKey="id"
				columns={columns}
				dataSource={lines.data}
				pagination={{ pageSize: 10, showSizeChanger: false }}
				loading={lines.isLoading}
			/>
		</Col>
	);
}
