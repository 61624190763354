import pt_BR from 'antd/es/locale/pt_BR';
import type { Locale } from 'antd/lib/locale-provider';
import { LocaleKey, LocaleKeyEnum } from './types';

export const browserLanguage: string = navigator.language;

const translations: Record<LocaleKey, Locale> = {
	[LocaleKeyEnum.PT_BR]: pt_BR
};

export const localeProvider: Locale = translations[browserLanguage];
