import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';
import { ConfigProvider, Button } from 'antd';
import { MehOutlined } from '@ant-design/icons';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useSelector, useDispatch } from 'react-redux';

import Api from '@/services/api';

import { Header } from './Header';
import { JobInformation } from './JobInformation';
import { Resume } from './Resume';
import { BodyParts } from './BodyParts';
import { Complexity } from './Complexity';
import { JobOrganization } from './JobOrganization';
import { OperatorReport } from './OperatorReport';
import { Actions } from './Actions';
import { previousCurrentStep } from '@/redux/checklist/actions';
import Images from './Images';
import { ModalActionPlan } from './components/ActionPlan/Modal';
import { Comment } from './Comment';
import { ActionPlansCard } from './components/ActionPlan/ListCard';

const customizeRenderEmpty = () => (
	<div style={{ textAlign: 'center', margin: '10px' }}>
		<MehOutlined style={{ fontSize: 20 }} />
		<p>{I18n.get('Data not found')}</p>
	</div>
);

export function Result() {
	const query = useParams();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [results, setResults] = React.useState();
	const [globalGrade, setGlobalGrade] = React.useState(1);

	const [isLoadingGlobalGrade, setIsLoadingGlobalGrade] = React.useState(true);
	const [isLoadingResults, setIsLoadingResults] = React.useState(true);
	const [actionPlanModal, setActionPlanModal] = React.useState(false);

	const checklist = useSelector((state) => state.checklist.checklist);
	const file = useSelector((state) => state.checklist.file);
	const company = useSelector((state) => state.organization.company);
	const organization = useSelector((state) => state.organization.organization);

	function onPrevious() {
		dispatch(previousCurrentStep());
	}

	function closeModal() {
		setActionPlanModal(false);
	}

	function openActionPlan() {
		setActionPlanModal(true);
	}

	function getParameters() {
		let body = {};

		if (query.organization_id) {
			body['organization_id'] = query.organization_id;
		}

		if (query.organization_id && query.company_id) {
			body['company_id'] = query.company_id;
		}

		if (query.file_id) {
			body['file_id'] = query.file_id;
			body['checklist_id'] = checklist.id;
		}

		return body;
	}

	const getGlobalGrade = React.useCallback(() => {
		if (!organization || !company || !file || !checklist) return;

		const url = '/report/checklist/global-grade';

		let body = getParameters();

		if (Object.keys(body).length === 0) {
			body = {
				organization_id: organization.id,
				company_id: company.id,
				file_id: file.id,
				checklist_id: checklist.id
			};
		}

		Api.post(url, body)
			.then((res) => isMounted() && setGlobalGrade(res.data.global_grade))
			.catch((err) => {})
			.finally(() => isMounted() && setIsLoadingGlobalGrade(false));
	}, [organization, company, file, checklist]);

	const getResults = React.useCallback(() => {
		if (!checklist || !company || !organization) return;

		const body = {
			organization_id: organization.id,
			company_id: company.id,
			checklist_id: checklist.id
		};

		Api.post('/report/checklist/results', body)
			.then((res) => isMounted() && setResults(res.data))
			.catch((err) => {})
			.finally(() => isMounted() && setIsLoadingResults(false));
	}, [organization, company, checklist]);

	React.useEffect(() => {
		getResults();
		getGlobalGrade();
	}, [organization, company, checklist, file]);

	const loading = isLoadingGlobalGrade || isLoadingResults;

	if (loading) return <p>{I18n.get('Loading')}</p>;

	return (
		<div>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<Header globalGrade={globalGrade} />
				<Actions />
				<JobInformation />
				<Images data={results?.checklist?.checklist_images} />
				<Resume data={results} openActionPlan={openActionPlan} />
				<BodyParts data={results} openActionPlan={openActionPlan} />
				<Complexity data={results} openActionPlan={openActionPlan} />
				<JobOrganization data={results} openActionPlan={openActionPlan} />
				<OperatorReport />
				<ActionPlansCard file_id={file.id} company_id={company.id} organization_id={organization.id} />
				<Comment
					checklist_id={checklist.id}
					company_id={company.id}
					file_id={file.id}
					disableEditComment={checklist.consolidated}
					organization_id={organization.id}
					initialValue={results.checklist.comment}
				/>
				{!checklist.consolidated && (
					<div style={{ textAlign: 'right' }}>
						<Button size="large" style={{ margin: '0 8px' }} onClick={onPrevious}>
							{I18n.get('Previous')}
						</Button>
					</div>
				)}
				<ModalActionPlan visible={actionPlanModal} onClose={closeModal} />
			</ConfigProvider>
		</div>
	);
}
