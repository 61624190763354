import React, { useContext, useState } from 'react';
import { message, Skeleton, Table, Space, Tooltip, Button, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { useDeleteWorkerRestriction } from '@/hooks/useDeleteWorkerRestriction';
import { useGetWorkerRestrictions } from '@/hooks/useGetRestrictions';
import { WorkerRestrictionListItem } from '@/types/entities/Worker';
import { useApplicationContext } from '@/context/application';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '@/utils/mask';
import { ManagementContext } from '../context';
import { UpdateRestriction } from './Update';
import { NewRestriction } from './Create';
import { Pagination } from '../types';
import {
	StyledModalContentRestriction,
	ModalStyledRestriction,
	StyledTableContainer,
	StyledButtonContainer
} from '../styles';

export function Restriction() {
	const { organization, company } = useApplicationContext();
	const {
		worker,
		restrictionModal,
		closeRestrictionModal,
		showNewRestrictionModal,
		showUpdateRestrictionModal,
		setTriggerReload
	} = useContext(ManagementContext);
	const deleteWorkerRestriction = useDeleteWorkerRestriction();

	const [pagination, setPagination] = useState<Pagination>({
		page: 0,
		limit: 5
	});

	function handleSetOffset(value: number): void {
		setPagination((prev: Pagination) => ({ ...prev, page: value }));
	}

	const {
		data: workerRestrictions,
		isError,
		isLoading
	} = useGetWorkerRestrictions({
		worker_id: worker?.id || '',
		organization_id: organization?.id,
		company_id: company?.id,
		limit: pagination.limit,
		offset: pagination.page > 0 ? (pagination.page - 1) * pagination.limit : 0
	});

	async function handleDeleteRestriction(id: string) {
		try {
			await deleteWorkerRestriction.mutateAsync({
				id,
				organization_id: organization?.id,
				company_id: company?.id
			});

			if (workerRestrictions?.rows?.length === 1) {
				handleSetOffset(1);
			}

			setTriggerReload();
		} catch (err: any) {
			message.error(err?.response?.data?.message || 'Internal server error');
		}
	}

	function onDeleteRestriction(id: string) {
		Modal.confirm({
			title: I18n.get('Do you want to delete this restriction?'),
			icon: <ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			okType: 'danger',
			onOk: () => {
				handleDeleteRestriction(id);
			}
		});
	}

	function handleCancel() {
		closeRestrictionModal();
	}

	function handleNewRestriction() {
		showNewRestrictionModal();
	}

	function handleUpdateRestriction(id: string) {
		showUpdateRestrictionModal(id);
	}

	if (isError) {
		message.error(I18n.get('Failed to fetch reports'));
		return null; // Retornar null para não causar problemas de renderização
	}

	const columns: ColumnsType<WorkerRestrictionListItem> = [
		{
			title: I18n.get('Type of restriction'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			width: '50%',
			render: (type: string) => <span>{I18n.get(capitalizeFirstLetter(type))}</span>
		},
		{
			title: I18n.get('Start date'),
			dataIndex: 'start_date',
			key: 'start_date',
			align: 'center',
			width: '20%',
			render: (date: Date) => (date ? moment(date).format('DD/MM/YYYY') : '-')
		},
		{
			title: I18n.get('End date'),
			dataIndex: 'end_date',
			key: 'end_date',
			align: 'center',
			width: '20%',
			render: (date: Date) => (date ? moment(date).format('DD/MM/YYYY') : '-')
		},
		{
			key: 'action',
			align: 'center',
			width: '10%',
			render: (restriction) => (
				<Space size="middle" style={{ borderLeft: '1px solid #e8e8e8', paddingLeft: '10px' }}>
					<Tooltip placement="bottomRight" title={I18n.get('Update restriction')}>
						<Button
							type="link"
							ghost
							onClick={() => handleUpdateRestriction(restriction.id)}
							icon={<Icons.EditOutlined />}
						/>
					</Tooltip>
					<Tooltip placement="bottomRight" title={I18n.get('Delete restriction')}>
						<Button
							type="link"
							ghost
							onClick={() => onDeleteRestriction(restriction.id)}
							icon={<Icons.DeleteOutlined style={{ color: 'red' }} />}
						/>
					</Tooltip>
				</Space>
			)
		}
	];

	return (
		<>
			<ModalStyledRestriction
				open={restrictionModal}
				centered
				width={700}
				footer={null}
				onCancel={handleCancel}
				title={I18n.get('Restrictions')}
			>
				{isLoading ? (
					<Skeleton />
				) : (
					<>
						{workerRestrictions?.count > 0 ? (
							<>
								<StyledTableContainer>
									<Table
										rowKey="id"
										columns={columns}
										dataSource={workerRestrictions.rows}
										pagination={{
											total: workerRestrictions.count,
											current: pagination.page === 0 ? pagination.page + 1 : pagination.page,
											pageSize: pagination.limit,
											hideOnSinglePage: false,
											defaultPageSize: pagination.limit,
											onChange: handleSetOffset
										}}
									/>
								</StyledTableContainer>
								<StyledButtonContainer onClick={handleNewRestriction}>
									<Icons.PlusCircleFilled className="modal-icon" />
								</StyledButtonContainer>
							</>
						) : (
							<StyledModalContentRestriction onClick={handleNewRestriction}>
								<Icons.PlusCircleFilled className="modal-icon" />
								<p className="modal-title">{I18n.get('Add a new restriction')}</p>
							</StyledModalContentRestriction>
						)}
					</>
				)}
			</ModalStyledRestriction>
			<NewRestriction />
			<UpdateRestriction />
		</>
	);
}
