import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, Steps, Typography, Button, message } from 'antd';

import { Investments } from './Investments';
import { AETCreateProvider } from './context';
import { Checklist } from './Checklist/index';
import { Apresentation } from './Apresentation';
import { QUERY_KEYS } from '@/utils/query-keys';
import { LocalApplication } from './LocalApplication';
import { ProductionVolume } from './ProductionVolume';
import { useApplicationContext } from '@/context/application';
import { SECTOR_ADMINISTRATIVE_TYPE } from '@/utils/constants';

import { useGetBusinessInformations } from '@/hooks/useGetBusinessInformations';
import { useCreateErgonomicWorkAnalysis } from '@/hooks/useCreateErgonomicWorkAnalysis';
import { AdministrativeHour, OperationHour, WorkHour, FormData } from './types';

const { Step } = Steps;
const { useForm } = Form;
const { GET_ERGONOMIC_WORK_ANALYSIS_LIST, GET_COMPANIES } = QUERY_KEYS;

export function ErgonomicAnalysisWorkCreate() {
	const [currentStep, setCurrentStep] = useState<number>(0);

	const history = useHistory();
	const [form] = useForm<FormData>();
	const queryClient = useQueryClient();
	const create = useCreateErgonomicWorkAnalysis();
	const { organization, company } = useApplicationContext();
	const { isLoading, data: businessInformations } = useGetBusinessInformations(organization!.id, company!.id);
	const companiesCache: any = queryClient.getQueryData([GET_COMPANIES], {
		exact: false
	});

	const steps = [
		{
			title: 'Apresentation',
			content: <Apresentation />
		},
		{
			title: 'Local application',
			content: <LocalApplication />
		},
		{
			title: 'Production volume',
			content: <ProductionVolume />
		},
		{
			title: 'Investments',
			content: <Investments />
		},
		{
			title: 'Checklist',
			content: <Checklist />
		}
	];

	function nextStep(): void {
		setCurrentStep((prev) => prev + 1);
	}

	function previousStep(): void {
		setCurrentStep((prev) => prev - 1);
	}

	async function handleValidationStep(): Promise<void> {
		await form.validateFields();
		nextStep();
	}

	function handlePreviousStep(): void {
		previousStep();
	}

	function handleOnSucessCreateReport(): void {
		queryClient.invalidateQueries([GET_ERGONOMIC_WORK_ANALYSIS_LIST]);
		message
			.success(I18n.get('Report created successfully'), 1.5)
			.then(() => message.loading(I18n.get('You are being redirected'), 1))
			.then(() => history.push('/ehs/reporting/aet'));
	}

	const format_date = (date: string, format: string) => moment(date).format(format);

	const group_elements = (array: any, is_op = false) =>
		array?.reduce((acc: any, value: any) => {
			const formatted: any = {
				init: format_date(value?.time[0], 'HH:mm'),
				finish: format_date(value?.time[1], 'HH:mm')
				// round: ''
			};
			if (is_op) formatted['round'] = value.round;
			acc.push(formatted);
			return acc;
		}, []) || [];

	function normalizePayload(values: any) {
		const date_report = format_date(values.date_report, 'DD/MM/YYYY');
		let [month, year] = format_date(values.reference_date, 'MMMM/YYYY').split('/');
		const reference_date = `${I18n.get(month)}/${year}`;
		const [companySelected] = companiesCache.filter((item: any) => item.id === values.company);

		return {
			organization_id: organization!.id,
			company_id: company!.id,
			name: values.report_name,
			checklists: values.checklists,
			volume_production: values.volume_production,
			investments: values.investments,
			direct_hourly: values.direct_hourly,
			indirect_hourly: values.indirect_hourly,
			monthly_payer: values.monthly_payer,
			application_organization: companySelected.name,
			reference_date: reference_date,
			date_report: date_report,
			operational_hours: group_elements(values.operational_hours, true),
			administrative_hours: group_elements(values.administrative_hours),
			organization: {
				company_name: values.company_name,
				fantasy_name: values.fantasy_name,
				cnpj: values.cnpj,
				address: values.address,
				city: values.city,
				state: values.state,
				zipcode: values.zipcode,
				district: values.district,
				cnae: values.cnae,
				risk_degree: values.risk_degree,
				company_activity: values.company_activity
			}
		};
	}

	async function handleOnSubmit(): Promise<void> {
		try {
			const values = await form.getFieldsValue(true);
			const payload = normalizePayload(values);
			await create.mutateAsync(payload);
			handleOnSucessCreateReport();
		} catch (error) {
			message.error(I18n.get('Failed to create report'));
		}
	}

	function handlePeriodoOfTimeParse(startDate: Date, endDate: Date): [Moment, Moment] {
		return [moment(startDate, 'HH:mm'), moment(endDate, 'HH:mm')];
	}

	function getSectorHours(workHours: WorkHour[] = []): [AdministrativeHour[], OperationHour[]] {
		let administrative: AdministrativeHour[] = [];
		let operational: OperationHour[] = [];

		workHours.forEach((value) => {
			const { sector_type, start_time, end_time, work_shift } = value;
			const time = handlePeriodoOfTimeParse(start_time, end_time);
			const round = work_shift ? parseInt(work_shift) : 0;

			sector_type === SECTOR_ADMINISTRATIVE_TYPE
				? administrative.push({ time })
				: operational.push({ round, time });
		});

		operational.sort((a, b) => a.round - b.round);

		return [administrative, operational];
	}

	if (isLoading) {
		return <p>Loading...</p>;
	}

	const [administrative_hours, operational_hours] = getSectorHours(businessInformations?.work_hours);

	const initialValues = {
		...businessInformations,
		administrative_hours,
		operational_hours,
		direct_hourly: 0,
		indirect_hourly: 0,
		monthly_payer: 0
	};

	return (
		<AETCreateProvider nextStep={nextStep} previousStep={previousStep}>
			<Form form={form} initialValues={initialValues} layout="vertical">
				<Row justify="center" align="middle">
					<Col span={16}>
						<Row justify="center" align="middle" gutter={[0, 24]}>
							<Col span={24} style={{ textAlign: 'center' }}>
								<Typography.Title level={4}>{I18n.get('Ergonomic analysis of work')}</Typography.Title>
							</Col>
							<Col span={18}>
								<Row>
									<Col span={24}>
										<Steps progressDot size="small" current={currentStep}>
											{steps.map((item) => (
												<Step progressDot key={item.title} title={I18n.get(item.title)} />
											))}
										</Steps>
									</Col>
								</Row>
							</Col>
							<Col span={24} style={{ minHeight: '350px' }}>
								{steps[currentStep].content}
							</Col>
							<Col span={24}>
								<Row justify="center" gutter={[10, 0]}>
									{currentStep > 0 && (
										<Col>
											<Button onClick={handlePreviousStep} disabled={create.isSuccess}>
												{I18n.get('Previous')}
											</Button>
										</Col>
									)}
									{currentStep < steps.length - 1 && (
										<Col>
											<Button type="primary" onClick={handleValidationStep}>
												{I18n.get('Next')}
											</Button>
										</Col>
									)}
									{currentStep === steps.length - 1 && (
										<Col>
											<Button
												type="primary"
												onClick={handleOnSubmit}
												loading={create.isLoading}
												disabled={create.isLoading || create.isSuccess}
											>
												{I18n.get('Finish')}
											</Button>
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</AETCreateProvider>
	);
}
