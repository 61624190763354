import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import moment from 'moment';

export type ChecklistQueryParams = {
	company_id: string;
	organization_id: string;
	sector_id?: string;
	workstation_id?: Array<string>;
	analyst?: string;
	file_name?: string;
	collection_date?: string[];
	created_at?: string[];
	clearFilter?: boolean;
};

export interface AetChecklistDTO {
	id: string;
	key: string;
	name: string;
	url?: string;
	checklist_id: string;
	original_name: string;
	collection_date: string;
	workstation_name: string;
}

async function getChecklists(queryParams: ChecklistQueryParams, page = 0, limit = 10) {
	let baseUrl = '/report/checklist?';
	const url = setUrl(baseUrl, queryParams, page, limit);
	const { data } = await Api.get<{ total: number; result: AetChecklistDTO[] }>(url);

	return data;
}

export const useGetChecklist = (queryParams: ChecklistQueryParams, page?: number, limit?: number) => {
	const { organization_id, clearFilter } = queryParams;

	const list = useQuery<{ total: number; result: AetChecklistDTO[] }, Error>({
		queryKey: ['checklist', [organization_id, clearFilter, page, limit]],
		queryFn: () => getChecklists(queryParams, page, limit),
		retry: 0,
		refetchOnWindowFocus: false
	});
	return {
		...list,
		data: list.data ?? { total: 0, result: [] }
	};
};

function setUrl(baseUrl: string, queryParams: ChecklistQueryParams, page: number, limit: number) {
	const { collection_date, company_id, organization_id, sector_id, workstation_id, analyst, file_name } = queryParams;

	let params = `organization_id=${organization_id}&offset=${page}&limit=${limit}`;

	if (company_id) {
		params = `${params}&company_id=${company_id}`;
	}

	if (sector_id) {
		params = `${params}&sector_id=${sector_id}`;
	}

	if (workstation_id) {
		workstation_id.forEach((id: string) => (params = `${params}&workstation_id=${id}`));
	}

	if (analyst) {
		params = `${params}&analyst=${analyst}`;
	}

	if (file_name) {
		params = `${params}&file_name=${file_name}`;
	}

	if (collection_date) {
		const [start, end] = collection_date;
		const start_date = moment(start, 'DD-MM-YYYY HH:mm:ss').format();
		const end_date = moment(end, 'DD-MM-YYYY HH:mm:ss').format();
		params = `${params}&start_date=${start_date}&end_date=${end_date}`;
	}

	return baseUrl + params;
}
