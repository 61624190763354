import React, { useState, useEffect, useContext } from 'react';
import { Form, Modal, Select, Button, Input } from 'antd';
import { I18n } from '@aws-amplify/core';
import { TreePermissions } from './TreePermissions';
import { ManagementContext } from './context';
import { FormValues, Company, Sector, Line, Workstation, TreeData } from './types';

const { Option } = Select;

export function Create() {
	const {
		companies,
		selectCompanyId,
		setSelectCompanyId,
		sectors,
		selectSectorId,
		setSelectSectorId,
		lines,
		selectLineId,
		setSelectLineId,
		workstations,
		workerCreateModal,
		closeWorkerCreateModal,
		onCreateWorker,
		isLoadingWorkerCreate
	} = useContext(ManagementContext);

	const [form] = Form.useForm<FormValues>();
	const [tree, setTree] = useState<TreeData[]>([]);

	function resetFieldsForm(field: string): void {
		const options: Record<string, string[]> = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation']
		};
		form.resetFields(options[field] || []);
	}

	function onMountingTree(workstations: Workstation[] = []): void {
		if (workstations.length === 0) return;
		const treeData: TreeData[] = workstations.map(({ id, name }) => ({
			title: name,
			value: id,
			key: id
		}));

		setTree(treeData);
	}

	function onCloseModal(): void {
		closeWorkerCreateModal();
		form.resetFields();
		setTree([]);
	}

	function onSubmit(): void {
		form.validateFields()
			.then((values) => onCreateWorker({ ...values, form }))
			.catch((errInfo) => {});
	}

	useEffect(() => {
		onMountingTree(workstations);
	}, [workstations]);

	const Footer = () => (
		<>
			<Button key="cancel" onClick={onCloseModal}>
				{I18n.get('Cancel')}
			</Button>
			<Button key="submit" type="primary" onClick={onSubmit} loading={isLoadingWorkerCreate}>
				{I18n.get('Save')}
			</Button>
		</>
	);

	return (
		<Modal
			width={650}
			footer={<Footer />}
			destroyOnClose={true}
			onCancel={onCloseModal}
			open={workerCreateModal}
			title={I18n.get('Create new worker')}
		>
			<Form form={form} layout="vertical" name="create_worker">
				<Form.Item
					name="register_id"
					label={I18n.get('Register')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid registration id'),
							pattern: new RegExp(/^[0-9]+$/)
						},
						{
							min: 7,
							message: I18n.get('Value should be less than 7 character')
						}
					]}
				>
					<Input placeholder={I18n.get('Register')} maxLength={7} />
				</Form.Item>
				<Form.Item
					name="name"
					label={I18n.get('Name')}
					rules={[
						{
							required: true,
							message: I18n.get('Please enter a valid name')
						}
					]}
				>
					<Input placeholder={I18n.get('Name')} />
				</Form.Item>
				<Form.Item
					name="pcd"
					label={I18n.get('PWD')}
					rules={[
						{
							required: true,
							message: I18n.get('Select an option')
						}
					]}
				>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder={I18n.get('Select an option')}
						optionFilterProp="children"
					>
						<Select.Option value="true">{I18n.get('Yes')}</Select.Option>
						<Select.Option value="false">{I18n.get('No')}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="gender"
					label={I18n.get('Gender')}
					rules={[
						{
							required: true,
							message: I18n.get('Select an option')
						}
					]}
				>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder={I18n.get('Select an option')}
						optionFilterProp="children"
					>
						<Select.Option value="MALE">{I18n.get('Male')}</Select.Option>
						<Select.Option value="FEMALE">{I18n.get('Female')}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="restrict"
					label={I18n.get('Restrict')}
					rules={[
						{
							required: true,
							message: I18n.get('Select an option')
						}
					]}
				>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder={I18n.get('Select an option')}
						optionFilterProp="children"
					>
						<Select.Option value="TEMPORARY">{I18n.get('Temporary')}</Select.Option>
						<Select.Option value="INDETERMINATE">{I18n.get('Indeterminate')}</Select.Option>
						<Select.Option value="NOT_RESTRICT">{I18n.get('Not restrict')}</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					name="company"
					label={I18n.get('Industrial site')}
					rules={[
						{
							required: true,
							message: I18n.get('Select a industrial site')
						}
					]}
				>
					<Select
						style={{ width: '100%' }}
						onChange={(value) => {
							setSelectCompanyId(value);
							resetFieldsForm('company');
						}}
						placeholder={I18n.get('Select a industrial site')}
					>
						{companies.map((company: Company, index: any) => (
							<Option key={index} value={company.id}>
								{company.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				{selectCompanyId && (
					<Form.Item
						name="sector"
						label={I18n.get('Sector')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a sector')
							}
						]}
					>
						<Select
							style={{ width: '100%' }}
							onChange={(value) => {
								setSelectSectorId(value);
								resetFieldsForm('sector');
							}}
							placeholder={I18n.get('Select a industrial site')}
						>
							{sectors.map((sector: Sector, index: any) => (
								<Option key={index} value={sector.id}>
									{sector.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				)}
				{selectCompanyId && selectSectorId && (
					<Form.Item
						name="line"
						label={I18n.get('Line')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a line')
							}
						]}
					>
						<Select
							style={{ width: '100%' }}
							onChange={(value) => {
								setSelectLineId(value);
								resetFieldsForm('line');
							}}
							placeholder={I18n.get('Select a line')}
						>
							{lines.map((line: Line, index: any) => (
								<Option key={index} value={line.id}>
									{line.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				)}
				{selectCompanyId && selectSectorId && selectLineId && (
					<Form.Item
						name="workstations"
						label={I18n.get('Workstation')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a workstation')
							}
						]}
					>
						<TreePermissions
							tree={tree}
							placeholder={I18n.get('Select a workstation')}
							value={form.getFieldValue('workstations')}
							onChange={(value: any) => form.setFieldsValue({ workstations: value })}
						/>
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
}
