import React, { Fragment } from 'react';
import { I18n } from '@aws-amplify/core';
import Dropzone from 'react-dropzone';
import { DropContainer, ContainerMessage, TitleUpload } from './styles';
import {
	AiOutlineCloudUpload,
	AiOutlineWarning,
	AiOutlineSmile,
} from 'react-icons/ai';

const LIMIT_SIZE = 2 * 1024 * 1024 * 1024;

export function Drop({ onUpload, disabled }) {
	const acceptMimeType = [
		'video/mp4',
		'video/avi',
		'video/MP2T',
		'video/mpeg',
		'video/3gpp',
		'video/x-flv',
		'video/x-ms-wmv',
		'video/quicktime',
		'video/x-msvideo',
		'video/x-matroska',
		'application/x-mpegURL',
	];

	function renderFeedback(isDragActive, isDragReject) {
		if (!isDragActive) {
			return (
				<ContainerMessage style={{ flexDirection: 'column' }}>
					<AiOutlineCloudUpload
						size={40}
						color={!disabled ? '#3956f8' : '#999999'}
						style={{ alignSelf: 'center' }}
					/>
					{I18n.get('Add files or drag')}
				</ContainerMessage>
			);
		}

		if (isDragReject && !disabled) {
			return (
				<ContainerMessage>
					<AiOutlineWarning
						size={40}
						color="#deb009"
						style={{ alignSelf: 'center' }}
					/>
					{I18n.get('Unsupported file')}
				</ContainerMessage>
			);
		}

		return (
			<ContainerMessage>
				<AiOutlineSmile
					size={40}
					color="#3AC430"
					style={{ alignSelf: 'center' }}
				/>
				{I18n.get('Drop files here')}
			</ContainerMessage>
		);
	}

	// accept="video/*,.avi"

	return (
		<Fragment>
			<TitleUpload>{I18n.get('Send video')}</TitleUpload>
			<Dropzone
				minSize={0}
				onDrop={onUpload}
				disabled={disabled}
				maxSize={LIMIT_SIZE}
				accept={acceptMimeType}
			>
				{({
					getRootProps,
					getInputProps,
					isDragActive,
					isDragReject,
					fileRejections,
				}) => (
					<DropContainer {...getRootProps()}>
						<input {...getInputProps()} />
						{renderFeedback(
							isDragActive,
							isDragReject,
							fileRejections
						)}
					</DropContainer>
				)}
			</Dropzone>
		</Fragment>
	);
}
