import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

interface GetLinesDTO {
	organization_id?: string;
	company_id?: string;
	sector_id?: string;
}

interface LineDTO {
	id: string;
	name: string;
	is_active: boolean;
	created_at: string;
	updated_at: string;
	createdAt: string;
	updatedAt: string;
	sector_id: string;
}

async function getLines({ organization_id, company_id, sector_id }: GetLinesDTO): Promise<LineDTO[]> {
	let url = `/line/${organization_id}/${company_id}/${sector_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetLines = (params: GetLinesDTO) => {
	return useQuery(['get-lines', params], () => getLines(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.sector_id
	});
};
