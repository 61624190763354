import Api from '@/services/api';
import type { ErrorResponse } from '@/types';
import type { ForgotPasswordParams, ForgotPasswordSuccess } from './dto/auth.dto';

interface IAuthService {
	forgotPassword: (params: ForgotPasswordParams) => Promise<ForgotPasswordSuccess | ErrorResponse>;
}

class AuthService implements IAuthService {
	async forgotPassword(params: ForgotPasswordParams): Promise<ForgotPasswordSuccess | ErrorResponse> {
		const { data } = await Api.post('/auth/forgot-password', params);
		return data;
	}
}

const Service = Object.freeze(new AuthService());
export { Service };
