import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import hooks from '../../../hooks';

import { CreateProduct } from './CreateProduct';
import { SelectProduct } from './SelectProduct';
import { EditProduct } from './EditProduct';

const { useGetProducts } = hooks;

export const ManageProduct = () => {
	const [productName, setProductName] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const { organizationId, companyId, workstationId } = useManageOrganization();

	const {
		data: products,
		isError: errorGettingProducts,
		isLoading: gettingProducts,
		isFetching: fetchingProducts,
	} = useGetProducts(organizationId, companyId, workstationId);

	function handleIsEditing(editing: boolean) {
		setIsEditing(editing);
	}

	function handleProductNameChange(name: string) {
		setProductName(name);
	}

	if (errorGettingProducts) {
		return I18n.get('Oops... Something went wrong!');
	}

	if (fetchingProducts || gettingProducts) {
		return `${I18n.get('Loading')}...`;
	}

	return products.length === 0 ? (
		<CreateProduct />
	) : isEditing ? (
		<EditProduct
			productName={productName}
			onProductNameChange={handleProductNameChange}
			onEditing={handleIsEditing}
		/>
	) : (
		<SelectProduct
			products={products}
			productName={productName}
			onProductNameChange={handleProductNameChange}
			onEditing={handleIsEditing}
		/>
	);
};
