import React from 'react';
import { Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import {
	ContainerImage,
	Image as Img,
	ContainerActions,
	EyeOutlinedWrapper,
	DeleteOutlinedWrapper,
	Error
} from './styles';

type Props = {
	file: {
		id: string;
		uri: string;
	};
	isLoading: boolean;
	onImagePreview: (uri: string) => void;
	onDelete: (uri: string) => void;
	error: string | null;
};

export const Image: React.FC<Props> = ({ file, isLoading, onImagePreview, onDelete, error }) => {
	if (error) {
		return (
			<ContainerImage>
				<Error>{I18n.get(error)}</Error>
			</ContainerImage>
		);
	}

	if (isLoading) {
		return (
			<ContainerImage>
				<Skeleton.Image active />
			</ContainerImage>
		);
	}

	return (
		<ContainerImage>
			<Img src={file.uri} />
			<ContainerActions>
				<EyeOutlinedWrapper onClick={() => onImagePreview(file.uri)} />
				<DeleteOutlinedWrapper onClick={() => onDelete(file.id)} />
			</ContainerActions>
		</ContainerImage>
	);
};
