import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

export type OrganizationDTO = {
	id: string;
	name: string;
	fantasy_name: string;
	cnae: string;
	cnpj: string;
	isActive: boolean;
	url_logo: string;
	createdAt: Date;
	updatedAt: Date;
};

export type GetOrganizationDTO = {
	organization_id?: string;
};

async function getOrganizations(params: GetOrganizationDTO) {
	const url = `/organization/${params?.organization_id}`;
	const { data } = await Api.get<any>(url);
	return data;
}

export function useGetOrganizations(params: GetOrganizationDTO) {
	const { organization_id } = params;
	const organizations = useQuery<any, Error>(
		['organizations_list', organization_id],
		() => getOrganizations(params),
		{ enabled: !!organization_id }
	);
	return {
		...organizations,
		data: organizations.data ?? []
	};
}
