import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Worker } from '@/types/entities';
import Api from '@/services/api';

interface GetWorkersDTO {
	organization_id?: string;
	worker_type?: 'MANAGER' | 'REGULAR';
}

async function getWorkers({ organization_id, worker_type }: GetWorkersDTO): Promise<Worker[]> {
	let url = `/worker`;
	const { data } = await Api.get(url, {
		params: {
			worker_type,
			organization_id
		}
	});
	return data;
}

export const useGetWorkers = (params: GetWorkersDTO) => {
	return useQuery([QUERY_KEYS.GET_WORKERS, params], () => getWorkers(params), {
		enabled: !!params.organization_id
	});
};
