import React, { SVGProps } from 'react';

function FilesReviewIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" fill="none" viewBox="0 0 27 33" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M15.696 5.346V6.71a.228.228 0 01-.228.227H4.55a.228.228 0 01-.228-.227V5.346c0-.125.102-.228.228-.228h10.918c.125 0 .228.103.228.228zM4.776 24.112H.91a.909.909 0 01-.91-.91V.91C0 .407.407 0 .91 0h18.198c.503 0 .91.407.91.91v7.05h3.867c.503 0 .91.406.91.91v10.918a.228.228 0 01-.228.228h-1.592a.228.228 0 01-.228-.228v-9.781H6.824v20.018h6.597c.125 0 .227.102.227.227v1.593a.228.228 0 01-.227.227H5.687a.909.909 0 01-.91-.91v-7.05zm0-2.047H2.048V2.047h15.924V7.96H5.687a.909.909 0 00-.91.91v13.195zm15.697-8.76v1.365a.228.228 0 01-.228.228H9.326a.228.228 0 01-.227-.228v-1.365c0-.125.102-.227.227-.227h10.92c.124 0 .227.102.227.227zM9.099 17.4c0-.125.102-.228.227-.228h5.232c.125 0 .228.103.228.228v1.365a.228.228 0 01-.228.227H9.326a.228.228 0 01-.227-.227V17.4zM23.44 28.836l2.653 2.653a.225.225 0 010 .319l-.881.881a.229.229 0 01-.16.066.221.221 0 01-.159-.066l-2.693-2.693a5.004 5.004 0 111.24-1.16zm-7.177-3.02a3.183 3.183 0 003.185 3.185 3.183 3.183 0 003.185-3.184 3.183 3.183 0 00-3.185-3.185 3.183 3.183 0 00-3.185 3.184z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}
export default FilesReviewIcon;
