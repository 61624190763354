import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export interface CreateWorkerRestrictionDTO {
	company_id: string;
	organization_id: string;
	start_date: string;
	worker_id: string;
	description: string;
	type: string;
	end_date?: string;
}

export interface WorkerRestrictionCreate {
	id: string;
}

type CreateWorkerRestrictionResponse = {
	status: string;
	message: string;
	data: WorkerRestrictionCreate;
};

async function createCreateWorkerRestriction(
	params: CreateWorkerRestrictionDTO
): Promise<CreateWorkerRestrictionResponse> {
	const url = `/worker-restriction`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateWorkerRestriction = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: CreateWorkerRestrictionDTO) => createCreateWorkerRestriction(prams), {
		onSuccess: ({ message: txt }: CreateWorkerRestrictionResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_RESTRICTIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_WORKERS]);
			message.success(I18n.get(txt));
		}
	});
};
