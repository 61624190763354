import styled from 'styled-components';

export const Container = styled.div`
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%); /* for IE 9 */
	-webkit-transform: translate(-50%, -50%); /* for Safari */

	width: 600px;
	height: auto;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

	button {
		width: 100%;
	}

	p {
		display: flex;
		color: #737373;
		font-size: 19px;
		margin-top: 16px;
		justify-content: space-between;
	}
`;

export const ContainerImage = styled.div`
	display: flex;
	cursor: pointer;
	text-align: center;
	margin-bottom: 24px;
	align-items: center;
	justify-content: center;

	img {
		width: 35%;
		padding-top: 3%;
		padding-bottom: 4%;
	}

	span {
		font-size: 16px;
		font-weight: 700;
		display: inline-block;
		text-transform: uppercase;
		vertical-align: text-bottom;
	}
`;
