import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
	width: 30px;
	height: 30px;
	background-color: #2f54eb;
	border-radius: 2px;
	padding: 4px;
	box-shadow: 1px 1px 5px 0px #00000026;
	svg {
		color: #fff;
	}

	&:disabled,
	&:disabled:hover {
		background-color: #8c8c8c;
		opacity: 0.4;

		svg {
			color: #d9d9d9;
		}
	}
`;

export const RoundedModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 15px;
	}

	.ant-modal-header {
		border-radius: 15px 15px 0 0;
		border-bottom: none;
	}

	.ant-modal-close-x svg {
		color: #C6C6C4;
		height: 11px;
		width: 11px;
	}
`;
