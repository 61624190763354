import React from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Input } from 'antd';
import { useAETCreateContext } from '../../context';

export const FileName: React.FC = () => {
	const { organization_id, company_id, setFileName, queryParams, setQueryParams } = useAETCreateContext();

	function handleFileNameChange(file_name: string) {
		setFileName(file_name);
		setQueryParams({
			...queryParams,
			file_name
		});
	}

	return (
		<Form.Item name="file_name">
			<Input
				disabled={!organization_id || !company_id}
				placeholder={I18n.get('File Name')}
				onChange={(event) => handleFileNameChange(event.target.value)}
			/>
		</Form.Item>
	);
};
