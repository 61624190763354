import styled from 'styled-components';

export const ChildrenContainer = styled.div`
  background: #fff;
  position: relative;
  margin-top: 15px;
  min-height: 70vh;
  padding: 10px;
`;

export const ContainerChildren = styled.div`
  position: relative;
  margin-top: 15px;
  min-height: 70vh;
  padding: 10px;
`;
