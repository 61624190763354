import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/ergonomic-work-analysis';
import { FilterParams } from '@/infra/services/dto/ergonomic-work-analysis.dto';

export const useGetErgonomicWorkAnalysis = (params: FilterParams) => {
	return useQuery([QUERY_KEYS.GET_ERGONOMIC_WORK_ANALYSIS_LIST, params], () => Service.index(params), {
		enabled: !!params.organization_id
	});
};
