import Types from './types';

const initState = {
	current_step: 0,
	checklist: null,
	file: {
		id: null,
		consolidated: false
	},
	effort: [],
	reaction: [],
	vibration: [],
	sector: null,
	line: null,
	workstation: null,
	collection_date: null,
	percussion: 0,
	hit_against: false,
	displacement: 1,
	wrist: {
		not_analyze: false,
		enable: false,
		file: null
	},
	regulation_notes: {
		interval: false,
		task_diversification: false,
		missing_material: false,
		work_intensity: 1
	},
	control_difficulty: {
		difficult_information: false,
		difficult_dexterity: false,
		long_attention: false
	},
	operator_report: null,
	companyMosaic: null
};

const checklistReducer = (state = initState, action) => {
	switch (action.type) {
		case Types.SET_COMPANY_MOSAIC: {
			return {
				...state,
				companyMosaic: action.payload
			};
		}

		case Types.SET_INFORMATIONS_CHECKLIST: {
			return {
				...state,
				sector: action.payload.sector,
				line: action.payload.line,
				workstation: action.payload.workstation,
				collection_date: action.payload.collection_date
			};
		}

		case Types.SET_CONSOLIDATED_CHECKLIST: {
			return {
				...state,
				checklist: {
					...state.checklist,
					consolidated: action.payload
				}
			};
		}

		case Types.SET_STEP_CHECKLIST: {
			return {
				...state,
				current_step: action.payload
			};
		}

		case Types.CLEAR_STATE_CHECKLIST: {
			return initState;
		}

		case Types.NEXT_STEP_CHECKLIST: {
			return {
				...state,
				current_step: state.current_step + 1
			};
		}

		case Types.PREVIOUS_STEP_CHECKLIST: {
			return {
				...state,
				current_step: state.current_step - 1
			};
		}

		case Types.SET_CHECKLIST: {
			const {
				id,
				effort,
				reaction,
				vibration,
				percussion,
				control_difficulty,
				displacement,
				interval,
				hit_against,
				missing_material,
				task_diversification,
				work_intensity,
				operator_report,
				consolidated,
				workstation,
				sector,
				file_hands,
				collection_date,
				line,
				checklist_images,
			} = action.payload || {};

			return {
				...state,
				checklist_images,
				checklist: {
					id: id,
					consolidated: consolidated !== null ? consolidated : state.checklist.consolidated
				},
				...(effort && { effort: effort }),
				...(reaction && { reaction: reaction }),
				...(vibration && { vibration: vibration }),
				...(percussion && { percussion: percussion }),
				...(control_difficulty && {
					control_difficulty: control_difficulty
				}),
				...(displacement && { displacement: displacement }),
				...(hit_against && { hit_against: hit_against }),
				...(operator_report && { operator_report: operator_report }),
				...(sector && { sector: sector }),
				...(line && { line: line }),
				...(workstation && { workstation: workstation }),
				...(collection_date && { collection_date: collection_date }),
				...(file_hands && {
					wrist: {
						not_analyze: false,
						enable: true,
						// not_analyze: file_hands ? false : true,
						// enable: file_hands ? true : false,
						file: {
							id: file_hands?.id,
							name: file_hands?.original_name
						}
					}
				}),
				regulation_notes: {
					...state.regulation_notes,
					interval: interval || state.regulation_notes.interval,
					missing_material: missing_material || state.regulation_notes.missing_material,
					task_diversification: task_diversification || state.regulation_notes.task_diversification,
					work_intensity: work_intensity != null ? work_intensity : state.regulation_notes.work_intensity
				}
				// regulation_notes: {
				// 	...state.regulation_notes,
				// 	...(interval !== null && { interval }),
				// 	...(missing_material !== null && { missing_material }),
				// 	...(task_diversification !== null && { task_diversification }),
				// 	...(work_intensity !== null && { work_intensity })
				// }
			};
		}

		case Types.SET_FILE_CHECKLIST: {
			return {
				...state,
				file: action.payload
			};
		}

		case Types.ADD_EFFORT_CHECKLIST: {
			return { ...state, effort: action.payload };
		}

		case Types.REMOVE_EFFORT_CHECKLIST: {
			return {
				...state,
				effort: state.effort.filter((item) => item.id !== action.payload)
			};
		}

		case Types.ADD_REACTION_CHECKLIST: {
			return { ...state, reaction: action.payload };
		}

		case Types.REMOVE_REACTION_CHECKLIST: {
			return {
				...state,
				reaction: state.reaction.filter((item) => item.id !== action.payload)
			};
		}

		case Types.ADD_VIBRATION_CHECKLIST: {
			return { ...state, vibration: action.payload };
		}

		case Types.REMOVE_VIBRATION_CHECKLIST: {
			return {
				...state,
				vibration: state.vibration.filter((item) => item.id !== action.payload)
			};
		}

		case Types.ADD_PERCUSSION_CHECKLIST: {
			return { ...state, percussion: action.payload };
		}

		case Types.ADD_HIT_AGAINST_CHECKLIST: {
			return { ...state, hit_against: action.payload };
		}

		case Types.ADD_REGULATION_NOTES_CHECKLIST: {
			return { ...state, regulation_notes: action.payload };
		}

		case Types.ADD_OPERATOR_REPORT_CHECKLIST: {
			return {
				...state,
				operator_report: action.payload
			};
		}

		case Types.ADD_CONTROL_DIFFICULTY_CHECKLIST: {
			return {
				...state,
				control_difficulty: action.payload
			};
		}

		case Types.ADD_DISPLACEMENT_CHECKLIST: {
			return {
				...state,
				displacement: action.payload
			};
		}

		case Types.ADD_WRIST_CHECKLIST: {
			return {
				...state,
				wrist: {
					...state.wrist,
					...action.payload
				}
			};
		}

		default:
			return state;
	}
};

export default checklistReducer;
