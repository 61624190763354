import Api from '@/services/api';
import {
	CompanyDTO,
	CompanyResponse,
	CreateBodyData,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	LineDTO,
	OrganizationDTO,
	OrganizationResponse,
	ProductDTO,
	SectorDTO,
	UpdateOrganizationRequest,
	UpdateRequest,
	WorkstationDTO,
} from './types';

type UrlAndBody = {
	url: string;
	mappedBody: CreateBodyData;
};

export class ManageOrganizationsService {
	public async fetchOrganizations() {
		const baseUrl = '/organization';
		const { data } = await Api.get<OrganizationDTO[]>(baseUrl);
		return data;
	}

	public async fetchCompanies(organizationId: string) {
		const baseUrl = `/company?organization_id=${organizationId}`;
		const { data } = await Api.get<CompanyDTO[]>(baseUrl);
		return data;
	}

	public async fetchSectors(organizationId: string, companyId: string) {
		const baseUrl = `/sector/${organizationId}/${companyId}`;
		const { data } = await Api.get<SectorDTO[]>(baseUrl);
		return data;
	}

	public async fetchLines(organizationId: string, companyId: string, sectorId: string) {
		const baseUrl = `/line/${organizationId}/${companyId}/${sectorId}`;
		const { data } = await Api.get<LineDTO[]>(baseUrl);
		return data;
	}

	public async fetchWorkstations(
		organizationId: string,
		companyId: string,
		lineId: string
	) {
		const baseUrl = `/workstation/${organizationId}/${companyId}/${lineId}`;
		const { data } = await Api.get<WorkstationDTO[]>(baseUrl);
		return data;
	}

	public async fetchProducts(
		organizationId: string,
		companyId: string,
		workstationId: string
	) {
		const baseUrl = `/product/${organizationId}/${companyId}/${workstationId}`;
		const { data } = await Api.get<ProductDTO[]>(baseUrl);
		return data;
	}

	public async createOrganization(body: CreateOrganizationRequest) {
		const url = '/organization';
		const { data } = await Api.post<OrganizationResponse>(url, body);
		return data;
	}

	public async updateOrganization(body: UpdateOrganizationRequest) {
		const { organization_id } = body;
		const url = `/organization/${organization_id}`;
		const { data } = await Api.put<OrganizationResponse>(url, body);
		return data;
	}

	public async deleteOrganization({ organization_id }: DeleteOrganizationRequest) {
		const url = `/organization/${organization_id}`;
		const { data } = await Api.delete<OrganizationResponse>(url);
		return data;
	}

	public async create(body: CreateRequest) {
		const { url, mappedBody } = this.setCreateUrlAndBody(body);
		const { data } = await Api.post<CompanyResponse>(url, mappedBody);
		if (data.data) {
			return data.data;
		}
		return data;
	}

	public async update(body: UpdateRequest) {
		const url: string = this.setUpdateUrl(body);
		const { data } = await Api.put<any>(url, body);
		return data;
	}

	public async delete(body: DeleteRequest) {
		const url: string = this.setDeleteUrl(body);
		const { data } = await Api.delete<any>(url);
		return data;
	}

	private setDeleteUrl(bodyParams: DeleteRequest) {
		const {
			organization_id,
			company_id,
			sector_id,
			line_id,
			workstation_id,
			product_id,
		} = bodyParams;

		if (product_id) {
			return `/product/${organization_id}/${company_id}/${product_id}`;
		}

		if (workstation_id) {
			return `/workstation/${organization_id}/${company_id}/${workstation_id}`;
		}

		if (line_id) {
			return `/line/${organization_id}/${company_id}/${line_id}`;
		}

		if (sector_id) {
			return `/sector/${organization_id}/${company_id}/${sector_id}`;
		}

		return `/company/${organization_id}/${company_id}`;
	}

	private setUpdateUrl(bodyParams: UpdateRequest) {
		const { company_id, sector_id, line_id, workstation_id, product_id } = bodyParams;

		if (product_id) {
			return `/product/${product_id}`;
		}

		if (workstation_id) {
			return `/workstation/${workstation_id}`;
		}

		if (line_id) {
			return `/line/${line_id}`;
		}

		if (sector_id) {
			return `/sector/${sector_id}`;
		}

		return `/company/${company_id}`;
	}

	private setCreateUrlAndBody(bodyParams: CreateRequest): UrlAndBody {
		const { organization_id, company_id, sector_id, line_id, workstation_id } =
			bodyParams;

		const {
			company_name,
			sector_name,
			line_name,
			workstation_name,
			workstation_ghe,
			product_name,
		} = bodyParams;

		if (workstation_id && product_name) {
			return {
				url: '/product/create',
				mappedBody: {
					organization_id,
					company_id,
					workstation_id,
					name: product_name,
				},
			};
		}

		if (line_id && workstation_name) {
			return {
				url: '/workstation/create',
				mappedBody: {
					organization_id,
					company_id,
					line_id,
					name: workstation_name,
					ghe: workstation_ghe,
				},
			};
		}

		if (sector_id && line_name) {
			return {
				url: '/line/create',
				mappedBody: {
					organization_id,
					company_id,
					sector_id,
					name: line_name,
				},
			};
		}

		if (company_id && sector_name) {
			return {
				url: '/sector/create',
				mappedBody: {
					organization_id,
					company_id,
					name: sector_name,
				},
			};
		}

		return {
			url: '/company/create',
			mappedBody: {
				organization_id,
				name: company_name || '',
			},
		};
	}
}
