import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input } from 'antd';

import { Filter } from './Filter';
import { TableList } from './TableList';
import { useAETCreateContext } from '../context';
import { useGetChecklist } from '@/hooks/useGetChecklist';

export function Checklist() {
	const form = Form.useFormInstance();
	const [page, setPage] = useState<number>(0);
	const [limit, setLimit] = useState<number>(10);

	const {
		organization_id,
		queryParams,
		data: { checklist },
		setQueryParams,
		setAnalyst,
		setFileName,
		setSectorId,
		setWorkstation,
		setCollectionDate
	} = useAETCreateContext();

	const {
		data: checklistList,
		isFetching,
		refetch
	} = useGetChecklist(
		{
			...queryParams,
			organization_id,
			company_id: form.getFieldValue('company')
		},
		page,
		limit
	);

	function handlePaginationChange(currentPage: number, pageSize: number) {
		setPage(currentPage - 1);
		setLimit(pageSize);
	}

	function handleFilter() {
		const element = document.getElementById('tableList');
		element?.scrollIntoView({ behavior: 'smooth' });
		setQueryParams({ ...queryParams, clearFilter: false });
		refetch();
	}

	function clearFilter() {
		form.resetFields(['analyst', 'file_name', 'sector_id', 'created_at', 'workstation', 'collection_date']);
		resetAllFilters();
		setQueryParams({
			organization_id: organization_id,
			company_id: form.getFieldValue('company'),
			clearFilter: true
		});
	}

	function resetAllFilters() {
		setAnalyst('');
		setFileName('');
		setSectorId('');
		setWorkstation([]);
		setCollectionDate([]);
	}

	return (
		<Col lg={24}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Filter onFilter={handleFilter} onClearFilter={clearFilter} />
				</Col>
				<Col span={24} id="tableList">
					<Form.Item
						name="checklists_list"
						rules={[
							() => ({
								validator() {
									return !checklist || checklist.length === 0 || !checklist[0]
										? Promise.reject(I18n.get('Select a file'))
										: Promise.resolve();
								}
							})
						]}
					>
						<TableList
							isLoading={isFetching}
							total={checklistList.total}
							checkList={checklistList.result}
							onPaginationChange={handlePaginationChange}
						/>
					</Form.Item>
					<Form.Item name="checklists" hidden>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
}
