import React from 'react';
import { I18n } from '@aws-amplify/core';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Button, notification } from 'antd';

import { useForgotPassword } from '@/hooks/useForgotPassword';
import Logo from '@/assets/img/logo.png';
import * as S from './styles';

const { useForm } = Form;

interface FormData {
	email: string;
}

export function ForgotPassword() {
	const [form] = useForm<FormData>();
	const forgotPassword = useForgotPassword();

	function handleNotificationSuccess(): void {
		notification.success({
			message: I18n.get('Operation performed successfully'),
			description: I18n.get('Check your email inbox'),
			duration: 0
		});
	}

	function handleNotificationError(message = ''): void {
		notification.error({
			message: I18n.get('Oops... Something went wrong!'),
			description: I18n.get(message)
		});
	}

	async function handleOnFinish(): Promise<void> {
		try {
			const values = await form.validateFields();
			await forgotPassword.mutateAsync(values);
			handleNotificationSuccess();
		} catch (error: any) {
			const message = error.response.data.message || error.message;
			handleNotificationError(message);
		}
	}

	return (
		<S.Container>
			<S.ContainerImage>
				<img alt="logo" src={Logo} />
			</S.ContainerImage>
			<Form form={form}>
				<Row gutter={[0, 6]}>
					<Col span={24}>
						<h1>{I18n.get('Forgot your password?')}</h1>
					</Col>
					<Col span={24}>
						<p>{I18n.get('We will send you an email to retrieve your password, rest assured :)')}</p>
					</Col>
					<Col span={24}>
						<Form.Item
							name="email"
							style={{ paddingTop: '25px' }}
							rules={[
								{
									type: 'email',
									message: I18n.get('Please enter a valid email address')
								},
								{
									required: true,
									message: I18n.get('Enter an email')
								}
							]}
						>
							<Input size="large" placeholder="E-mail" prefix={<UserOutlined />} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Button
							size="large"
							type="primary"
							onClick={handleOnFinish}
							loading={forgotPassword.isLoading}
							disabled={forgotPassword.isLoading}
						>
							{I18n.get('Send')}
						</Button>
					</Col>
					<Col span={24}>
						<p style={{ fontSize: '17px' }}>
							<a style={{ float: 'right' }} href="/login">
								{I18n.get('Back to login')}
							</a>
						</p>
					</Col>
				</Row>
			</Form>
		</S.Container>
	);
}
