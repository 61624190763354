import React, { useContext, useState } from 'react';
import moment from 'moment';
import { message, Table } from 'antd';
import { I18n } from '@aws-amplify/core';
import type { ColumnsType } from 'antd/es/table';

import { Pagination } from '../types';
import { ManagementContext } from '../context';
import { CustomTableSkeleton } from './CustomSkeleton';
import { useApplicationContext } from '@/context/application';
import { WorkerHistoryListItem } from '@/types/entities/Worker';
import { useGetWorkerHistory } from '@/hooks/useGetWorkerHisory';
import { ModalStyledRestriction, StyledTableContainer } from '../styles';

export function History() {
	const { organization } = useApplicationContext();
	const { worker, employeeHistoryModal, closeEmployeeHistoryModal } = useContext(ManagementContext);

	const [pagination, setPagination] = useState<Pagination>({
		page: 0,
		limit: 5
	});

	function handleSetOffset(value: number): void {
		setPagination((prev: Pagination) => ({ ...prev, page: value }));
	}

	const {
		data: workerHistory,
		isError,
		isLoading
	} = useGetWorkerHistory({
		id: worker?.id || '',
		organization_id: organization?.id,
		limit: pagination.limit,
		offset: pagination.page > 0 ? (pagination.page - 1) * pagination.limit : 0
	});

	if (isError) {
		message.error(I18n.get('Failed to fetch reports'));
		return null;
	}

	const columns: ColumnsType<WorkerHistoryListItem> = [
		{
			title: I18n.get('Start date'),
			dataIndex: 'start_date',
			key: 'start_date',
			align: 'center',
			width: '20%',
			render: (date: Date) => (date ? moment(date).format('DD/MM/YYYY') : '-')
		},
		{
			title: I18n.get('End date'),
			dataIndex: 'end_date',
			key: 'end_date',
			align: 'center',
			width: '20%',
			render: (date: Date) => (date ? moment(date).format('DD/MM/YYYY') : 'N/A')
		},
		{
			title: I18n.get('Activity'),
			dataIndex: 'activity',
			key: 'activity',
			align: 'center',
			width: '20%',
			render: (type: string) => <span>{I18n.get(type)}</span>
		},
		{
			title: I18n.get('Details'),
			dataIndex: 'details',
			key: 'details',
			align: 'center',
			width: '40%',
			render: (details: string) => <span>{I18n.get(details)}</span>
		}
	];

	return (
		<ModalStyledRestriction
			open={employeeHistoryModal}
			centered
			width={1000}
			footer={null}
			onCancel={closeEmployeeHistoryModal}
			title={I18n.get('Employee history')}
		>
			<StyledTableContainer>
				{isLoading ? (
					<CustomTableSkeleton />
				) : workerHistory && workerHistory.rows ? (
					<Table
						rowKey="id"
						columns={columns}
						dataSource={workerHistory.rows}
						pagination={{
							total: workerHistory.count,
							current: pagination.page === 0 ? pagination.page + 1 : pagination.page,
							pageSize: pagination.limit,
							hideOnSinglePage: false,
							defaultPageSize: pagination.limit,
							onChange: handleSetOffset
						}}
					/>
				) : (
					<p>{I18n.get('No history available')}</p>
				)}
			</StyledTableContainer>
		</ModalStyledRestriction>
	);
}
