import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

interface GetWorkstationsDTO {
	organization_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
}

interface WorkstationDTO {
	id: string;
	name: string;
	count: any;
	unity: any;
	custo: any;
	code: any;
	ghe: string;
	obs: any;
	is_active: boolean;
	created_at: string;
	updated_at: string;
	createdAt: string;
	updatedAt: string;
	line_id: string;
}

async function getWorkstations(params: GetWorkstationsDTO): Promise<WorkstationDTO[]> {
	const { organization_id, company_id, line_id } = params;
	let url = `/workstation/${organization_id}/${company_id}/${line_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetWorkstations = (params: GetWorkstationsDTO) => {
	const { organization_id, company_id, sector_id, line_id } = params;
	return useQuery(['get-workstations', params], () => getWorkstations(params), {
		enabled: !!organization_id && !!company_id && !!sector_id && !!line_id
	});
};
