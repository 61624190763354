import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { NameInput } from '../styles';

const { useFormInstance } = Form;

export function CreateProduct() {
	const [productName, setProductName] = useState('');

	const { validateFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		workstationId,
		creatingOrganizationData,
		isEditing,
		createOrganizationData,
		handleProduct,
		treatFieldValidation,
	} = useManageOrganization();

	async function handleCreateProduct() {
		try {
			await validateFields(['product_name']);
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				workstation_id: workstationId,
				product_name: productName,
			});

			setProductName('');
			handleProduct(created.id, setFieldValue);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	function handleProductNameChange(name: string) {
		setProductName(name);
	}

	return (
		<Col span={24}>
			<Row align="bottom" gutter={[4, 0]}>
				<Col span={20}>
					<NameInput
						disabled={isEditing}
						name="product_name"
						label={I18n.get('Create product')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name'),
							},
						]}
					>
						<Input
							disabled={isEditing}
							placeholder={I18n.get('Product name')}
							onChange={(e) => handleProductNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={2}>
					<Button
						disabled={isEditing}
						loading={creatingOrganizationData}
						type="primary"
						htmlType="submit"
						onClick={() => handleCreateProduct()}
					>
						{I18n.get('Create')}
					</Button>
				</Col>
			</Row>
		</Col>
	);
}
