import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

export type UpdateChecklistEhsCommentDTO = {
	file_id: string;
	comment: string;
	company_id: string;
	organization_id: string;
	checklist_id: string;
};

async function updateChecklistEhsComment(params: UpdateChecklistEhsCommentDTO): Promise<any> {
	const url = `/report/checklist/comment`;
	const { data } = await Api.put(url, params);
	return data;
}

export const useUpdateChecklistEhsComment = () => {
	return useMutation(updateChecklistEhsComment);
};
