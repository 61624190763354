import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { PlusCircleFilled } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';
import { Button } from '@/components/ui/Button';

const { Title } = Typography;

export function Header() {
	const history = useHistory();

	function handleOnCreate(): void {
		history.push('/ehs/reporting/aet/create');
	}

	return (
		<Row>
			<Col span={12}>
				<Title level={4}>{I18n.get('Ergonomic analysis of work')}</Title>
			</Col>
			<Col span={12}>
				<Button
					type="primary"
					onClick={handleOnCreate}
					icon={<PlusCircleFilled />}
					style={{ display: 'inline', float: 'right' }}
				>
					{I18n.get('Create')}
				</Button>
			</Col>
		</Row>
	);
}
