import { useQuery } from '@tanstack/react-query';
import { WorkerRestrictionListItem } from '@/types/entities';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

interface GetWorkerRestrictionsDTO {
	organization_id?: string;
	company_id?: string;
	worker_id: string;
	limit: number;
	offset: number;
}

interface WorkerRestrictionDTO {
	rows: WorkerRestrictionListItem[];
	count: number;
}

async function getWorkerRestrictions({
	worker_id,
	organization_id,
	company_id,
	limit,
	offset
}: GetWorkerRestrictionsDTO): Promise<WorkerRestrictionDTO> {
	let url = `/worker-restriction/${worker_id}`;
	const { data } = await Api.get(url, {
		params: {
			company_id,
			organization_id,
			limit,
			offset
		}
	});
	return data;
}

export const useGetWorkerRestrictions = (params: GetWorkerRestrictionsDTO) => {
	return useQuery([QUERY_KEYS.GET_RESTRICTIONS, params], () => getWorkerRestrictions(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.worker_id
	});
};
