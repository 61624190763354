import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export interface UpdateWorkerRestrictionDTO {
	id: string;
	type: string;
	worker_id: string;
	end_date?: string;
	company_id: string;
	start_date: string;
	description: string;
	organization_id: string;
}

interface UpdateWorkerRestrictionSuccess {
	status: string;
	message: string;
	data: {
		worker_restriction: string;
	};
}

async function updateWorkerRestriction({
	id,
	type,
	end_date,
	start_date,
	company_id,
	description,
	organization_id
}: UpdateWorkerRestrictionDTO): Promise<UpdateWorkerRestrictionSuccess> {
	const url = `/worker-restriction/restriction/${id}`;
	const { data } = await Api.put(url, { type, start_date, end_date, description, company_id, organization_id });
	return data;
}

export const useUpdateWorkerRestriction = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: UpdateWorkerRestrictionDTO) => updateWorkerRestriction(prams), {
		onSuccess: ({ message: txt }: UpdateWorkerRestrictionSuccess) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_RESTRICTIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_WORKERS]);
			message.success(I18n.get(txt));
		}
	});
};
