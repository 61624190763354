import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import Api from '@/services/api';
import { ActionPlan } from '@/types/entities';
import { QUERY_KEYS } from '@/utils/query-keys';

export enum ActionPlanBoard {
	TO_DO = 'TO DO',
	DOING = 'DOING',
	DONE = 'DONE'
}

export interface CreateActionPlanDTO {
	action_plan: {
		file_id: string;
		deadline: Date | string;
		board: ActionPlanBoard;
		description: string;
		complaint_id?: string;
		workstation_id: string;
		responsible_worker_id: string;
	};
	organization_id: string;
}

type CreateActionPlanResponse = {
	status: string;
	message: string;
	data: ActionPlan;
};

async function createCreateActionPlan(params: CreateActionPlanDTO): Promise<CreateActionPlanResponse> {
	const url = `/action_plan`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation((prams: CreateActionPlanDTO) => createCreateActionPlan(prams), {
		onSuccess: ({ message: txt, data: { file_id } }: CreateActionPlanResponse) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_FILE_ACTION_PLANS, { file_id }]);
			message.success(I18n.get(txt));
		}
	});
};
