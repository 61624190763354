import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Table } from 'antd';
import moment from 'moment';

import { useAETCreateContext } from '../context';

interface TableListProps {
	total: number;
	isLoading: boolean;
	checkList: any[];
	onPaginationChange: (currentPage: number, pageSize: number) => void;
}

export const TableList: React.FC<TableListProps> = ({ isLoading, total, checkList, onPaginationChange, ...props }) => {
	const form = Form.useFormInstance();
	const {
		data: { checklist },
		setData
	} = useAETCreateContext();

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [cachedResults, setCachedResults] = useState<any[]>([]);

	useEffect(() => {
		if (checkList && Array.isArray(checkList)) {
			setCachedResults((prev) => {
				const existingIds = new Set(prev.map((item) => item.checklist_id));
				const newItems = checkList.filter((item) => !existingIds.has(item.checklist_id));
				return [...prev, ...newItems];
			});
		}
	}, [checkList]);

	useEffect(() => {
		if (checklist && Array.isArray(checklist)) {
			setSelectedRowKeys(checklist);
		}
	}, [checklist]);

	const rowSelection: TableRowSelection<any> = {
		selectedRowKeys,
		onChange: onSelectChange
	};

	function onSelectChange(selectedRowKeys: React.Key[]) {
		setSelectedRowKeys(selectedRowKeys);
		const updatedChecklist = setCheckList(selectedRowKeys);

		setData({
			checklist: updatedChecklist
		});

		const getChecklists = cachedResults
			.filter((item) => updatedChecklist.includes(item.checklist_id))
			.map((item) => ({ name: item.workstation_name, key: item.key, id: item.checklist_id }));

		form.setFieldsValue({ checklists: getChecklists });
	}

	function setCheckList(selectedRowKeys: React.Key[]): React.Key[] {
		const existingIds = new Set(checklist.map((item: any) => item));
		const newKeys = selectedRowKeys.filter((key) => !existingIds.has(key));
		return [...checklist, ...newKeys];
	}

	const columns = [
		{
			title: I18n.get('File'),
			dataIndex: 'original_name',
			render: (text: string) => <span style={{ color: 'blue' }}>{text}</span>
		},
		{
			title: I18n.get('Date of colletion'),
			dataIndex: 'collection_date',
			render: (text: string) => (
				<span style={{ color: 'blue' }}>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</span>
			)
		}
	];

	return (
		<Table
			{...props}
			rowKey="checklist_id"
			columns={columns}
			loading={isLoading}
			rowSelection={rowSelection}
			pagination={{ showSizeChanger: false }}
			dataSource={checkList}
		/>
	);
};
