import React from 'react';
import { Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

interface DisabledInputProps {
	name: string;
	label: string;
	item: {
		name: string;
		id: string;
	}
}

export function DisabledInput({ name, label, item }: DisabledInputProps) {
	return (
		<Form.Item name={name} label={I18n.get(label)}>
			<Select disabled={true} options={[{ label: item.name, value: item.id }]} />
		</Form.Item>
	);
};
