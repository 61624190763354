import React, { useEffect } from 'react';
import moment from 'moment';
import { message, Table } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Actions } from './Actions';
import { useAETContext } from './context';
import { useGetErgonomicWorkAnalysis } from '@/hooks/useGetErgonomicWorkAnalysis';
import type { ErgonomicWorkAnalisysListItem } from '@/types/entities/ErgonomicWorkAnalisys';
import type { ColumnsType } from 'antd/es/table';

moment.locale(navigator.language);

export function List() {
	const { filter, handleSetOffset, handleSetLimit } = useAETContext();
	const { isLoading, data, isError } = useGetErgonomicWorkAnalysis(filter);

	const columns: ColumnsType<ErgonomicWorkAnalisysListItem> = [
		{
			title: I18n.get('Report'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			width: '35%',
			render: (name) => <span>{name}</span>
		},
		{
			title: I18n.get('Industrial site'),
			dataIndex: 'company',
			key: 'company',
			align: 'center',
			width: '25%',
			render: ({ name }) => <span>{name}</span>
		},
		{
			title: I18n.get('Date'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			width: '25%',
			render: (date) => (date ? moment(date).format('LLL') : '-')
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center',
			render: (value) => <Actions data={value} />
		}
	];

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch reports'));
		}
	}, [isError]);

	return (
		<Table
			rowKey="id"
			columns={columns}
			loading={isLoading}
			dataSource={data?.rows}
			pagination={{
				total: data?.total,
				current: filter.page,
				showSizeChanger: true,
				pageSize: filter.limit,
				hideOnSinglePage: true,
				defaultPageSize: filter.limit,
				pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
				onChange: handleSetOffset,
				onShowSizeChange: (_, size: number) => handleSetLimit(size)
			}}
		/>
	);
}
