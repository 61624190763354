import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { CounterCard } from '@/components/ui/CounterCard';

import { HealthMatrixContext } from './context';
import { HierarchyContainer, LineInfoContainer } from './styles';

export function LineInfo() {
	const history = useHistory();

	const { selectedLine, selectedLineSector, selectedLineCompany, lineHierarchy, startDate, endDate } =
		useContext(HealthMatrixContext);

	function cardRedirect(cardType) {
		const routes = {
			// pcd: '/ehs/workers/pcd',
			// worker: '/ehs/workers/all',
			// women: '/ehs/workers/women',
			// restrict: '/ehs/workers/restrict',
			complaint: '/ehs/complaints',
			certificate: '/ehs/certificates'
		};

		const START_DATE_PARAM = new Date(startDate).setUTCHours(0, 0, 0, 0);
		const END_DATE_PARAM = new Date(endDate).setUTCHours(23, 59, 59, 999);

		const path = `${routes[cardType]}/${selectedLineCompany.id}/${selectedLineSector.id}/${selectedLine.id}?start_date=${START_DATE_PARAM}&end_date=${END_DATE_PARAM}`;

		history.push(path);
	}

	const cardArray = [
		{
			title: I18n.get('Complaints'),
			Icon: <Icons.FrownOutlined />,
			count: selectedLine.complaint,
			handleClick: () => cardRedirect('complaint')
		},
		{
			title: I18n.get('Med. certificates'),
			Icon: <Icons.SnippetsOutlined />,
			count: selectedLine.medical_certificate,
			handleClick: () => cardRedirect('certificate')
		},
		{
			title: I18n.get('Workers'),
			Icon: <Icons.IdcardOutlined />,
			count: selectedLine.worker
			// handleClick: () => cardRedirect('worker')
		},
		{
			title: I18n.get('Women'),
			Icon: <Icons.WomanOutlined />,
			count: selectedLine.women
			// handleClick: () => cardRedirect('women')
		},
		{
			title: I18n.get('PWD'),
			Icon: <Icons.WarningOutlined />,
			count: selectedLine.pcd
			// handleClick: () => cardRedirect('pcd')
		},
		{
			title: I18n.get('Restrict'),
			Icon: <Icons.StopOutlined />,
			count: selectedLine.restrict_worker
			// handleClick: () => cardRedirect('restrict')
		}
	];

	return (
		<LineInfoContainer>
			<HierarchyContainer>
				{lineHierarchy.isLoading ? (
					<Icons.LoadingOutlined style={{ fontSize: '1.6rem' }} />
				) : (
					<div style={{ margin: '1.6rem' }}>
						<p>{selectedLineCompany.name}</p>
						<p>{selectedLineSector.name}</p>
						<p>{selectedLine.name}</p>
					</div>
				)}
			</HierarchyContainer>
			{cardArray.map((card) => (
				<CounterCard
					size="small"
					key={card.title}
					Icon={card.Icon}
					title={card.title}
					count={card.count}
					handleClick={card.handleClick}
					loading={lineHierarchy.isLoading}
				/>
			))}
		</LineInfoContainer>
	);
}
