import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { TrueOrFalseList } from './TrueOrFalseList';

export function Complexity( { openActionPlan }) {
	const control_difficulty = useSelector(
		(state) => state.checklist.control_difficulty
	);
	const title = I18n.get('Complexity');
	let items = [
		{
			name: I18n.get('Easy to understand information'),
			checked: control_difficulty.difficult_information,
		},
		{
			name: I18n.get('Movements do not require high skill'),
			checked: control_difficulty.difficult_dexterity,
		},
		{
			name: I18n.get(
				'Low level of attention required for long periods'
			),
			checked: control_difficulty.long_attention,
		},
	];

	const result_label =
		items.filter((item) => item.checked).length === 0
			? I18n.get('Workstation is complex and high cognitive demand')
			: I18n.get('Workstation is simple and of low cognitive requirement');

	items.push({
		name: I18n.get('Result'),
		value: result_label,
		action_plan: true
	});

	return <TrueOrFalseList title={title} items={items} openActionPlan={openActionPlan} />;
}
