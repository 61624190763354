import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button } from 'antd';

interface FooterProps {
	onClickNext: () => void;
	onClickPrevious: () => void;
	isLoading: boolean;
}

export function Footer({ onClickNext, onClickPrevious, isLoading }: FooterProps) {
	return (
		<Row>
			<Col>
				<Button style={{ margin: '0 8px' }} onClick={onClickPrevious}>
					{I18n.get('Previous')}
				</Button>
			</Col>
			<Col>
				<Form.Item shouldUpdate noStyle>
					{() => (
						<Button type="primary" onClick={onClickNext} loading={isLoading}>
							{I18n.get('Next')}
						</Button>
					)}
				</Form.Item>
			</Col>
		</Row>
	);
}
