import styled from 'styled-components';

export const TitleUpload = styled.h2`
	opacity: 0.8;
`;

export const DropContainer = styled.div.attrs({ className: 'dropzone' })`
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;

	min-height: 250px;
	border: 1px solid #ddd;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	transition: height 0.2s ease;
`;

export const UploadMessage = styled.p`
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 0;
`;

export const ContainerMessage = styled.div`
	display: flex;
	align-items: flex-start;
`;
