import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

interface WorkerHistoryDTO {
	id: string;
	organization_id?: string;
	limit?: number;
	offset?: number;
}

interface WorkerHistoryRowDTO {
	id: string;
	details: string;
	activity: string;
	end_date: string;
	start_date: string;
}

interface WorkerHistoryResponseDTO {
	count: number;
	rows: WorkerHistoryRowDTO[];
}

async function getWorkerHistory({
	id,
	limit,
	offset,
	organization_id
}: WorkerHistoryDTO): Promise<WorkerHistoryResponseDTO> {
	let url = `/worker/history/${id}`;
	const { data } = await Api.get(url, {
		params: {
			limit,
			offset,
			organization_id
		}
	});
	return data;
}

export const useGetWorkerHistory = (params: WorkerHistoryDTO) => {
	return useQuery([QUERY_KEYS.GET_WORKER_HISTORY, params], () => getWorkerHistory(params), {
		enabled: !!params.organization_id && !!params.id
	});
};
