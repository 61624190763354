import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Image, Typography } from 'antd';
import { Container } from './style';

type ImageItem = {
	id: string;
	original_name: string;
	uri: string;
};

interface ImagesProps {
	data?: ImageItem[];
}

export default function Images({ data = [] }: ImagesProps) {
	if (!data || data.length === 0) {
		return null;
	}

	return (
		<Container>
			<Typography.Title level={5}>{I18n.get('Images')}</Typography.Title>
			<Row justify="center">
				<Col span={22}>
					<Row gutter={[50, 24]} justify="start">
						{data.map((item) => (
							<Col key={item.id}>
								<Image src={item.uri} alt={item.original_name} />
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</Container>
	);
}
