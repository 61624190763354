import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export type GetFileActionPlansDTO = {
	organization_id?: string;
	company_id?: string;
	file_id?: string;
};

type Deadline = {
	full_date: string;
	day: string;
	month: string;
	year: string;
	remaining_days: number;
	remaining_days_text: string;
};

type ActionPlanTask = {
	id: string;
	description: string;
	is_completed: boolean;
};

export type GetFileActionPlansResponse = {
	id: string;
	description: string;
	responsible: string;
	deadline: Deadline;
	tasks: ActionPlanTask[];
};

async function getFileActionPlans({
	file_id,
	...params
}: GetFileActionPlansDTO): Promise<GetFileActionPlansResponse[]> {
	let url = `/action_plan/${file_id}`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useGetFileActionPlans = (params: GetFileActionPlansDTO) => {
	return useQuery([QUERY_KEYS.GET_FILE_ACTION_PLANS, { file_id: params.file_id }], () => getFileActionPlans(params), {
		enabled: !!params.organization_id && !!params.file_id && !!params.company_id
	});
};
