import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

import { Worker } from '@/types/entities';
import { ErrorResponse } from '@/types';
import {
	FilesToCSVDTO,
	GetFilesDTO,
	ComplaintsToCSVDTO,
	MedicalCertificateDTO,
	GetMedicalCertificatesDTO
} from '@/types/dto';

async function filesToCSV({
	organizationId,
	companyId,
	page = 0,
	limit = 10,
	query = ''
}: GetFilesDTO): Promise<FilesToCSVDTO> {
	let url = `/upload/${organizationId}/${companyId}?offset=${page}&limit=${limit}&${query}`;
	const { data } = await Api.get(url);
	return data;
}

export const useFilesToCSV = () => {
	return useMutation<FilesToCSVDTO, ErrorResponse, GetFilesDTO>(filesToCSV);
};

async function workersToCSV(params: string): Promise<Worker> {
	let url = `/worker?${params}`;
	const { data } = await Api.get(url);
	return data;
}

export const useWorkersToCSV = () => {
	return useMutation<Worker, ErrorResponse, string>(workersToCSV);
};

async function complaintsToCSV(params: string): Promise<ComplaintsToCSVDTO> {
	let url = `/complaint?${params}`;
	const { data } = await Api.get(url);
	return data;
}

export const useComplaintsToCSV = () => {
	return useMutation<ComplaintsToCSVDTO, ErrorResponse, string>(complaintsToCSV);
};

async function certificatesToCSV(params: GetMedicalCertificatesDTO): Promise<MedicalCertificateDTO[]> {
	let url = `/medical_certificate`;
	const { data } = await Api.get(url, { params });
	return data;
}

export const useCertificatesToCSV = () => {
	return useMutation<MedicalCertificateDTO[], ErrorResponse, GetMedicalCertificatesDTO>(certificatesToCSV);
};
