import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import ActionPlanButton from './components/ActionPlan/Button';

const StyledList = styled(List)`
	margin-bottom: 40px;

	& .ant-list-header {
		font-weight: bold;
	}

	& .ant-list-footer {
		padding: 0;
	}
`;

export function MultipleCassificationList({ title, items, classificationText, openActionPlan }) {
	const color = ['#CBEDBA', '#FDE6B8', '#FCBDC0', ''].reverse();
	const classificationTexts = [I18n.get('Low risk'), I18n.get('Moderate Risk'), I18n.get('High risk'), ''].reverse();

	return (
		<StyledList
			header={
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{title}
					<ActionPlanButton disabled={!items?.length} onClick={openActionPlan} />
				</div>
			}
			bordered
			dataSource={items}
			renderItem={(item) => (
				<List.Item style={{ background: color[item.classification] }}>
					<List.Item.Meta title={item.name} description={item.description} />
					<div>{classificationText ? classificationText : classificationTexts[item.classification]}</div>
				</List.Item>
			)}
		/>
	);
}
