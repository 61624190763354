import React, { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
	viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.667 16H5.333v-5.333H0V5.333h5.333V0h5.334v5.333H16v5.334h-5.333V16Zm-4-1.333h2.666V9.333h5.334V6.667H9.333V1.333H6.667v5.334H1.333v2.666h5.334v5.334Z"
    />
  </svg>
)
export default SvgComponent
