import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { Complaint } from '@/types/entities';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetComplaintsDTO {
	organization_id?: string;
	workstation_id?: string;
}

async function getComplaints({ organization_id, workstation_id }: GetComplaintsDTO): Promise<Complaint[]> {
	let url = `/complaint/${organization_id}/${workstation_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetComplaints = (params: GetComplaintsDTO) => {
	return useQuery([QUERY_KEYS.GET_COMPLAINTS, params], () => getComplaints(params), {
		enabled: !!params.organization_id && !!params.workstation_id
	});
};
