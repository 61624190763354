import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Button, Table, Space, Tooltip } from 'antd';

import { ManagementContext } from './context';
import { textParseGender } from '@/utils/mask';
import { CustomButton, StatusIncon, ResponsiveTableContainer } from './styles';

interface Worker {
	id: string;
	register_id: string;
	name: string;
	pcd: boolean;
	gender: string;
	status?: string;
	is_active: boolean;
	end_date?: string;
}

const getStatusColor = (status: string): string => {
	const statusColorMap: { [key: string]: string } = {
		Active: '#17A93B',
		Inactive: '#E80707',
		Restricted: '#EFAC1E',
		'Restriction until': '#EFAC1E',
		'Indefinite restriction': '#EFAC1E'
	};
	return statusColorMap[status] || 'black';
};

const formatDate = (parameter: string): string =>
	new Date(parameter).toLocaleDateString(navigator.language, {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	});

export function List() {
	const {
		workers,
		showWorkerEditModal,
		showComplaintOrCertificateModal,
		showWorkerDeleteModal,
		showWorkerActivateModal,
		showEmployeeHistoryModal
	} = useContext(ManagementContext);

	const columns = [
		{
			title: I18n.get('Register'),
			dataIndex: 'register_id',
			key: 'register_id',
			align: 'center' as 'center',
			width: '5%'
		},
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			className: 'expand-on-small'
		},
		{
			title: I18n.get('PWD'),
			dataIndex: 'pcd',
			key: 'pcd',
			align: 'center' as 'center',
			render: (text: boolean) => <span>{I18n.get(text ? 'Yes' : 'No')}</span>,
			className: 'hide-on-small',
			width: '5%'
		},
		{
			title: I18n.get('Gender'),
			dataIndex: 'gender',
			key: 'gender',
			align: 'center' as 'center',
			render: (text: string) => <span>{I18n.get(textParseGender(text))}</span>,
			className: 'hide-on-small',
			width: '10%'
		},
		{
			title: I18n.get('Status'),
			key: 'status',
			align: 'center' as 'center',
			width: '15%',
			render: (worker: Worker) => {
				return (
					<Row>
						<Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<span style={{ display: 'flex' }}>
								<StatusIncon
									style={{
										backgroundColor: worker.status ? getStatusColor(worker.status) : 'gray'
									}}
								/>
							</span>
						</Col>
						<Col span={20}>
							{I18n.get(worker.status)}{' '}
							{worker.status === 'Restriction until' && worker.end_date && formatDate(worker.end_date)}
						</Col>
					</Row>
				);
			},
			className: 'expand-on-small'
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center' as 'center',
			width: '5%',
			render: (worker: Worker) => (
				<Space size="middle">
					<Tooltip placement="bottomRight" title={I18n.get('Update worker')}>
						<Button
							type="primary"
							disabled={!worker.is_active}
							onClick={() => showWorkerEditModal(worker)}
							icon={<Icons.EditOutlined />}
						/>
					</Tooltip>
					<Tooltip placement="bottomRight" title={I18n.get('Add informations')}>
						<Button
							type="primary"
							disabled={!worker.is_active}
							onClick={() => showComplaintOrCertificateModal(worker)}
							icon={<Icons.FileAddOutlined />}
						/>
					</Tooltip>
					<Tooltip placement="bottomRight" title={I18n.get('Employee history')}>
						<Button
							type="primary"
							onClick={() => showEmployeeHistoryModal(worker)}
							icon={<Icons.HistoryOutlined />}
						/>
					</Tooltip>
					{worker.is_active ? (
						<Tooltip placement="bottomRight" title={I18n.get('Delete worker')}>
							<Button
								type="primary"
								danger
								ghost
								onClick={() => showWorkerDeleteModal(worker)}
								icon={<Icons.StopOutlined />}
							/>
						</Tooltip>
					) : (
						<Tooltip placement="bottomRight" title={I18n.get('Activate worker')}>
							<CustomButton
								type="primary"
								ghost
								icon={<Icons.UndoOutlined />}
								onClick={() => showWorkerActivateModal(worker)}
							/>
						</Tooltip>
					)}
				</Space>
			)
		}
	];

	return (
		<Col sm={24} md={24}>
			<ResponsiveTableContainer>
				<Table
					columns={columns}
					dataSource={workers ?? []}
					pagination={{ pageSize: 10 }}
					rowKey={(record: Worker) => record.id}
				/>
			</ResponsiveTableContainer>
		</Col>
	);
}
