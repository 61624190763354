import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Typography, Button, DatePicker } from 'antd';

interface HeaderProps {
	onClick: () => void;
	onChangeDate: () => void;
}

export function Header({ onClick, onChangeDate }: HeaderProps) {
	return (
		<Col span={24}>
			<Row>
				<Col span={12}>
					<Typography.Title level={3}>{I18n.get('Health Matrix')}</Typography.Title>
				</Col>
				<Col span={12}>
					<Button type="link" style={{ float: 'right' }} onClick={onClick}>
						{I18n.get('Export CSV')}
					</Button>
					<DatePicker.RangePicker picker="month" onChange={onChangeDate} style={{ float: 'right' }} />
				</Col>
			</Row>
		</Col>
	);
}
