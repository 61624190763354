import { I18n } from '@aws-amplify/core';
import * as iconv from 'iconv-lite';
import { message } from 'antd';

export class CSV {
	download = (data: any[], fileName: string) => {
		if (!data.length) {
			message.error(I18n.get('There is no data matching the filter'));
			return;
		}

		const content = this.format(data);
		const csvContent = content;

		const encoded = iconv.encode(csvContent, 'win1252');

		const blob = new Blob([encoded], { type: 'text/csv;charset=windows-1252;' });
		const url = URL.createObjectURL(blob);

		let archive = document.createElement('a');
		archive.target = '_blank';
		archive.href = url;
		archive.download = fileName;

		archive.click();
		archive.remove();
	};

	format = (data: any[]) => {
		const titles = Object.keys(data[0]);
		const translatedTitles = titles.map((header) => I18n.get(header.toString()).replace(/;/g, ''));

		const rows = data.map((item: any) =>
			titles.map((header) => {
				if (item[header]) {
					return I18n.get(item[header].toString()).replace(/;/g, '');
				} else {
					return '';
				}
			})
		);
		const headers = translatedTitles.join(';') + ';';

		const csvRows = [headers, ...rows.map((row: any) => row.join(';') + ';')];
		return csvRows.join('\n');
	};
}
