import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export interface SendFileToS3DTO {
	headers: {
		headers: {
			'Content-Type': string;
		};
	},
	url: string;
	file: ArrayBuffer;
}

async function sendFileToS3({ headers, url, file }: SendFileToS3DTO): Promise<any> {
	const { data } = await axios.put(url, file, headers);
	return data;
}

export const useSendFileS3 = () => {
	return useMutation(sendFileToS3);
};
