import React from 'react';
import { Menu, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useDispatch } from 'react-redux';
import { LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';

import history from '@/store/history';
import { AvatarUser } from './AvatarUser';
import { logoutUserSession } from '@/redux/auth/actions';

export function Popover() {
	const dispatch = useDispatch();

	function onAccount() {
		history.push('/settings/account');
	}

	function onLogout() {
		dispatch(logoutUserSession());
	}

	return (
		<Col>
			<Menu key="user" mode="horizontal">
				<Menu.SubMenu title={<AvatarUser size="large" />}>
					<Menu.Item key="account" onClick={onAccount}>
						<InfoCircleOutlined />
						{I18n.get('My account')}
					</Menu.Item>
					<Menu.Item key="logout" onClick={onLogout}>
						<LogoutOutlined />
						{I18n.get('Exit')}
					</Menu.Item>
				</Menu.SubMenu>
			</Menu>
		</Col>
	);
}
