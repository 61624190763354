import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Typography, Row, Col } from 'antd';

const { Title } = Typography;

export function Header() {
	return (
		<Row>
			<Col span={24}>
				<Title level={4}>{I18n.get('Files')}</Title>
			</Col>
		</Row>
	);
}
