import React from 'react';
import Api from '@/services/api';
import { useSelector } from 'react-redux';
import { SingleCassificationList } from './SingleCassificationList';
import { ConfigProvider } from 'antd';
import { MehOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';

const customizeRenderEmpty = () => (
	<div style={{ textAlign: 'center', margin: '10px' }}>
		<MehOutlined style={{ fontSize: 20 }} />
		<p>{I18n.get('There is no post with screwdriver')}</p>
	</div>
);

export function ResumeReactions({ data, openActionPlan }) {
	const query = useParams();

	const checklist = useSelector((state) => state.checklist.checklist);
	const company = useSelector((state) => state.organization.company);
	const organization = useSelector((state) => state.organization.organization);
	const file = useSelector((state) => state.checklist.file);
	const [reactions, setReactions] = React.useState({
		loading: false,
		data: null,
		err: null
	});

	function getParameters() {
		let body = {};

		if (query.organization_id) {
			body['organization_id'] = query.organization_id;
		}

		if (query.organization_id && query.company_id) {
			body['company_id'] = query.company_id;
		}

		if (query.file_id) {
			body['file_id'] = query.file_id;
			body['checklist_id'] = checklist.id;
		}

		return body;
	}

	const getReaction = React.useCallback(() => {
		if (!checklist || !company || !organization || !file) return;

		let body = getParameters();

		if (Object.keys(body).length === 0) {
			body = {
				organization_id: organization.id,
				company_id: company.id,
				checklist_id: checklist.id,
				file_id: file.id
			};
		}

		Api.post('/report/checklist/reaction/all', body)
			.then((res) => {
				setReactions((prev) => ({ ...prev, data: res.data }));
			})
			.catch((err) => {});
	}, [organization, company, checklist, file]);

	React.useEffect(() => {
		getReaction();
	}, [organization, company, checklist, file]);

	return (
		<ConfigProvider renderEmpty={customizeRenderEmpty}>
			<SingleCassificationList
				openActionPlan={openActionPlan}
				classification={data?.reaction?.note}
				title={I18n.get('Reaction')}
				items={reactions.data?.map((item) => ({
					name: item.name,
					effort: item.torque.toLocaleString(),
					frequency: item.frequency
				}))}
			/>
		</ConfigProvider>
	);
}
