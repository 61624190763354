import React from 'react';
import { Row, Col, Modal } from 'antd';

import { List } from './List';
import { Header } from './Header';
import { useAETContext } from '../context';

export function ListFilesModal() {
	const { isOpenListFile, handleOpenModalListFile, handleSelectReport } = useAETContext();

	function handleOnCancel(): void {
		handleOpenModalListFile();
		handleSelectReport();
	}

	return (
		<Modal width={1000} open={isOpenListFile} onCancel={handleOnCancel} footer={null}>
			<Row gutter={[0, 40]}>
				<Col span={24}>
					<Header />
				</Col>
				<Col span={24}>
					<List />
				</Col>
			</Row>
		</Modal>
	);
}
