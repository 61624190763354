import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { NameInput } from '../styles';

const { useFormInstance } = Form;

export function CreateWorkstation() {
	const [workstationName, setWorkstationName] = useState('');
	const [workstationGHE, setWorkstationGHE] = useState('');

	const { validateFields, resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		lineId,
		creatingOrganizationData,
		isEditing,
		handleWorkstation,
		createOrganizationData,
		treatFieldValidation,
	} = useManageOrganization();

	async function handleCreateWorkstation() {
		try {
			await validateFields(['workstation_name', 'workstation_ghe']);
			const created = await createOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				line_id: lineId,
				workstation_name: workstationName,
				workstation_ghe: workstationGHE,
			});

			setWorkstationName('');
			setWorkstationGHE('');
			handleWorkstation(
				created.id,
				created.ghe || workstationGHE,
				resetFields,
				setFieldValue
			);
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	function handleWorkstationNameChange(name: string) {
		setWorkstationName(name);
	}

	function handleWorkstationGHEChange(ghe: string) {
		setWorkstationGHE(ghe);
	}

	return (
		<Col span={24}>
			<Form.Item
				name="workstation"
				label={I18n.get('Create workstation')}
				labelCol={{ span: 24 }}
				style={{ fontWeight: isEditing ? '400' : '600' }}
			>
				<Row align="bottom" gutter={[4, 0]}>
					<Col span={10}>
						<NameInput
							disabled={isEditing}
							name="workstation_ghe"
							label={I18n.get('GHE')}
							labelCol={{ span: 24 }}
							style={{ margin: '0' }}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid GHE'),
								},
							]}
						>
							<Input
								disabled={isEditing}
								placeholder={I18n.get('Workstation GHE')}
								onChange={(e) =>
									handleWorkstationGHEChange(e.target.value)
								}
							/>
						</NameInput>
					</Col>
					<Col span={10}>
						<NameInput
							disabled={isEditing}
							name="workstation_name"
							label={I18n.get('Name')}
							labelCol={{ span: 24 }}
							style={{ margin: '0' }}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid name'),
								},
							]}
						>
							<Input
								disabled={isEditing}
								placeholder={I18n.get('Workstation name')}
								onChange={(e) =>
									handleWorkstationNameChange(e.target.value)
								}
							/>
						</NameInput>
					</Col>
					<Col span={2}>
						<Button
							disabled={isEditing}
							loading={creatingOrganizationData}
							type="primary"
							htmlType="submit"
							onClick={() => handleCreateWorkstation()}
						>
							{I18n.get('Create')}
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</Col>
	);
}
