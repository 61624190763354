import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import ActionPlanButton from './components/ActionPlan/Button';

const StyledList = styled(List)`
	margin-bottom: 40px;

	& .ant-list-footer {
		padding: 0;
	}

	& .ant-list-item,
	& .ant-list-header {
		border-color: #dedbdb;
	}

	& .ant-list-header {
		font-weight: bold;
	}
`;

const defaultClassifications = {
	3: I18n.get('Low risk'),
	2: I18n.get('Moderate risk'),
	1: I18n.get('High risk')
};

export function SingleCassificationList({
	classification,
	classifications = defaultClassifications,
	title,
	items,
	effortUnit = 'Kg',
	openActionPlan
}) {
	const classificationText = classifications[classification];
	const color = ['#CBEDBA', '#FDE6B8', '#FCBDC0', ''].reverse()[classification];

	return (
		<StyledList
			header={
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{title}
					<ActionPlanButton disabled={!items?.length} onClick={openActionPlan} />
				</div>
			}
			footer={<div style={{ background: color, padding: '12px 24px' }}>{I18n.get(classificationText)}</div>}
			bordered
			dataSource={items}
			style={{ borderWidth: 2, borderColor: color }}
			renderItem={(item) => (
				<List.Item>
					<List.Item.Meta title={item.name} description={item.description} />
					<div>
						{item.effort}
						{effortUnit}
						{item.frequency ? ` / ${item.frequency}x por hora` : ''}
					</div>
				</List.Item>
			)}
		/>
	);
}
