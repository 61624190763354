import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, message } from 'antd';
import { useAETCreateContext } from '../../context';
import { useGetWorkstations } from '@/hooks/useGetWorkstations';

export function Workstation() {
	const { organization_id, company_id, sector_id, queryParams, setWorkstation, setQueryParams } =
		useAETCreateContext();

	const { isInitialLoading, data, isError } = useGetWorkstations({ organization_id, company_id, sector_id });

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch workstations'));
		}
	}, [isError]);

	function handleOnChange(values: string[]): void {
		setWorkstation(values);
		setQueryParams({ ...queryParams, workstation_id: values });
	}

	return (
		<Form.Item name="workstation">
			<Select
				showSearch
				mode="multiple"
				onChange={handleOnChange}
				loading={isInitialLoading}
				placeholder={I18n.get('Workstation')}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
