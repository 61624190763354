import React from 'react';
import { List } from 'antd';
import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import ActionPlanButton from './components/ActionPlan/Button';

const StyledList = styled(List)`
	& .ant-list-item {
		border-color: #dedbdb;
	}
`;

function Icon({ checked, value }) {
	if (value) {
		return <MinusOutlined />;
	}

	if (checked) return <CheckOutlined style={{ color: '#037D00', fontSize: 18 }} />;

	return <CloseOutlined style={{ color: '#BF1212', fontSize: 18 }} />;
}

export function TrueOrFalseList({ title, items, withDescription, openActionPlan }) {
	return (
		<StyledList
			header={title}
			itemLayout="horizontal"
			dataSource={items}
			style={{ margin: '40px 0' }}
			renderItem={(item) => (
				<List.Item>
					<List.Item.Meta
						style={{ alignItems: 'center' }}
						avatar={<Icon {...item} />}
						title={`${item.name}${item.value ? ': ' + item.value : ''} ${
							withDescription
								? item.checked === false
									? I18n.get('Requires actions')
									: I18n.get('No actions required')
								: ''
						}`}
					/>
					{item.action_plan && <ActionPlanButton onClick={openActionPlan} />}
				</List.Item>
			)}
		/>
	);
}
