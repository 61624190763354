import styled from 'styled-components';

export const Container = styled.div`
	border: 1px solid #00000033;
	margin-bottom: 1rem;
	padding: 1rem;
	padding-bottom: 2rem;

	.ant-typography {
		margin-bottom: 2rem;
	}

	.ant-image {
		width: 100%;
		border-radius: 6px;

		img {
			height: 270px;
			border-radius: 6px;
			width: 100%;
			object-fit: cover;
			box-shadow: 1px 1px 5px 0px #00000026;
		}
	}
`;
