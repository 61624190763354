import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Steps } from 'antd';

import Spinner from '@/components/SpinnerDeprecated';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFile, setChecklist, clearStateChecklist, setStep } from '@/redux/checklist/actions';
import { clearReports } from '@/redux/reports/actions';
import { useIsMounted } from '@/hooks/useIsMounted';

import Api from '@/services/api';
import { Information } from './Information';
import { Effort } from './Effort';
import { Reaction } from './Reaction';
import { Vibration } from './Vibration';
import { Wrist } from './Wrist';
import { Percussion } from './Percussion';
import { HitAgainst } from './HitAgainst';
import { RegulationNotes } from './RegulationNotes';
import { Displacement } from './Displacement';
import { ControlDifficulty } from './ControlDifficulty';
import { OperatorReport } from './OperatorReport';
import { Result } from './Result';
import { UploadImage } from './UploadImage/UploadImage';

const { Step } = Steps;

export function ChecklistEHS() {
	const { organization_id, company_id, file_id } = useParams();

	const history = useHistory();
	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [isLoadingFile, setIsLoadingFile] = useState(true);
	const [isLoadingChecklist, setIsLoadingChecklist] = useState(true);

	const [hasErrorsFile, setHasErrorsFile] = useState(null);
	const [hasErrorsChecklist, setHasErrorsChecklist] = useState(null);

	const current = useSelector((state) => state.checklist.current_step);
	const checklist = useSelector((state) => state.checklist.checklist);
	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const getFile = useCallback(() => {
		let url;

		if (organization_id && company_id) {
			url = `/file/${organization_id}/${company_id}/${file_id}`;
		} else {
			url = `/file/${organization.id}/${company.id}/${file_id}`;
		}

		Api.get(url)
			.then((res) => {
				const { id, original_name, duration } = res.data;
				isMounted() && dispatch(setFile({ id, original_name, duration }));
			})
			.catch((err) => isMounted() && setHasErrorsFile(err))
			.finally(() => isMounted() && setIsLoadingFile(false));
	}, [organization, company, file_id, organization_id, company_id]);

	const getChecklist = useCallback(() => {
		let url;
		if (organization_id && company_id) {
			url = `/report/checklist/${file_id}/${organization_id}/${company_id}`;
		} else {
			url = `/report/checklist/${file_id}/${organization.id}/${company.id}`;
		}

		Api.get(url)
			.then((res) => isMounted() && dispatch(setChecklist(res.data)))
			.catch((err) => isMounted() && setHasErrorsChecklist(err))
			.finally(() => isMounted() && setIsLoadingChecklist(false));
	}, [organization, company, file_id, organization_id, company_id]);

	useEffect(() => {
		getFile();
		getChecklist();
	}, [organization, company, file_id]);

	useEffect(() => {
		return () => {
			dispatch(clearStateChecklist());
			dispatch(clearReports());
		};
	}, []);

	useEffect(() => {
		if (!organization && !company) {
			history.push('/');
		}
	}, [organization, company]);

	const steps = [
		{
			title: 'Informations',
			content: <Information />
		},
		{
			title: 'Image upload',
			content: <UploadImage />
		},
		{
			title: 'Effort',
			content: <Effort />
		},
		{
			title: 'Reaction',
			content: <Reaction />
		},
		{
			title: 'Vibration',
			content: <Vibration />
		},
		{
			title: 'Percussion',
			content: <Percussion />
		},
		{
			title: 'Wrist analysis',
			content: <Wrist />
		},
		{
			title: 'Batida contra',
			content: <HitAgainst />
		},
		{
			title: 'RegulationNotes',
			content: <RegulationNotes />
		},
		{
			title: 'Displacement',
			content: <Displacement />
		},
		{
			title: 'ControlDifficulty',
			content: <ControlDifficulty />
		},
		{
			title: 'OperatorReport',
			content: <OperatorReport />
		},
		{
			title: 'Result',
			content: <Result />
		}
	];

	useEffect(() => {
		if (checklist?.consolidated) {
			const lastStep = steps.length - 1;
			dispatch(setStep(lastStep));
		}
	}, [checklist]);

	const loading = isLoadingFile || isLoadingChecklist;
	const hasErrors = hasErrorsFile || hasErrorsChecklist;

	if (loading) {
		return <Spinner />;
	}

	if (hasErrors) {
		return <h2>Internal server error </h2>;
	}

	return (
		<Row>
			<Col sm={24} style={{ paddingTop: '20px', paddingBottom: '30px' }}>
				<h2>Checklist EHS - Análise ergonômica do trabalho</h2>
			</Col>
			<Col sm={24} style={{ minHeight: '660px' }}>
				<Row>
					<Col sm={2}>
						<Steps direction="vertical" size="medium" current={current} style={{ minHeight: '700px' }}>
							{steps.map((step, index) => (
								<Step key={index} />
							))}
						</Steps>
					</Col>
					<Col sm={22}>{steps[current].content}</Col>
				</Row>
			</Col>
		</Row>
	);
}
