import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input, Typography } from 'antd';

const TextArea = Input.TextArea;

export function ProductionVolume() {
	return (
		<Row>
			<Col span={24}>
				<Typography.Title level={5}>{I18n.get('Production Volume')}</Typography.Title>
			</Col>
			<Col span={24}>
				<Row>
					<Col sm={24}>
						<p>{I18n.get('Enter production volumes below')}</p>
					</Col>
					<Col lg={24}>
						<Form.Item name="volume_production">
							<TextArea style={{ width: '950' }} rows={10} maxLength={500} allowClear showCount />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
