export enum QUERY_KEYS {
	GET_WORKERS = 'get-workers',
	GET_COMPANIES = 'get-companies',
	GET_CHECKLISTS = 'get-checklists',
	GET_COMPLAINTS = 'get-complaints',
	GET_RESTRICTIONS = 'get-restrictions',
	GET_WORKER_HISTORY = 'get-worker-history',
	GET_FILE_ACTION_PLANS = 'get-file-action-plans',
	GET_WORKER_RESTRICTION = 'get-worker-restriction',
	GET_MEDICAL_CERTIFICATES = 'get-medical-certificates',
	GET_BUSINESS_INFORMATIONS = 'get-business-informations',
	GET_ERGONOMIC_WORK_ANALYSIS_LIST = 'get-ergonomic-work-analysis-list'
}
