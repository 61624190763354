export const statusTypeDownload = {
	NOT_PROCESSED: true,
	PROCESSING: true,
	EXTRACTED_DATA: true
};

export const statusTypeReports = {
	PROCESSING: true,
	NOT_PROCESSED: true
};

export enum RoleUserEnum {
	ADMIN = 'ADMIN',
	MASTER = 'MASTER',
	SUPERVISOR = 'SUPERVISOR',
	USER = 'USER'
}

export enum GenderEnum {
	FEMALE = 'Female',
	MALE = 'Male'
}

export const actionPlanBoards = [
	{ name: 'To Do', bg_color: '#E9ECEF' },
	{ name: 'Doing', bg_color: '#E8EEF5' },
	{ name: 'Done', bg_color: '#EBF3EE' }
];

export const actionPlanDateStatus = [
	{ value: 'ON_TIME', description: 'On time' },
	{ value: 'OVERDUE', description: 'Overdue' },
	{ value: 'THIS_WEEK', description: 'Finishing in a week' }
];

export const workerRestrictEnum = {
	TEMPORARY: 'Temporary',
	INDETERMINATE: 'Undetermined',
	NOT_RESTRICT: 'Without restriction'
};

export const bodyPartsComplaints = {
	hip: 'Hip',
	neck: 'Neck',
	lumbar_spine: 'Lumbar spine',
	backbone: 'Backbone',
	left_hand: 'Left hand',
	right_hand: 'Right hand',
	left_lower_arm: 'Left lower arm',
	right_lower_arm: 'Right lower arm',
	left_upper_arm: 'Left upper arm',
	right_upper_arm: 'Right upper arm',
	left_ankle: 'Left ankle',
	right_ankle: 'Right ankle',
	left_knee: 'Left knee',
	right_knee: 'Right knee'
};

export enum StatusFileDescriptionEnum {
	CORRUPTED_FILE = 'Corrupted file',
	EXTRACTED_DATA = 'Extracted data',
	NOT_PROCESSED = 'Not processing',
	PROCESSING = 'Processing',
	PROCESSED = 'Processed',
	IN_QUEUE = 'In queue'
}

export enum WorkerRestrictEnum {
	TEMPORARY = 'Temporary',
	INDETERMINATE = 'Undetermined',
	NOT_RESTRICT = 'Without restriction'
}
