import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Form, Input, Select, Switch, DatePicker, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ResponsibleWorker, SaveValues, Workstation, ChecklistItem } from '../../types';
import { ManagementContext } from '../../context';
import { Checklist } from './Checklist';
import Api from '@/services/api';

const { Option } = Select;

export const bodyPartsComplaints = {
	hip: 'Hip',
	neck: 'Neck',
	lumbar_spine: 'Lumbar spine',
	backbone: 'Backbone',
	left_hand: 'Left hand',
	right_hand: 'Right hand',
	left_lower_arm: 'Left lower arm',
	right_lower_arm: 'Right lower arm',
	left_upper_arm: 'Left upper arm',
	right_upper_arm: 'Right upper arm',
	left_ankle: 'Left ankle',
	right_ankle: 'Right ankle',
	left_knee: 'Left knee',
	right_knee: 'Right knee'
};

export function Complaint() {
	const {
		worker,
		selectWorkstationId,
		setSelectWorkstationId,
		complaintModal,
		workstationsByWorker,
		closeComplaintModal,
		setErrors,
		setTriggerReload
	} = useContext(ManagementContext);

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [checklists, setChecklists] = useState<ChecklistItem[] | null>(null);
	const [nexus, setNexus] = useState<boolean>(false);
	const [responsibleWorkers, setResponsibleWorkers] = useState<ResponsibleWorker[]>([]);

	const organizationId = useSelector((state: any) => state.organization.organization?.id);

	const globalGrade = {
		1: { text: 'HIGH_RISK' },
		2: { text: 'MODERATE_RISK' },
		3: { text: 'LOW_RISK' }
	};

	const [form] = Form.useForm();

	async function handleOnSubmit() {
		const values = await form.validateFields();
		onSave(values);
	}

	function handleOnClose() {
		closeComplaintModal();
		form.resetFields();
		setNexus(false);
	}

	function handleToggleNexus() {
		setNexus((prev) => !prev);
	}

	async function getChecklists(organizationId: string, selectWorkstationId: string): Promise<void> {
		if (!organizationId || !selectWorkstationId) return;
		let url = `/report/checklist/${organizationId}/${selectWorkstationId}`;
		try {
			const { data: workstation_checklists } = await Api.get(url);
			setChecklists(workstation_checklists);
		} catch (error: any) {
			message.error(error.toString());
			throw error;
		}
	}

	async function getResponsibleWorkers(organizationId: string): Promise<void> {
		if (!organizationId) return;
		let url = `/worker?organization_id=${organizationId}&worker_type=MANAGER`;
		try {
			const { data: responsible_workers } = await Api.get(url);
			setResponsibleWorkers(responsible_workers);
		} catch (errors) {
			setErrors(errors);
		}
	}

	const Footer = (): JSX.Element => (
		<>
			<Button key="cancel" onClick={handleOnClose}>
				{I18n.get('Cancel')}
			</Button>
			<Button key="submit" type="primary" onClick={handleOnSubmit} loading={isLoadingSubmit}>
				{I18n.get('Save')}
			</Button>
		</>
	);

	async function onSave(values: SaveValues) {
		try {
			setIsLoadingSubmit(true);
			const {
				description,
				body_part,
				workstation_id,
				action_plan_description,
				responsible_worker_id,
				deadline,
				collection_date
			} = values;

			const worstGrade = checklists?.[0]?.global_grade;
			const cause = worstGrade ? globalGrade[worstGrade].text : null;

			const payload = {
				organization_id: organizationId,
				complaint: {
					description,
					body_part,
					workstation_id,
					worker_id: worker!.id,
					nexus,
					cause,
					collection_date: moment(collection_date).startOf('day').format()
				},
				action_plan: nexus
					? {
							description: action_plan_description,
							deadline,
							responsible_worker_id,
							workstation_id
					  }
					: undefined
			};

			const { data } = await Api.post(`/complaint`, payload);
			message.success(I18n.get(data.message));
			closeComplaintModal();
			form.resetFields();
			setTriggerReload();
		} catch (errors) {
			setErrors(errors);
		} finally {
			setIsLoadingSubmit(false);
			setNexus(false);
		}
	}

	useEffect(() => {
		if (nexus) {
			getResponsibleWorkers(organizationId);
		}
	}, [nexus, organizationId]);

	useEffect(() => {
		getChecklists(organizationId, selectWorkstationId!);
	}, [organizationId, selectWorkstationId]);

	return (
		<Modal
			width={750}
			footer={<Footer />}
			open={complaintModal}
			onOk={handleOnSubmit}
			onCancel={handleOnClose}
			title={I18n.get('Fill the informations of the complaint:')}
		>
			<Form form={form} layout="vertical" name="create_complaint">
				<Row gutter={[10, 10]}>
					<Col sm={24}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description')
								}
							]}
						>
							<Input.TextArea showCount rows={4} maxLength={500} />
						</Form.Item>
					</Col>
					<Col sm={14}>
						<Form.Item
							name="body_part"
							label={I18n.get('Body parts')}
							rules={[
								{
									required: true,
									message: I18n.get('Please select a body part')
								}
							]}
						>
							<Select
								showSearch
								mode="tags"
								style={{ width: '100%' }}
								optionFilterProp="children"
								placeholder={I18n.get('Select an option')}
							>
								{Object.keys(bodyPartsComplaints).map((key) => (
									<Option key={key} value={key}>
										{I18n.get(bodyPartsComplaints[key as keyof typeof bodyPartsComplaints])}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={10}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Date of colletion')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format="L"
								style={{ width: '100%' }}
								placeholder={I18n.get('Date of colletion')}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="workstation_id"
							label={I18n.get('Workstation')}
							rules={[
								{
									required: true,
									message: I18n.get('Please select a workstation')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectWorkstationId(value);
								}}
								placeholder={I18n.get('Select a workstation')}
							>
								{workstationsByWorker?.map((workstation: Workstation, index: string) => (
									<Option key={index} value={workstation.value}>
										{workstation.title}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item name="nexus" valuePropName="checked">
							<Switch
								onChange={handleToggleNexus}
								checkedChildren={I18n.get('Nexus')}
								unCheckedChildren={I18n.get('Nexus')}
							/>
						</Form.Item>
					</Col>
					{nexus && (
						<>
							<Col sm={24}>
								<Form.Item
									name="action_plan_description"
									label={I18n.get('Action plan description')}
									rules={[
										{
											required: true,
											message: I18n.get('Please enter a valid action plan description')
										}
									]}
								>
									<Input placeholder={I18n.get('Action plan description')} />
								</Form.Item>
							</Col>
							<Col sm={16}>
								<Form.Item
									name="responsible_worker_id"
									label={I18n.get('Person responsible')}
									rules={[
										{
											message: I18n.get('Please enter a person responsible'),
											required: true
										}
									]}
								>
									<Select
										placeholder={I18n.get('Who is responsible for this action plan?')}
										allowClear
									>
										{responsibleWorkers.map((person) => (
											<Select.Option key={person.id} value={person.id}>
												{person.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={8}>
								<Form.Item
									name="deadline"
									label={I18n.get('Deadline')}
									rules={[
										{
											required: true,
											message: I18n.get('Please enter a valid date')
										}
									]}
								>
									<DatePicker
										format="DD/MM/YYYY"
										style={{ width: '100%' }}
										placeholder={I18n.get('Date')}
										disabledDate={(current) => current < moment().subtract(1, 'day')}
									/>
								</Form.Item>
							</Col>
						</>
					)}
					{selectWorkstationId && (
						<Col sm={24}>
							<Checklist checklists={checklists || []} />
						</Col>
					)}
				</Row>
			</Form>
		</Modal>
	);
}
