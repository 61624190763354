import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

export interface SignedUrlDTO {
	file_id: string;
	file_name: string;
	company_id: string;
	content_type: string;
	checklist_id: string;
	organization_id: string;
}

async function createSignedUrl(params: SignedUrlDTO): Promise<{
	url: string;
	id: string;
}> {
	const url = `/report/checklist/upload/create-url-signed`;
	const { data } = await Api.post(url, params);
	return data;
}

export const useCreateChecklistImageSignedUrl = () => {
	return useMutation(createSignedUrl);
};
