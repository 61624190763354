import styled from 'styled-components';
import { Row, Col } from 'antd';

export const RowCustomer = styled(Row)`
	border-radius: 10px;
	border: 1px solid #d9d9d9;
`;

export const TableTitle = styled(Col)<{ $hasColor?: boolean; $hasBorder?: boolean }>`
	font-weight: bold;
	padding: 20px 20px;
	background-color: ${({ $hasColor }) => $hasColor && '#E6E8E8'};

	&::before {
		content: '';
		position: absolute;
		background-color: ${({ $hasBorder }) => $hasBorder && '#F7F7F7'};
		height: 80%;
		width: 1px;
		left: 0px;
		bottom: 5px;
	}

	.ant-typography {
		margin: 0;
	}
`;

export const RpnScore = styled(Row)<{ $riskColor: string }>`
	padding: 5px 20px;
	height: 100%;
	font-weight: bold;
	background-color: ${({ $riskColor }) => $riskColor};
	color: #fff;
	border-radius: 0 10px 0 0;
	.ant-col:last-child {
		font-size: 18px;
		text-align: center;
	}
`;

export const TableDescription = styled(Col)<{ $customPadding?: string; $hasBorder?: boolean }>`
	padding: ${({ $customPadding }) => $customPadding ?? '20px'};
	border-left: ${({ $hasBorder }) => $hasBorder && '1px solid #d9d9d9'};
`;

export const TableListDescription = styled(Col)`
	padding: 20px 20px 10px 20px;
	.ant-row {
		border-bottom: 1px solid #d9d9d9;
		padding: 20px 0;
	}
	.ant-row:last-child {
		border-bottom: none;
	}

	.ant-col {
		text-align: start;
	}

	.ant-col:last-child {
		text-align: center;
	}
`;

export const DateComponent = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 3px 5px;
	.ant-col {
		border-right: 1px solid #d9d9d9;
		padding: 0 10px;
	}

	.ant-col:last-child {
		border: none;
	}
`;

export const Description = styled(Col)`
	.ant-typography {
		margin: 0;
	}
`;
