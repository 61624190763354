import React, { FC, InputHTMLAttributes } from 'react';
import { I18n } from '@aws-amplify/core';
import { FileImageOutlined } from '@ant-design/icons';
import { ContainerUpload, ContentUpload } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	hidden: boolean;
	onUploadFile: (files: FileList | null) => void;
}

export const Upload: FC<Props> = ({ hidden, onUploadFile, ...props }) => {
	if (hidden) {
		return <></>;
	}

	function onDrop(e: React.DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		const files = e.dataTransfer.files;
		if (files) {
			onUploadFile(files);
		}
	}

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files;
		if (files) {
			onUploadFile(files);
		}
		e.target.value = '';
		e.target.files = null;
	}

	return (
		<>
			<input id="file" type="file" style={{ display: 'none' }} multiple={true} onChange={onChange} {...props} />
			<label
				htmlFor="file"
				onDrop={onDrop}
				onDragOver={(e) => {
					e.preventDefault();
				}}
			>
				<ContainerUpload>
					<ContentUpload>
						<FileImageOutlined style={{ fontSize: '40px', color: '#2F54EB' }} />
						<p>{I18n.get('Upload file')}</p>
					</ContentUpload>
				</ContainerUpload>
			</label>
		</>
	);
};
