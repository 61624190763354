import React from "react";

function ActionPlanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M16.92 13.59a2.76 2.76 0 100 5.52 2.76 2.76 0 000-5.52zm0 3.78a1.02 1.02 0 11-.002-2.038 1.02 1.02 0 01.002 2.038zm6.12-15.69h-6.36C16.68.753 15.927 0 15 0h-2.4c-.927 0-1.68.753-1.68 1.68H4.56a.959.959 0 00-.96.96v6H.96A.959.959 0 000 9.6v13.44c0 .531.429.96.96.96h10.08c.531 0 .96-.429.96-.96v-.48h11.04c.531 0 .96-.429.96-.96V2.64a.959.959 0 00-.96-.96zM10.68 3.6h2.16V1.92h1.92V3.6h2.16v1.44h-6.24V3.6zm-.6 18.48H1.92v-6.96h8.16v6.96zm0-8.88H1.92v-2.64h8.16v2.64zm11.76 7.2H12V9.6a.959.959 0 00-.96-.96H5.76v-4.8h3v3.12h10.08V3.84h3V20.4zM17.76 8.88v2.88c0 .132.108.24.24.24h1.44a.24.24 0 00.24-.24V8.88a.24.24 0 00-.24-.24H18a.24.24 0 00-.24.24zM14.4 12h1.44a.24.24 0 00.24-.24v-1.68a.24.24 0 00-.24-.24H14.4a.24.24 0 00-.24.24v1.68c0 .132.108.24.24.24z"
      ></path>
    </svg>
  );
}

export default ActionPlanIcon;
