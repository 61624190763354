import React, { ReactNode, useContext, createContext, useState } from 'react';
import type { Context, Methods, States, Filter, FormData, SelectedReport } from './types';
import { useApplicationContext } from '@/context/application';
import moment, { Moment } from 'moment';

interface AETProviderProps {
	children: ReactNode;
}

const AETContext = createContext<Context>({} as Context);

export function AETProvider({ children }: Readonly<AETProviderProps>) {
	const { organization } = useApplicationContext();
	const initialValues = { organization_id: organization!.id, page: 1, limit: 10 };

	const [filter, setFilter] = useState<Filter>(initialValues);
	const [isOpenListFile, setIsOpenListFile] = useState<boolean>(false);
	const [selectedReport, setSelectedReport] = useState<SelectedReport>();

	const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

	function handleSetOffset(value: number): void {
		setFilter((prev: Filter) => ({ ...prev, page: value }));
	}

	function handleSetLimit(value: number): void {
		setFilter((prev: Filter) => ({ ...prev, limit: value }));
	}

	function handleStartDateFormat(startDate?: Moment): string | undefined {
		const initialDayHours = { hour: 0, minute: 0, second: 0, millisecond: 0 };
		return startDate ? moment(startDate).set(initialDayHours).format() : undefined;
	}

	function handleStartEndFormat(endDate?: Moment): string | undefined {
		const endDayHours = { hour: 23, minute: 59, second: 59, millisecond: 999 };
		return endDate ? moment(endDate).set(endDayHours).format() : undefined;
	}

	function handleSetFilter(values: FormData): void {
		const { company_id, name, start_date, end_date } = values;

		const startOfDay = handleStartDateFormat(start_date);
		const endOfDay = handleStartEndFormat(end_date);

		const payload = {
			organization_id: organization!.id,
			start_date: startOfDay,
			end_date: endOfDay,
			company_id,
			name,
			page: 1,
			limit: 10
		};

		setFilter(payload);
	}

	function handleOnClearFilter(): void {
		setFilter(initialValues);
	}

	function handleSelectedFiles(eaw_checklist: any) {
		setSelectedFiles(eaw_checklist);
	}

	function handleOpenModalListFile(): void {
		setIsOpenListFile((prev) => !prev);
	}

	function handleSelectReport(value?: SelectedReport): void {
		setSelectedReport(value);
	}

	const states: States = {
		selectedFiles,
		filter,
		isOpenListFile,
		selectedReport
	};

	const methods: Methods = {
		handleSetFilter,
		handleOnClearFilter,
		handleSetLimit,
		handleSetOffset,
		handleSelectedFiles,
		handleOpenModalListFile,
		handleSelectReport
	};

	const context: Context = {
		...states,
		...methods
	};

	return <AETContext.Provider value={context}>{children}</AETContext.Provider>;
}

export function useAETContext() {
	const context = useContext(AETContext);
	return context;
}
