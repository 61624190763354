import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input, Typography } from 'antd';

const TextArea = Input.TextArea;

export function Investments() {
	return (
		<Row>
			<Col span={24}>
				<Typography.Title level={5}>{I18n.get('Investments')}</Typography.Title>
			</Col>
			<Col span={24}>
				<Row>
					<Col sm={24}>
						<p>{I18n.get('Inform below the investments in ergonomics')}</p>
					</Col>
					<Col lg={24}>
						<Form.Item name="investments">
							<TextArea rows={10} maxLength={500} allowClear showCount />
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
