import { useMutation } from '@tanstack/react-query';
import Api from '@/services/api';

interface DeleteCheckListImageDTO {
	id: string;
	file_id: string,
	company_id: string,
	checklist_id: string,
	organization_id: string;
}

interface DeleteCheckListImageSuccess {
	status: string;
	message: string;
	data: {
		medical_certificate_id: string;
	};
}

async function deleteCheckListImage({
	id,
	file_id,
	company_id,
	checklist_id,
	organization_id,
}: DeleteCheckListImageDTO): Promise<DeleteCheckListImageSuccess> {
	const url = `/report/checklist/upload/${id}`;
	const { data } = await Api.delete(url, { params: { organization_id, company_id, checklist_id, file_id } });
	return data;
}

export const useDeleteCheckListImage = () => {
	return useMutation(deleteCheckListImage);
};
