import Api from '@/services/api';
import type { ErrorResponse } from '@/types';
import type {
	FilterParams,
	FilterSuccess,
	DeleteParams,
	DeleteSuccess,
	GetFilesParams,
	GetFilesSuccess,
	DownloadParams,
	DownloadSuccess,
	CreateParams,
	CreateSuccess
} from './dto/ergonomic-work-analysis.dto';

interface IErgonomicWorkAnalysisService {
	index: (params: FilterParams) => Promise<FilterSuccess>;
	remove: (params: DeleteParams) => Promise<DeleteSuccess | ErrorResponse>;
	getFiles: (params: GetFilesParams) => Promise<GetFilesSuccess>;
	download: (params: DownloadParams) => Promise<DownloadSuccess>;
	create: (params: CreateParams) => Promise<CreateSuccess>;
}

class ErgonomicWorkAnalysisService implements IErgonomicWorkAnalysisService {
	async index(params: FilterParams): Promise<FilterSuccess> {
		const { data } = await Api.get(`/aet`, { params });
		return data;
	}

	async remove(params: DeleteParams): Promise<DeleteSuccess | ErrorResponse> {
		const { organization_id, company_id, id } = params;
		const { data } = await Api.delete(`/aet/${id}`, { params: { organization_id, company_id } });
		return data;
	}

	async getFiles(params: GetFilesParams): Promise<GetFilesSuccess> {
		const { organization_id, company_id, id } = params;
		const { data } = await Api.get(`/aet/${id}/files`, { params: { organization_id, company_id } });
		return data;
	}

	async download(params: DownloadParams): Promise<DownloadSuccess> {
		const { id, ...body } = params;
		const { data } = await Api.post(`/aet/${id}/download`, body);
		return data;
	}

	async create(params: CreateParams): Promise<CreateSuccess> {
		const { data } = await Api.post('/aet', params);
		return data;
	}
}

const Service = Object.freeze(new ErgonomicWorkAnalysisService());
export { Service };
