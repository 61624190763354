import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useManageOrganization } from '../../../context';
import { Cancel, Confirm, NameInput } from '../styles';

const { useFormInstance } = Form;

type EditProductProps = {
	productName: string;
	onProductNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const EditProduct: React.FC<EditProductProps> = ({
	productName,
	onProductNameChange,
	onEditing,
}) => {
	const [editingName] = useState(productName);
	const {
		updateOrganizationData,
		treatFieldValidation,
		handleIsEditing,
		companyId,
		organizationId,
		workstationId,
		productId,
	} = useManageOrganization();

	const { setFieldValue, validateFields } = useFormInstance();

	function handleProductNameChange(name: string) {
		onProductNameChange(name);
	}

	function handleCancel() {
		onProductNameChange('');
		handleIsEditing(false);
		onEditing(false);
		setFieldValue('product_id', productId);
	}

	async function handleUpdateProduct(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();
		try {
			await validateFields(['edit_product_name']);

			updateOrganizationData({
				organization_id: organizationId,
				company_id: companyId,
				workstation_id: workstationId,
				product_id: productId,
				name: productName,
			}).then(({ id }) => {
				onProductNameChange('');
				handleIsEditing(false);
				onEditing(false);
				setFieldValue('product_id', id);
				setFieldValue('edit_product_name', '');
			});
		} catch (error: any) {
			treatFieldValidation(error);
		}
	}

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<NameInput
						name="edit_product_name"
						label={I18n.get('Edit product')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
						rules={[
							{
								required: true,
								message: I18n.get('Please, enter a valid product name'),
							},
						]}
					>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
								border: '2px solid #000',
							}}
							value={productName}
							onChange={(e) => handleProductNameChange(e.target.value)}
						/>
					</NameInput>
				</Col>
				<Col span={4}>
					<Row align="bottom" justify="space-between">
						<Cancel span={12}>
							<Button
								disabled={!companyId || !productId}
								size="middle"
								type="text"
								onClick={() => handleCancel()}
								icon={<CloseOutlined />}
							/>
						</Cancel>
						<Confirm span={12}>
							<Button
								disabled={
									!companyId ||
									!productId ||
									!productName ||
									editingName === productName
								}
								size="middle"
								type="text"
								onClick={(e) => handleUpdateProduct(e)}
								icon={<CheckOutlined />}
							/>
						</Confirm>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
