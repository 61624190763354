import React from 'react';
import { Col, Button } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { BarChart } from './Chart/Bar';

const Container = styled.div`
	position: absolute;
	right: 80px;
	top: 50px;
`;

interface MatrixChartProps {
	hasSelection: boolean;
	onClearFilter: () => void;
}

export function MatrixChart({ hasSelection, onClearFilter }: Readonly<MatrixChartProps>) {
	return (
		<Col span={24}>
			{hasSelection && (
				<Container>
					<Button onClick={onClearFilter}>{I18n.get('Clear filter')}</Button>
				</Container>
			)}
			<BarChart minHeight={24} />
		</Col>
	);
}
