import React from 'react';
import { I18n } from '@aws-amplify/core';
import FilesReviewIcon from '@/assets/icons/Files';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, Space, Tooltip, Button, message } from 'antd';
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useAETContext } from './context';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useRemoveErgonomicAnalysisWork } from '@/hooks/useRemoveErgonomicWorkAnalysis';
import { useDownloadErgonomicWorkAnalysis } from '@/hooks/useDownloadErgonomicWorkAnalysis';
import type { ErgonomicWorkAnalisysListItem } from '@/types/entities/ErgonomicWorkAnalisys';

const { GET_ERGONOMIC_WORK_ANALYSIS_LIST } = QUERY_KEYS;

interface ActionsProps {
	data: ErgonomicWorkAnalisysListItem;
}

export function Actions({ data }: ActionsProps) {
	const queryClient = useQueryClient();
	const remove = useRemoveErgonomicAnalysisWork();
	const download = useDownloadErgonomicWorkAnalysis();
	const { handleOpenModalListFile, handleSelectReport } = useAETContext();

	function handleViewFile(): void {
		const payload = {
			organization_id: data.organization.id,
			company_id: data.company.id,
			id: data.id
		};
		handleOpenModalListFile();
		handleSelectReport(payload);
	}

	async function handleDownloadFileReport(): Promise<void> {
		try {
			const result = await download.mutateAsync({
				organization_id: data.organization.id,
				company_id: data.company.id,
				id: data.id
			});

			let element = document.createElement('a');
			element.target = '_blank';
			element.href = result.data.url;
			element.click();
			element.remove();
		} catch (error) {
			message.error(I18n.get('Failed to download file'));
		}
	}

	function handleDeleteFile(): void {
		Modal.confirm({
			title: I18n.get('Warning'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('No'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this report?'),
			onOk() {
				return new Promise(async (resolve, reject) => {
					try {
						await remove.mutateAsync({
							organization_id: data.organization.id,
							company_id: data.company.id,
							id: data.id
						});
						queryClient.invalidateQueries([GET_ERGONOMIC_WORK_ANALYSIS_LIST]);
						resolve(true);
					} catch (error: any) {
						const message_err = error?.response?.data?.message || error?.message;
						message.error(I18n.get(message_err));
						reject();
					}
				});
			}
		});
	}

	return (
		<Space size="middle">
			<Tooltip placement="bottomRight" title={I18n.get('View files')}>
				<Button ghost type="link" onClick={handleViewFile} icon={<FilesReviewIcon />} />
			</Tooltip>
			<Tooltip placement="bottomRight" title={I18n.get('Dowload report')}>
				<Button
					ghost
					type="link"
					disabled={!data.location}
					onClick={handleDownloadFileReport}
					icon={<DownloadOutlined />}
				/>
			</Tooltip>
			<Tooltip placement="bottomRight" title={I18n.get('Delete report')}>
				<Button ghost danger type="link" onClick={handleDeleteFile} icon={<DeleteOutlined />} />
			</Tooltip>
		</Space>
	);
}
