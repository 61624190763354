import React from 'react';
import { Worker } from './types';

export interface ManagementState {
	companies: any[];
	selectCompanyId: string | null;
	sectors: any[];
	selectSectorId: string | null;
	lines: any[];
	selectLineId: string | null;
	workstations: any[];
	selectWorkstationId: string | null;
	workers: Worker[] | null;
	isFetchWorkers: boolean;
	workerCreateModal: boolean;
	isLoadingWorkerCreate: boolean;
	workerUpdateModal: boolean;
	triggerReload: boolean | null;
	worker: Worker | null;
	errors: any;
	complaintOrCertificateModal: boolean;
	complaintModal: boolean;
	medicalCertificateModal: boolean;
	workstationsByWorker: any;
	cidm: string[] | null;
	workerDeleteModal: boolean;
	workerActivateModal: boolean;
	restrictionModal: boolean;
	newRestrictionModal: boolean;
	updateRestrictionModal: boolean;
	workerRestrictionId: string | null;
	employeeHistoryModal: boolean;
}

const defaultState: ManagementState = {
	companies: [],
	selectCompanyId: null,
	sectors: [],
	selectSectorId: null,
	lines: [],
	selectLineId: null,
	workstations: [],
	selectWorkstationId: null,
	workers: null,
	isFetchWorkers: true,
	workerCreateModal: false,
	isLoadingWorkerCreate: false,
	workerUpdateModal: false,
	triggerReload: null,
	worker: null,
	errors: null,
	complaintOrCertificateModal: false,
	complaintModal: false,
	medicalCertificateModal: false,
	workstationsByWorker: null,
	cidm: null,
	workerDeleteModal: false,
	workerActivateModal: false,
	restrictionModal: false,
	newRestrictionModal: false,
	updateRestrictionModal: false,
	workerRestrictionId: null,
	employeeHistoryModal: false
};

const defaultContext = {
	...defaultState,
	setSelectCompanyId: (_: string | null) => {},
	setSelectSectorId: (_: string | null) => {},
	setSelectLineId: (_: string | null) => {},
	setSelectWorkstationId: (_: string | null) => {},
	onFilterWorker: (values?: Record<string, any>) => {},
	setTriggerReload: () => {},
	onCreateWorker: (_: any) => {},
	showWorkerCreateModal: () => {},
	closeWorkerCreateModal: () => {},
	showWorkerEditModal: (_: any) => {},
	closeWorkerEditModal: () => {},
	setErrors: (_: any) => {},
	getCompanies: (_: string) => {},
	getSectors: (_: string, __: string) => {},
	getLines: (_: string, __: string, ___: string) => {},
	getWorkstations: (_: string, __: string, ___: string, ____: string) => {},
	showComplaintOrCertificateModal: (_: any) => {},
	closeComplaintOrCertificateModal: () => {},
	showComplaintModal: (_: any, __: any) => {},
	closeComplaintModal: () => {},
	showMedicalCertificateModal: (_: any, __: any) => {},
	closeMedicalCertificateModal: () => {},
	setWorkstationsByWorker: (_: any) => {},
	getCidM: (_: string) => {},
	handleDeactivateWorker: (_: any) => {},
	handleActivateWorker: (_: any) => {},
	showWorkerDeleteModal: (_: any) => {},
	showWorkerActivateModal: (_: any) => {},
	closeWorkerDeleteModal: () => {},
	closeWorkerActivateModal: () => {},
	closeRestrictionModal: () => {},
	showNewRestrictionModal: () => {},
	closeNewRestrictionModal: () => {},
	showUpdateRestrictionModal: (_: string) => {},
	closeUpdateRestrictionModal: () => {},
	showEmployeeHistoryModal: (_: any) => {},
	closeEmployeeHistoryModal: () => {},
	showRestrictionModal: (_: any, __: any) => {}
};

export const ManagementContext = React.createContext(defaultContext);
