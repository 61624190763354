import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import FileSize from 'filesize';
// import { Prompt } from 'react-router';
import { Row, Col, Button, Form, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useSelector, shallowEqual } from 'react-redux';

import { I18n } from '@aws-amplify/core';
import History from '@/store/history';
import Api from '@/services/api';

import { Drop } from './Drop';
import { UploadList } from './List';
import { Container } from './styles';
import axios from 'axios';

const TIMEOUT_REQUEST = 1800000;

const { useForm } = Form;

//upmostly.com/tutorials/react-dropzone-file-uploads-react#:~:text=Luckily%20we%20can%20limit%20the,%2C%201%20Megabyte%20%3D%201048576%20Bytes.

export function UploadFile() {
	const [form] = useForm();
	const [errors, setErrors] = useState(null);

	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [inProcessUpload, setInProcessUpload] = useState(false);

	const [fileNotProcessed, setFileNotProcessed] = useState(false);
	const [redirectOnSuccess, setRedirectOnSuccess] = useState(false);

	const expirationPlan = useSelector((state) => state.user.expirationPlan);

	const { organization, company } = useSelector(
		({ organization }) => ({
			company: organization.company,
			organization: organization.organization
		}),
		shallowEqual
	);

	useEffect(() => {
		if (errors) {
			const stack = errors.response?.data?.message;
			message.error(I18n.get(stack || 'Error loading video'));
			setErrors(null);
		}
	}, [errors]);

	useEffect(() => {
		uploadedFiles.forEach((file) => {
			if (!file.blocking) {
				setFileNotProcessed(true);
			}
			setRedirectOnSuccess(file.uploaded ? true : false);
		});
	}, [uploadedFiles, fileNotProcessed]);

	useEffect(() => {
		if (redirectOnSuccess) {
			message.loading(I18n.get('You are being redirected'), 5).then(() => History.push('/files'));
		}
	}, [redirectOnSuccess]);

	const handleUpload = (files) => {
		const arrayFiles = files.map((file) => ({
			file,
			id: _.uniqueId(),
			name: file.name,
			readableSize: FileSize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
			blur: false,
			tool_type: 'rula',
			blocking: false
		}));

		setUploadedFiles((current) => current.concat(arrayFiles));
	};

	const onDelete = (id) => {
		setUploadedFiles((current) => current.filter((file) => file.id !== id));
	};

	const updateFile = (id, data) => {
		setUploadedFiles((current) =>
			current.map((uploadedFile) => (id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile))
		);
	};

	const onSubmit = () => {
		uploadedFiles.forEach(processUpload);
	};

	const onClear = () => {
		form.setFieldsValue({ tool: 'rula' });
		setUploadedFiles([]);
	};

	const updateProgressUpload = (e, id) => {
		const progress = parseInt(Math.round((e.loaded * 100) / e.total));
		updateFile(id, { progress, blocking: true });
	};

	const processUpload = async (uploadedFile) => {
		if (!uploadedFile) return;

		const { id, file } = uploadedFile;

		try {
			setInProcessUpload(true);
			updateFile(id, { progress: 1, blocking: true });

			const { data } = await Api.post('/upload/create-url-signed', {
				file_name: file.name,
				company_id: company.id,
				content_type: file.type,
				organization_id: organization.id
			});

			const headers = {
				onUploadProgress: (e) => updateProgressUpload(e, id),
				timeout: TIMEOUT_REQUEST,
				headers: {
					'Content-Type': file.type
				}
			};

			await axios.put(data.url, file, headers);

			updateFile(id, { uploaded: true, blocking: true });
		} catch (error) {
			updateFile(id, { error: true, blocking: false });
			setErrors(error);
		} finally {
			setInProcessUpload(false);
		}
	};

	return (
		<Container>
			<Row type="flex" align="middle" justify="center">
				{/* <Prompt
					when={inProcessUpload}
					message={I18n.get(
						'Are you sure you want to quit? You have an upload in process.'
					)}
				/> */}
				<Col sm={24} md={18} lg={18}>
					<Drop
						onUpload={handleUpload}
						disabled={expirationPlan?.expired || expirationPlan?.canceled_on ? true : false}
					/>
				</Col>
				<Col sm={24} md={18} lg={18}>
					<UploadList form={form} onDelete={onDelete} files={uploadedFiles} onUpdate={updateFile} />
					{!!uploadedFiles.length > 0 && (
						<Button
							type="primary"
							onClick={onSubmit}
							style={{ float: 'right' }}
							disabled={inProcessUpload || !fileNotProcessed}
							icon={<CloudUploadOutlined />}
						>
							{I18n.get('Send')}
						</Button>
					)}
					{!!uploadedFiles.length > 0 && (
						<Button onClick={onClear} style={{ float: 'right', marginRight: '5px' }}>
							{I18n.get('Clear')}
						</Button>
					)}
				</Col>
			</Row>
		</Container>
	);
}
