import React from 'react';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Form, Collapse, Select, Input, DatePicker } from 'antd';

import { useAETContext } from './context';
import { Button } from '@/components/ui/Button';
import { useGetCompanies } from '@/hooks/useGetCompanies';
import { useApplicationContext } from '@/context/application';
import type { FormData } from './types';

const FORMAT_DATE = 'DD/MM/YYYY';

export function Filter() {
	const [form] = Form.useForm<FormData>();
	const { organization } = useApplicationContext();
	const { handleSetFilter, handleOnClearFilter } = useAETContext();
	const companies = useGetCompanies({ organization_id: organization?.id });

	async function handleOnFinish(): Promise<void> {
		const values = await form.validateFields();
		handleSetFilter(values);
	}

	function handleOnResetFilter(): void {
		form.resetFields();
		handleOnClearFilter();
	}

	return (
		<Collapse defaultActiveKey={['0']}>
			<Collapse.Panel header={I18n.get('Filters')} key="1">
				<Form form={form} layout="vertical" onFinish={handleOnFinish}>
					<Row gutter={[10, 0]}>
						<Col xs={24} sm={6}>
							<Form.Item name="company_id" label={I18n.get('Industrial site')}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={I18n.get('Select a industrial site')}
									style={{ width: '100%' }}
								>
									{companies.data?.map((company) => (
										<Select.Option key={company.id} value={company.id}>
											{company.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={6}>
							<Form.Item name="name" label={I18n.get('Name')}>
								<Input placeholder={I18n.get('Name')} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={6}>
							<Form.Item name="start_date" label={I18n.get('Initial date')}>
								<DatePicker
									format={FORMAT_DATE}
									placeholder={I18n.get('Initial date')}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={6}>
							<Form.Item name="end_date" label={I18n.get('End date')}>
								<DatePicker
									format={FORMAT_DATE}
									placeholder={I18n.get('End date')}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={6} style={{ paddingTop: '20px' }}>
						<Col sm={3}>
							<Button
								type="primary"
								htmlType="submit"
								style={{ width: '100%' }}
								icon={<SearchOutlined />}
							>
								{I18n.get('Search')}
							</Button>
						</Col>
						<Col sm={3}>
							<Button onClick={handleOnResetFilter} style={{ width: '100%' }}>
								{I18n.get('Clear')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Collapse.Panel>
		</Collapse>
	);
}
