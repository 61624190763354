import styled from 'styled-components';
import { Button, Modal, Checkbox, Col, Row } from 'antd';

export const Title = styled.h2`
	font-size: 20px;
`;

export const ModalStyled = styled(Modal)`
	.ant-modal-footer {
		border-top: none !important;
		padding-bottom: 30px;
	}
`;

export const StyledCancelButton = styled(Button)`
	height: 40px;
	width: 25%;
	font-weight: bold;
`;

export const StyledFooter = styled.div`
	text-align: center;
	margin-top: 0px;
`;

export const CustomButton = styled(Button)`
	&.ant-btn-background-ghost.ant-btn-primary {
		border-color: #17a93b !important;
		color: #17a93b !important;
	}
`;

export const StyledModalContent = styled.div`
	margin-top: 20px;
	text-align: center;
	.modal-icon {
		font-size: 60px;
		color: #2f54eb;
		margin-bottom: 2rem;
	}
	.modal-title {
		font-size: 20px;
		font-weight: normal;
		margin-bottom: 8px;
	}
	.modal-employee-name {
		font-size: 20px;
	}
`;

export const StyledOkButton = styled(Button)`
	background-color: #2f54eb;
	color: white;
	font-weight: bold;
	border: none;
	width: 25%;
	height: 40px;
	margin-left: 20px !important;
	&:hover {
		background-color: #1d3bb8;
	}
`;

export const StyledCheckbox = styled(Checkbox)`
	&.custom-checkbox-indeterminate {
		margin-right: 10px;
	}

	&.custom-checkbox-indeterminate .ant-checkbox-inner {
		position: relative;
		background-color: #fff;
		border-color: #1890ff;
	}

	&.custom-checkbox-indeterminate .ant-checkbox-inner::after {
		content: ' ';
		position: absolute;
		background-color: #1890ff;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 2px;
		transform: translate(-50%, -50%);
		border: 5px solid #1400ff;
	}

	@media (max-width: 1366px) {
		.label-checkbox {
			font-size: 10px !important;
		}
	}
`;

export const StyledCol = styled(Col)`
	margin-right: 3%;
	@media (max-width: 1366px) {
		margin-right: 1%;
	}
`;

export const StyledColResponsive = styled(Col)`
	@media (max-width: 1366px) {
		margin-right: 1%;
	}
`;

export const ModalStyledRestriction = styled(Modal)`
	.ant-modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 2rem;
		flex-direction: column;
		width: 100%;
	}
`;

export const StyledModalContentRestriction = styled.div`
	cursor: pointer;
	margin-top: 20px;
	margin-bottom: 50px;
	text-align: center;
	border: 2px solid #2f54eb;
	padding-top: 1rem;
	width: 80%;
	&:hover {
		background-color: #eaeefd;
	}

	.modal-icon {
		font-size: 60px;
		color: #2f54eb;
		margin-bottom: 0.6rem;
	}
	.modal-title {
		font-size: 18px;
		font-weight: normal;
		margin-bottom: 8px;
		color: #2f54eb;
	}
	.modal-employee-name {
		font-size: 18px;
	}
`;

export const StatusIncon = styled.span`
	display: 'inline-block';
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 8px;
`;

export const StyledTableContainer = styled.div`
	display: block;
	width: 100%;

	.ant-table-thead > tr > th {
		background-color: #fff;
		font-weight: bold;
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
			[colspan]
		)::before {
		display: none;
	}

	.ant-table-row {
		background-color: #f8f8f8;
	}

	.ant-table-cell {
		border-bottom: 1px solid #e6e6e6 !important;
	}

	.ant-btn-icon-only > * {
		font-size: 1.5rem;
	}
`;

export const StyledButtonContainer = styled.div`
	display: block;
	margin-top: 0.5rem;
	cursor: pointer;

	.modal-icon {
		font-size: 60px;
		color: #2f54eb;
	}
`;

export const ResponsibleRowCheckbox = styled(Row)`
	@media (max-width: 1440px) {
		display: none;
	}
`;

export const ResponsibleRow = styled(Row)`
	display: none;
	@media (max-width: 1440px) {
		display: block;
	}
`;

export const Container = styled.div`
	padding-bottom: 10px;
	width: 100%;
`;

export const ResponsiveTableContainer = styled.div`
	@media (max-width: 1366px) {
		.hide-on-small {
			display: none;
		}

		.expand-on-small {
			width: 50%;
		}
	}
`;
