import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetOrganizations } from '@/hooks/useGetOrganizations';
import { useAETCreateContext } from '../../context';

const { useFormInstance } = Form;

export function Organization() {
	const { organization_id } = useAETCreateContext();

	const { data: organization, isRefetching } = useGetOrganizations({
		organization_id
	});

	const { setFieldValue } = useFormInstance();

	useEffect(() => {
		if (organization_id) {
			setFieldValue('organization_id', organization?.name);
		}
	}, [organization_id, isRefetching, organization, setFieldValue]);

	return (
		<Form.Item name="organization_id">
			<Select showSearch filterOption={false} disabled showArrow={false} placeholder={I18n.get('Company')} />
		</Form.Item>
	);
}
