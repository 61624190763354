import React, { useEffect } from 'react';
import { Form, Select, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetCompanies } from '@/hooks/useGetCompanies';
import { useAETCreateContext } from '../../context';

const { useFormInstance } = Form;

export function Company() {
	const { organization_id, company_id, queryParams, setCompanyId, setQueryParams, setSectorId, setWorkstation } =
		useAETCreateContext();

	const form = useFormInstance();

	const { isInitialLoading, data, isError, refetch } = useGetCompanies({ organization_id });

	const { setFieldValue } = useFormInstance();

	useEffect(() => {
		if (organization_id) {
			setFieldValue('company_id', company_id);
			setCompanyId(company_id);
		}
	}, [organization_id, company_id]);

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to search for companies'));
		}
	}, [isError]);

	useEffect(() => {
		if (!company_id) {
			refetch();
		}
	}, [company_id, refetch]);

	function handleOnClearForm(): void {
		form.resetFields(['sector_id', 'workstation']);
	}

	function handleOnClearHierarchy(): void {
		setSectorId('');
		setWorkstation([]);
	}

	function handleOnChange(value: string): void {
		setCompanyId(value);
		handleOnClearHierarchy();
		handleOnClearForm();
		setQueryParams({ ...queryParams, company_id: value });
	}

	return (
		<Form.Item name="company_id">
			<Select
				showSearch
				disabled={true}
				loading={isInitialLoading}
				onChange={handleOnChange}
				placeholder={I18n.get('Industrial Site')}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
			/>
		</Form.Item>
	);
}
