import React from 'react';

import { I18n } from '@aws-amplify/core';
import { DatePicker, Form } from 'antd';
import { useAETCreateContext } from '../../context';

export const CollectionDate: React.FC = () => {
	const { organization_id, company_id, setCollectionDate, queryParams, setQueryParams } = useAETCreateContext();

	function handleCollectionDateChange(collection_date: string[]) {
		setCollectionDate(collection_date);
		setQueryParams({
			...queryParams,
			collection_date
		});
	}

	return (
		<Form.Item label={I18n.get('Collection date')} name="collection_date">
			<DatePicker.RangePicker
				disabled={!organization_id || !company_id}
				showTime={{ format: 'HH:mm:00' }}
				format="DD-MM-YYYY HH:mm:00"
				style={{ width: '100%' }}
				onChange={(_date, dateString) => handleCollectionDateChange(dateString)}
			/>
		</Form.Item>
	);
};
