import React, { useState } from 'react';
import { ManageOrganizationProvider } from './context';
import { Col, Row as Container } from 'antd';
import { I18n } from '@aws-amplify/core';
import hooks from './hooks';

import { Spinner } from '@/components/SpinnerDeprecated';

import { CreateOrganization } from './CreateOrganization';
import { OrganizationsTable } from './OrganizationsTable';
import { Header } from './Header';

const { useGetOrganizations } = hooks;

export function ManageOrganizations() {
	const [openCreateModal, setOpenCreateModal] = useState(false);

	const {
		data: organizationsList,
		isLoading: organizationsListLoading,
		isError: organizationsListError,
		refetch,
	} = useGetOrganizations();

	function onShowCreate() {
		setOpenCreateModal(true);
	}

	function onCloseCreate() {
		setOpenCreateModal(false);
		refetch();
	}

	if (organizationsListLoading) {
		return <Spinner />;
	}

	if (organizationsListError) {
		return <div>{I18n.get('Unable to show results')}</div>;
	}

	return (
		<ManageOrganizationProvider>
			<Container>
				<Header onCreate={onShowCreate} />
				<CreateOrganization visible={openCreateModal} onClose={onCloseCreate} />
				<Col span={24}>
					<OrganizationsTable organizations={organizationsList} />
				</Col>
			</Container>
		</ManageOrganizationProvider>
	);
}
