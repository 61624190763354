import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

interface GetSectorsDTO {
	organization_id?: string;
	company_id?: string;
}

interface SectorDTO {
	id: string;
	name: string;
	company_id: string;
}

async function getSectors({ organization_id, company_id }: GetSectorsDTO): Promise<SectorDTO[]> {
	let url = `/sector/${organization_id}/${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetSectors = (params: GetSectorsDTO) => {
	return useQuery(['get-sectors', params], () => getSectors(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
