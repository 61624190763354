import React, { useContext, useState } from 'react';
import { Row, Col, Modal, Button, Form, Input, Select, DatePicker, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import Api from '@/services/api';
import { ManagementContext } from '../../context';
import { Workstation, FormValues } from '../../types';

const { Option } = Select;

export function MedicalCertificate() {
	const [form] = Form.useForm<FormValues>();

	const {
		cidm,
		worker,
		setSelectWorkstationId,
		medicalCertificateModal,
		workstationsByWorker,
		closeMedicalCertificateModal,
		setErrors,
		setTriggerReload
	} = useContext(ManagementContext);

	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const organizationId = useSelector((state: any) => state.organization.organization?.id);

	function onClose() {
		form.resetFields();
		closeMedicalCertificateModal();
	}

	async function onSubmit() {
		const values = await form.validateFields();
		onSave(values);
	}

	const Footer = () => (
		<>
			<Button key="cancel" onClick={onClose}>
				{I18n.get('Cancel')}
			</Button>
			<Button key="submit" type="primary" onClick={onSubmit} loading={loadingSubmit}>
				{I18n.get('Save')}
			</Button>
		</>
	);

	async function onSave(values: FormValues): Promise<void> {
		try {
			setLoadingSubmit(true);

			const { cidm, description, workstation, collection_date } = values;

			const payload = {
				cidm_id: cidm,
				worker_id: worker!.id,
				description: description,
				workstation_id: workstation,
				organization_id: organizationId,
				collection_date: moment(collection_date).startOf('day').format()
			};

			const { data } = await Api.post(`/medical_certificate`, payload);
			message.success(I18n.get(data.message));
			form.resetFields();
			closeMedicalCertificateModal();
			setTriggerReload();
		} catch (errors) {
			setErrors(errors);
		} finally {
			setLoadingSubmit(false);
		}
	}

	return (
		<Modal
			width={750}
			onOk={onSubmit}
			onCancel={onClose}
			footer={<Footer />}
			open={medicalCertificateModal}
			title={I18n.get('Fill the informations of the medical certificate:')}
		>
			<Form form={form} layout="vertical" name="create_medical_certificate">
				<Row gutter={[10, 10]}>
					<Col sm={24}>
						<Form.Item
							name="cidm"
							label={I18n.get('CID-M')}
							rules={[
								{
									required: true,
									message: I18n.get('Select the CID-M')
								}
							]}
						>
							<Select
								showSearch
								style={{ width: '100%' }}
								optionFilterProp="children"
								filterOption={(inputValue, option) => {
									const children = option?.children as unknown as string;
									return children.toLowerCase().includes(inputValue.toLowerCase());
								}}
								placeholder={I18n.get('Select the CID-M')}
							>
								{cidm?.map((item: any, index: number) => (
									<Option key={index} value={item.id}>
										{item.code} - {item.description}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description')
								}
							]}
						>
							<Input.TextArea showCount rows={4} maxLength={500} />
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="workstation"
							label={I18n.get('Workstation')}
							rules={[
								{
									required: true,
									message: I18n.get('Select a workstation')
								}
							]}
						>
							<Select
								style={{ width: '100%' }}
								onChange={(value) => {
									setSelectWorkstationId(value);
								}}
								placeholder={I18n.get('Select a workstation')}
							>
								{workstationsByWorker?.map((workstation: Workstation, index: number) => (
									<Option key={index} value={workstation.value}>
										{workstation.title}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Date of colletion')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Date of colletion')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
