import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Tooltip, Table, Space, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { useAETContext } from '../context';
import { useDownloadFile } from '@/hooks/useDownloadFile';
import { useGetFilesErgonomicWorkAnalysis } from '@/hooks/useGetFilesErgonomicWorkAnalysis';
import type { FileItem } from './types';
import type { ColumnsType } from 'antd/es/table';

export function List() {
	const { selectedReport } = useAETContext();
	const download = useDownloadFile();
	const { isLoading, data, isError } = useGetFilesErgonomicWorkAnalysis(selectedReport!);

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch file list'));
		}
	}, [isError]);

	async function handleDownloadFile(file: FileItem): Promise<void> {
		try {
			const url = await download.mutateAsync({
				organization_id: file.organization_id,
				original_name: file.original_name,
				company_id: file.company_id,
				id: file.id
			});

			let element = document.createElement('a');
			element.target = '_blank';
			element.href = url;
			element.click();
			element.remove();
		} catch (error) {
			message.error(I18n.get('Failed to download file'));
		}
	}

	const columns: ColumnsType<FileItem> = [
		{
			title: I18n.get('Name'),
			key: 'original_name',
			align: 'center',
			dataIndex: 'original_name',
			width: '40%',
			render: (value) => <p>{value}</p>
		},
		{
			title: I18n.get('Date'),
			key: 'created_at',
			align: 'center',
			dataIndex: 'created_at',
			width: '40%',
			render: (value) => new Date(value).toLocaleDateString('pt-BR')
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center',
			render: (item) => (
				<Space size="middle">
					<Tooltip placement="bottomRight" title={I18n.get('Dowload file')}>
						<Button
							ghost
							type="link"
							size="large"
							icon={<DownloadOutlined />}
							onClick={() => handleDownloadFile(item)}
						/>
					</Tooltip>
				</Space>
			)
		}
	];

	return (
		<Table
			rowKey="id"
			columns={columns}
			loading={isLoading}
			dataSource={data?.rows}
			pagination={{ pageSize: 10 }}
		/>
	);
}
