import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface DeleteWorkerRestrictionDTO {
	id: string;
	company_id?: string;
	organization_id?: string;
}

interface DeleteWorkerRestrictionSuccess {
	status: string;
	message: string;
	data: {
		worker_restriction: string;
	};
}

async function deleteWorkerRestriction({
	id,
	company_id,
	organization_id
}: DeleteWorkerRestrictionDTO): Promise<DeleteWorkerRestrictionSuccess> {
	const url = `/worker-restriction/restriction/${id}`;
	const { data } = await Api.delete(url, { params: { organization_id, company_id } });
	return data;
}

export const useDeleteWorkerRestriction = () => {
	const queryClient = useQueryClient();
	return useMutation((prams: DeleteWorkerRestrictionDTO) => deleteWorkerRestriction(prams), {
		onSuccess: ({ message: txt }: DeleteWorkerRestrictionSuccess) => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_RESTRICTIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_WORKERS]);
			message.success(I18n.get(txt));
		}
	});
};
