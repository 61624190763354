import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Col, Table, Tag } from 'antd';

import Api from '@/services/api';
import { LineInfo } from './LineInfo';
import { HealthMatrixContext } from './context';

export function LineDetails() {
	const { selectedLine, selectedLineSector, selectedLineCompany, startDate, endDate } =
		useContext(HealthMatrixContext);

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	const workstationsParams = {
		organizationId,
		lineId: selectedLine?.id,
		startDate,
		endDate
	};

	const workstations = useQuery(['workstations', workstationsParams], () =>
		getWorkstationsByLine(workstationsParams)
	);

	async function getWorkstationsByLine({ organizationId, lineId, startDate, endDate }) {
		if (!organizationId || !lineId || !startDate || !endDate) return;
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('line_id', lineId);
		params.append('group_by', 'workstation');
		params.append('start_date', startDate);
		params.append('end_date', endDate);

		let url = `/appointment/work-metrics?`;
		const { data } = await Api.get(url + params);
		return data;
	}

	const globalGrade = {
		1: {
			text: I18n.get('High risk'),
			tagColor: 'red'
		},
		2: {
			text: I18n.get('Moderate risk'),
			tagColor: 'orange'
		},
		3: {
			text: I18n.get('Without risk'),
			tagColor: 'green'
		}
	};

	const columns = [
		{
			title: I18n.get('Workstation'),
			dataIndex: 'name',
			key: 'name',
			align: 'left',
			width: '140px',
			render: (_, workstation) => {
				let path = '/files?';

				const attributes = {
					organization_id: organizationId,
					company_id: selectedLineCompany.id,
					sector_id: selectedLineSector.id,
					workstation_id: workstation.id
				};

				const query = new URLSearchParams(attributes);
				const url = `${path}${query.toString()}`;

				return <Link to={url}>{workstation.name}</Link>;
			}
		},
		{
			title: I18n.get('Ergonomic grade'),
			dataIndex: 'global_grade',
			key: 'global_grade',
			align: 'center',
			width: '120px',
			sorter: (a, b) => b.global_grade - a.global_grade,
			render: (grade) => {
				const item = globalGrade[grade] || {
					text: I18n.get('Not assigned'),
					tagColor: 'default'
				};

				return <Tag color={item.tagColor}>{item.text}</Tag>;
			}
		},
		{
			title: I18n.get('Complaints with nexus'),
			dataIndex: 'complaint_with_nexus',
			key: 'complaint_with_nexus',
			align: 'center',
			width: '150px',
			sorter: (a, b) => b.complaint_with_nexus - a.complaint_with_nexus
		},
		{
			title: I18n.get('Complaints without nexus'),
			dataIndex: 'complaint_without_nexus',
			key: 'complaint_without_nexus',
			align: 'center',
			width: '150px',
			sorter: (a, b) => b.complaint_without_nexus - a.complaint_without_nexus
		},
		{
			title: I18n.get('Medical certificates'),
			dataIndex: 'medical_certificate',
			key: 'medical_certificate',
			align: 'center',
			width: '100px',
			sorter: (a, b) => b.medical_certificate - a.medical_certificate
		},
		{
			title: I18n.get('Workers'),
			dataIndex: 'worker',
			key: 'worker',
			align: 'center',
			width: '90px',
			sorter: (a, b) => b.worker - a.worker
		},
		{
			title: I18n.get('Women'),
			dataIndex: 'women',
			key: 'women',
			align: 'center',
			width: '90px',
			sorter: (a, b) => b.women - a.women
		},
		{
			title: I18n.get('PWD'),
			dataIndex: 'pcd',
			key: 'pcd',
			align: 'center',
			width: '90px',
			sorter: (a, b) => b.pcd - a.pcd
		},
		{
			title: I18n.get('Restrict'),
			dataIndex: 'restrict_worker',
			key: 'restrict_worker',
			align: 'center',
			width: '90px',
			sorter: (a, b) => b.restrict_worker - a.restrict_worker
		}
	];

	return (
		<Col span={24}>
			<LineInfo />
			<Table
				rowKey="id"
				size="middle"
				columns={columns}
				pagination={false}
				scroll={{ y: 240 }}
				dataSource={workstations.data}
				loading={workstations.isLoading}
			/>
		</Col>
	);
}
