import React from 'react';
import { useSelector } from 'react-redux';
import ActionPlanIcon from '@/assets/icons/ActionPlan';
import { CustomButton } from './styles';
import type { ChecklistState, HierarchyItem } from './Modal/types';
interface ActionPlanButtonProps {
	onClick: () => void;
	disabled?: boolean;
}

type SelectorState = {
	checklist: ChecklistState;
	organization: {
		organization: HierarchyItem;
		company: HierarchyItem;
	};
};

const ActionPlanButton = ({ onClick, disabled }: ActionPlanButtonProps) => {
	const consolidated = useSelector<SelectorState, boolean>((state) => state?.checklist?.checklist?.consolidated);

	return (
		<CustomButton disabled={disabled || consolidated} type="primary" onClick={onClick}>
			<ActionPlanIcon />
		</CustomButton>
	);
};

export default ActionPlanButton;
