import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

interface WorkerRestrictionDTO {
	id: string;
	organization_id?: string;
	company_id?: string;
}

interface WorkerRestrictionResponseDTO {
	id: string;
	type: string;
	end_date?: string;
	start_date: string;
	description: string;
}

async function getWorkerRestriction({ id, ...params }: WorkerRestrictionDTO): Promise<WorkerRestrictionResponseDTO> {
	const { data } = await Api.get(`/worker-restriction/restriction/${id}`, { params });
	return data;
}

export const useGetWorkerRestriction = (params: WorkerRestrictionDTO) => {
	return useQuery([QUERY_KEYS.GET_WORKER_RESTRICTION, params], () => getWorkerRestriction(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
