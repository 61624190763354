import React from 'react';
import type { ButtonProps } from 'antd';
import * as S from './styles';

type Props = ButtonProps & {
	onClick?: () => void;
	children?: string;
};

export const Button: React.FC<Props> = ({ children = '', onClick, ...props }) => {
	return (
		<S.CustomButton onClick={onClick} {...props}>
			{children}
		</S.CustomButton>
	);
};
