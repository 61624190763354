import React, { ReactNode } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd';

import { I18n } from '@aws-amplify/core';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';

import { useManageOrganization } from '../../../context';

import { DeleteRequest, ProductDTO } from '../../../types';
import { Create, Delete, Edit, SelectId } from '../styles';

const { useFormInstance } = Form;

type OptionType = {
	label: string;
	value: string;
};

type SelectProductProps = {
	products: ProductDTO[];
	productName: string;
	onProductNameChange: (name: string) => void;
	onEditing: (isEditing: boolean) => void;
};

export const SelectProduct: React.FC<SelectProductProps> = ({
	products,
	productName,
	onProductNameChange,
	onEditing,
}) => {
	const { resetFields, setFieldValue } = useFormInstance();

	const {
		organizationId,
		companyId,
		workstationId,
		productId,
		updatingOrganizationData,
		creatingOrganizationData,
		isEditing,
		handleProduct,
		handleProductId,
		deleteOrganizationData,
		createOrganizationData,
		handleIsEditing,
	} = useManageOrganization();

	async function handleCreateProduct() {
		const created = await createOrganizationData({
			organization_id: organizationId,
			company_id: companyId,
			workstation_id: workstationId,
			product_name: productName,
		});

		onProductNameChange('');
		handleProduct(created.id, setFieldValue);
	}

	function handleEditProduct(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const editingProduct = products.find((product) => product.id === productId);
		if (editingProduct) {
			setFieldValue('edit_product_name', editingProduct.name);
			onProductNameChange(editingProduct.name);
			handleIsEditing(true);
			onEditing(true);
		}
	}

	async function handleDeleteProduct(event: React.MouseEvent<HTMLElement, MouseEvent>) {
		event.stopPropagation();
		event.preventDefault();

		const body: DeleteRequest = {
			organization_id: organizationId,
			company_id: companyId,
			product_id: productId,
		};

		const content = I18n.get(
			`All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?`
		);

		Modal.confirm({
			title: I18n.get('Warning!'),
			icon: <ExclamationCircleOutlined />,
			content: content,
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			onOk: async () => {
				deleteOrganizationData(body).then(() => {
					onProductNameChange('');
					handleProductId('');
					resetFields(['product_id']);
				});
			},
		});
	}

	function filterProduct(option: OptionType | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function handleSelectProduct(id: string) {
		handleProduct(id, setFieldValue);
	}

	function manageProductDropdown(menu: ReactNode) {
		return (
			<Col span={24}>
				{menu}
				<Divider style={{ margin: '4px 0' }} />
				<Create>
					<Form.Item name="create_product_name" style={{ margin: '0' }}>
						<Input
							style={{
								flex: 'auto',
								borderRadius: '7px',
							}}
							value={productName}
							onChange={(e) => onProductNameChange(e.target.value)}
						/>
					</Form.Item>
					<Button
						onClick={handleCreateProduct}
						type="link"
						loading={creatingOrganizationData}
						disabled={!productName || productName.length < 3}
					>
						{I18n.get('Create product')}
					</Button>
				</Create>
			</Col>
		);
	}

	const productsList = products.map(({ id, name }) => ({
		label: name,
		value: id,
	}));

	const actions = (
		<Col span={4}>
			<Row align="bottom" justify="space-between">
				<Edit span={12}>
					<Button
						disabled={!productId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleEditProduct(e)}
						icon={<EditOutlined />}
					/>
				</Edit>
				<Delete span={12}>
					<Button
						disabled={!productId || isEditing}
						size="middle"
						type="text"
						onClick={(e) => handleDeleteProduct(e)}
						icon={<DeleteOutlined />}
					/>
				</Delete>
			</Row>
		</Col>
	);

	return (
		<Col span={24}>
			<Row align="bottom" justify="space-between" gutter={[8, 0]}>
				<Col span={20}>
					<SelectId
						selected={!!productId || isEditing}
						name="product_id"
						label={I18n.get('Select a product')}
						labelCol={{ span: 24 }}
						style={{ margin: '0' }}
					>
						<Select
							showSearch
							allowClear
							onClear={() => handleProductId('')}
							options={productsList}
							loading={updatingOrganizationData}
							filterOption={(input, option) => filterProduct(option, input)}
							onChange={(value: string) => handleSelectProduct(value)}
							placeholder={I18n.get('Select a product')}
							dropdownRender={(menu: ReactNode) =>
								manageProductDropdown(menu)
							}
						/>
					</SelectId>
				</Col>
				{actions}
			</Row>
		</Col>
	);
};
