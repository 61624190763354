import React from 'react';
import { Row, Col } from 'antd';

import { Header } from './Header';
import { Filter } from './Filter';
import { List } from './List';
import { AETProvider } from './context';
import { ListFilesModal } from './ListFilesModal';

export function ErgonomicWorkAnalysis() {
	return (
		<AETProvider>
			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Header />
				</Col>
				<Col span={24}>
					<Filter />
				</Col>
				<Col span={24}>
					<List />
				</Col>
				<Col span={24}>
					<ListFilesModal />
				</Col>
			</Row>
		</AETProvider>
	);
}
