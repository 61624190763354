import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import Api from '@/services/api';
import { Header } from './Header';
import { Filter } from './Filter';
import { Reports } from './Reports';
import { TableFiles } from './Table';
import { FilesContext } from './context';
import { useQueryURL } from '@/hooks/v1';

export function Files() {
	const query = useQueryURL();
	const queryClient = useQueryClient();

	const { organizationId, companyId } = useSelector((state) => ({
		organizationId: state.organization.organization?.id,
		companyId: state.organization.company?.id
	}));

	const initalState = {
		organizationId,
		companyId,
		page: 0,
		limit: 10
	};

	// Modal
	const [visible, setVisible] = useState(false);
	const [file, setFile] = useState('');
	const [type, setType] = useState('');

	const [pageSize, setPageSize] = useState(10);
	const [filter, setFilter] = useState(initalState);

	function updateFilter(payload) {
		setFilter((prev) => ({ ...prev, ...payload }));
	}

	function getFilesParams() {
		let attributes = {};
		let defaultParams = {};

		if (query.get('organization_id')) {
			defaultParams['organizationId'] = query.get('organization_id');
		}

		if (query.get('organization_id') && query.get('company_id')) {
			defaultParams['companyId'] = query.get('company_id');
		}

		if (query.get('organization_id') && query.get('company_id') && query.get('sector_id')) {
			attributes['sector_id'] = query.get('sector_id');
		}

		if (
			query.get('organization_id') &&
			query.get('company_id') &&
			query.get('sector_id') &&
			query.get('workstation_id')
		) {
			attributes['workstation_id'] = query.get('workstation_id');
		}

		if (
			query.get('organization_id') &&
			query.get('company_id') &&
			query.get('sector_id') &&
			query.get('workstation_id') &&
			query.get('product_id')
		) {
			attributes['product_id'] = query.get('product_id');
		}

		if (query.get('filter_type') && query.get('filter')) {
			attributes[query.get('filter_type')] = query.get('filter');
		}

		const params = new URLSearchParams(attributes);

		updateFilter({
			...defaultParams,
			query: params.toString()
		});
	}

	useEffect(() => {
		updateFilter({ organizationId, companyId });
	}, [organizationId, companyId]);

	useEffect(() => {
		getFilesParams();
		queryClient.invalidateQueries(['files']);
	}, []);

	function setPage(value) {
		setFilter((prev) => ({ ...prev, page: value }));
	}

	function setSize(value) {
		setPageSize(value);
		setFilter((prev) => ({ ...prev, limit: value }));
	}

	function resetFilter() {
		setFilter(initalState);
	}

	function onDownload({ id, original_name, organization_id, company_id }) {
		return Api.post('/upload/create-url-download', {
			organization_id,
			original_name,
			company_id,
			file_id: id
		});
	}

	function onDelete({ id, organization_id, company_id }) {
		return Api.delete(`/upload/${id}/${organization_id}/${company_id}`);
	}

	function onShow(file) {
		setVisible(true);
		setFile(file);
	}

	function onClose() {
		setVisible(false);
		setFile('');
		setType('');
	}

	const context = {
		updateFilter,
		resetFilter,
		pageSize,
		filter,
		setPage,
		setSize,
		onDownload,
		onDelete,
		visible,
		onShow,
		onClose,
		file,
		setFile,
		type,
		setType
	};

	return (
		<FilesContext.Provider value={context}>
			<Row gutter={[0, 8]}>
				<Header />
				<Reports />
				<Filter />
				<TableFiles />
			</Row>
		</FilesContext.Provider>
	);
}
