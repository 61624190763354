import React from 'react';
import { I18n } from '@aws-amplify/core';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import { List, Popconfirm, Checkbox, Row, Col, Button } from 'antd';
import { MdDeleteForever, MdCheckCircle, MdError, MdVideocam } from 'react-icons/md';

import { Container } from './styles';
import history from '@/store/history';

export function UploadList({ files, onDelete, onUpdate }) {
	const HeaderList = () => (
		<Row type="flex" align="middle" justify="center">
			<Col sm={12}>
				<span style={{ marginLeft: '48px' }}>{I18n.get('Description')}</span>
			</Col>
			<Col sm={6} style={{ textAlign: 'center' }}>
				{/* <span>Ferramenta</span> */}
			</Col>
			<Col sm={2} style={{ textAlign: 'center' }}>
				<span>{I18n.get('Blur')}</span>
			</Col>
			<Col sm={2} style={{ textAlign: 'center' }}>
				<span>{I18n.get('Actions')}</span>
			</Col>
		</Row>
	);

	return (
		<Container>
			<div style={{ height: '40px', width: '100%' }}>
				<Button
					type="link"
					onClick={() => history.push('/reporting')}
					style={{ display: 'block', float: 'right' }}
				>
					{I18n.get('Reports')}
				</Button>
			</div>
			{!!files?.length > 0 && (
				<List
					header={<HeaderList />}
					dataSource={files}
					renderItem={(item) => (
						<List.Item key={item.id}>
							<Row type="flex" align="middle" justify="center" style={{ width: '100%' }}>
								<Col
									sm={12}
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap'
									}}
								>
									<MdVideocam size={30} color="#7159c1" style={{ textAlign: 'center' }} />
									<strong style={{ paddingLeft: '16px' }}>{item.name}</strong>
								</Col>
								<Col sm={6} style={{ textAlign: 'center' }}>
									{/* <Form form={form} initialValues={{ tool: 'rula' }}>
										<Form.Item name="tool">
											<Select
												size="small"
												disabled={true}
												// disabled={item.blocking}
												style={{ width: '80%' }}
												onSelect={(type) => {
													form.setFieldsValue({ tool: type });
													onUpdate(item.id, { tool_type: type });
												}}
											>
												<Option value="rula">
													RULA
												</Option>
											</Select>
										</Form.Item>
									</Form> */}
								</Col>
								<Col sm={2} style={{ textAlign: 'center' }}>
									<Checkbox
										checked={item.blur}
										disabled={item.blocking}
										onChange={({ target }) =>
											onUpdate(item.id, {
												blur: target.checked
											})
										}
									/>
								</Col>
								<Col sm={2} style={{ textAlign: 'center' }}>
									{!item.uploaded && !item.error && (
										<CircularProgressbar
											styles={{
												root: { width: 24 },
												path: { stroke: '#7159c1' }
											}}
											strokeWidth={10}
											value={item.progress}
										/>
									)}
									{!item.error && item.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
									{item.error && <MdError size={24} color="#e57878" />}
									{!item.progress && !item.error && (
										<Popconfirm
											placement="topRight"
											okText={I18n.get('Confirm')}
											title={I18n.get('Are you sure?')}
											cancelText={I18n.get('Cancel')}
											onConfirm={() => onDelete(item.id)}
											icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
										>
											<MdDeleteForever size={24} color="#e57878" style={{ marginRight: 8 }} />
										</Popconfirm>
									)}
								</Col>
							</Row>
						</List.Item>
					)}
				/>
			)}
		</Container>
	);
}
