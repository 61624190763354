import React, { useContext } from 'react';
import { Typography } from 'antd';
import { I18n } from '@aws-amplify/core';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { ManagementContext } from './context';
import { StyledModalContent, StyledFooter, StyledOkButton, StyledCancelButton, ModalStyled } from './styles';

const { Text } = Typography;

export function Activate() {
	const { worker, workerActivateModal, closeWorkerActivateModal, handleActivateWorker } =
		useContext(ManagementContext);

	async function handleOk(): Promise<void> {
		if (worker) {
			handleActivateWorker(worker);
		}
	}

	const handleCancel = () => {
		closeWorkerActivateModal();
	};

	return (
		<div>
			<ModalStyled
				open={workerActivateModal}
				centered
				width={450}
				footer={
					<StyledFooter>
						<StyledCancelButton onClick={handleCancel}>{I18n.get('Cancel')}</StyledCancelButton>
						<StyledOkButton type="primary" onClick={handleOk}>
							{I18n.get('Yes')}
						</StyledOkButton>
					</StyledFooter>
				}
				onCancel={handleCancel}
			>
				<StyledModalContent>
					<ExclamationCircleFilled className="modal-icon" />
					<p className="modal-title">
						{I18n.get('Do you really want to activate the employee')} <Text strong>"{worker?.name}"</Text>
						?
					</p>
				</StyledModalContent>
			</ModalStyled>
		</div>
	);
}
