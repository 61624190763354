import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Modal, Select, Input, DatePicker, Button, message, Skeleton } from 'antd';

import { useQueryClient } from '@tanstack/react-query';
import { ManagementContext } from '../context';
import { useApplicationContext } from '@/context/application';
import { useGetWorkerRestriction } from '@/hooks/useGetWorkerRestriction';
import { useUpdateWorkerRestriction, UpdateWorkerRestrictionDTO } from '@/hooks/useUpdateWorkerRestriction';
import { QUERY_KEYS } from '@/utils/query-keys';

const { TextArea } = Input;

const FORMAT_DATE = 'DD/MM/YYYY';

export function UpdateRestriction() {
	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	const { organization, company } = useApplicationContext();
	const [isUndeterminated, setIsUndeterminated] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<Moment | null>(null);

	const { worker, workerRestrictionId, closeUpdateRestrictionModal, updateRestrictionModal, setTriggerReload } =
		useContext(ManagementContext);

	const { isLoading: isLoadingWorkerRestriction, data } = useGetWorkerRestriction({
		id: workerRestrictionId!,
		organization_id: organization!.id,
		company_id: company!.id
	});

	useEffect(() => {
		if (data) {
			setIsUndeterminated(data.type === 'UNDETERMINED');
		}
	}, [data, updateRestrictionModal]);

	useEffect(() => {
		if (data) {
			setStartDate(data.start_date ? moment(data.start_date) : null);
			form.setFieldsValue({
				type: data.type,
				start_date: data.start_date ? moment(data.start_date) : null,
				end_date: data.end_date ? moment(data.end_date) : null,
				description: data.description
			});
		}
	}, [data, form]);

	const { mutateAsync: updateWorkerRestriction, isLoading } = useUpdateWorkerRestriction();

	async function onUpdate(values: UpdateWorkerRestrictionDTO): Promise<void> {
		const { type, description, start_date, end_date } = values;
		const body = {
			id: workerRestrictionId!,
			type,
			description,
			start_date,
			worker_id: worker!.id,
			company_id: company!.id,
			organization_id: organization!.id,
			...(end_date ? { end_date } : {})
		};

		await updateWorkerRestriction(body);
		handleCancel();
	}

	async function handleSubmit(): Promise<void> {
		try {
			const values = await form.validateFields();
			await onUpdate(values);
		} catch (errInfo: any) {
			message.error(errInfo?.response?.data?.message || 'Oops... Something went wrong');
		}
	}

	function handleCancel(): void {
		form.resetFields();
		setIsUndeterminated(true);
		closeUpdateRestrictionModal();
		setTriggerReload();
		queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WORKER_RESTRICTION] });
	}

	return (
		<Modal
			centered
			width={550}
			open={updateRestrictionModal}
			onCancel={handleCancel}
			title={I18n.get('Edit restriction')}
			destroyOnClose={true}
			footer={null}
		>
			{isLoadingWorkerRestriction ? (
				<Skeleton />
			) : (
				<Form
					key={workerRestrictionId}
					form={form}
					onFinish={handleSubmit}
					layout="vertical"
					name="create_worker"
					initialValues={{
						...data,
						start_date: data?.start_date ? moment(data.start_date) : null,
						end_date: data?.end_date ? moment(data.end_date) : null
					}}
				>
					<Row>
						<Col sm={24}>
							<Form.Item
								name="type"
								label={I18n.get('Type of restriction')}
								rules={[
									{
										required: true,
										message: I18n.get('Select an option')
									}
								]}
							>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder={I18n.get('Select the type of restriction')}
									optionFilterProp="children"
									defaultValue={data?.type}
									onChange={(e: string) => {
										setIsUndeterminated(e === 'UNDETERMINED');
									}}
								>
									<Select.Option value="TEMPORARY">{I18n.get('Temporary')}</Select.Option>
									<Select.Option value="UNDETERMINED">{I18n.get('Undetermined')}</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row style={{ justifyContent: 'space-between' }}>
						<Col sm={11}>
							<Form.Item
								name="start_date"
								label={I18n.get('Restriction start date')}
								rules={[
									{
										required: true,
										message: I18n.get('Select an start date')
									}
								]}
							>
								<DatePicker
									onChange={(e) => {
										setStartDate(e);
										form.setFieldsValue({ end_date: null });
									}}
									format={FORMAT_DATE}
									placeholder={I18n.get('Date')}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>

						{!isUndeterminated && (
							<Col sm={11}>
								<Form.Item
									name="end_date"
									label={I18n.get('Restriction end date')}
									rules={[
										{
											required: true,
											message: I18n.get('Please enter a valid date')
										}
									]}
								>
									<DatePicker
										format={FORMAT_DATE}
										placeholder={I18n.get('Date')}
										style={{ width: '100%' }}
										disabledDate={(current: any) =>
											current && startDate && current.valueOf() <= startDate!.valueOf()
										}
									/>
								</Form.Item>
							</Col>
						)}
					</Row>
					<Row>
						<Col sm={24}>
							<Form.Item
								name="description"
								label={I18n.get('Description of the restriction')}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid description')
									}
								]}
							>
								<TextArea
									rows={4}
									showCount
									allowClear
									maxLength={250}
									placeholder={I18n.get('Description of the restriction')}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row justify={'end'} style={{ paddingTop: '20px' }}>
						<Col sm={24}>
							<Button
								loading={isLoading}
								type="primary"
								htmlType="submit"
								style={{ float: 'right', marginLeft: '2%' }}
							>
								{I18n.get('Save')}
							</Button>
							<Button onClick={handleCancel} style={{ float: 'right' }}>
								{I18n.get('Cancel')}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Modal>
	);
}
