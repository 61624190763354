import React, { Fragment, useContext } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Col, Table, Divider, Button, Tooltip, Modal, Tag } from 'antd';

import Can from '@/components/Can';
import { useFile } from '@/hooks/v1';
import { FilesContext } from './context';
import { formatFileSize } from '@/utils/mask';
import { StatusFileDescriptionEnum } from '@/utils/enum';
import { useDeleteFile, useDownloadFile } from './hooks';

export function TableFiles() {
	const { pageSize, filter, setPage, setSize, onDownload, onDelete, onShow } = useContext(FilesContext);

	const { isLoading, data } = useFile(filter);
	const deleteFile = useDeleteFile(onDelete);
	const download = useDownloadFile(onDownload);

	function onDeleteFile(id) {
		Modal.confirm({
			title: I18n.get('Heads up! want to delete this file?'),
			icon: <Icons.ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			confirmLoading: true,
			okType: 'danger',
			onOk: () => deleteFile.mutateAsync({ id })
		});
	}

	function handleRenderStatusFile(statusValue) {
		const statusFile = StatusFileDescriptionEnum[statusValue] || '-';
		return I18n.get(statusFile);
	}

	const columns = [
		{
			key: 'original_name',
			title: I18n.get('Description'),
			dataIndex: 'original_name',
			width: '20%'
		},
		{
			key: 'size',
			title: I18n.get('Size'),
			dataIndex: 'size',
			render: (text) => formatFileSize(text)
		},
		{
			key: 'created_at',
			title: I18n.get('Upload date'),
			dataIndex: 'created_at',
			align: 'center',
			width: '10%',
			render: (text) => moment(text).format('DD/MM/YYYY - h:mm:ss A')
		},
		{
			key: 'status',
			title: I18n.get('Status'),
			dataIndex: 'status',
			align: 'center',
			render: (value) => handleRenderStatusFile(value)
		},
		{
			key: 'sector',
			title: I18n.get('Sector'),
			dataIndex: 'sector',
			align: 'center',
			render: (text) => (text ? text : I18n.get('Not assigned'))
		},
		{
			key: 'global_grade',
			title: I18n.get('Global grade'),
			dataIndex: 'global_grade',
			align: 'center',
			render: (_, { global_grade }) => {
				const colors = {
					1: 'error',
					2: 'warning',
					3: 'success'
				};

				const description = {
					1: 'High risk',
					2: 'Moderate risk',
					3: 'Without risk'
				};

				if (!global_grade) {
					return I18n.get('Not assigned');
				}

				return <Tag color={colors[global_grade]}>{I18n.get(description[global_grade])}</Tag>;
			}
		},
		{
			key: 'workstation',
			title: I18n.get('Workstation'),
			dataIndex: 'workstation',
			align: 'center',
			render: (text) => (text ? text : I18n.get('Not assigned'))
		},
		{
			key: 'url',
			title: I18n.get('Actions'),
			dataIndex: 'url',
			align: 'center',
			render: (_, item) => (
				<>
					<Tooltip title={I18n.get('Download')}>
						<Button
							ghost
							type="primary"
							icon={<Icons.DownloadOutlined />}
							disabled={!(item.status === 'PROCESSED')}
							onClick={() =>
								download.mutateAsync({
									id: item.id,
									organization_id: item.organization_id,
									company_id: item.company_id,
									original_name: item.original_name
								})
							}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title={I18n.get('Reports')}>
						<Button
							ghost
							type="primary"
							icon={<Icons.LineChartOutlined />}
							onClick={() => onShow(item)}
							disabled={!['EXTRACTED_DATA', 'PROCESSED'].includes(item.status)}
						/>
					</Tooltip>
					{Can('delete', 'upload') && (
						<Fragment>
							<Divider type="vertical" />
							<Tooltip title={I18n.get('Delete')}>
								<Button
									ghost
									type="danger"
									icon={<Icons.DeleteOutlined />}
									onClick={() =>
										onDeleteFile({
											id: item.id,
											organization_id: item.organization_id,
											company_id: item.company_id
										})
									}
								/>
							</Tooltip>
						</Fragment>
					)}
				</>
			)
		}
	];

	return (
		<Col span={24}>
			<Table
				rowKey="id"
				columns={columns}
				loading={isLoading}
				dataSource={data?.result}
				pagination={{
					pageSize,
					total: data?.total,
					hideOnSinglePage: true,
					onChange: (page) => setPage(page - 1),
					onShowSizeChange: (_, size) => setSize(size)
				}}
			/>
		</Col>
	);
}
