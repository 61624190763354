import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Card, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { PieChartOutlined, LineChartOutlined, CheckSquareOutlined } from '@ant-design/icons';

import { FrequencyDuration } from '@/components/Report/SelectReports/FrequencyDuration';
import { ScoreParts } from '@/components/Report/SelectReports/ScoreParts';
import { FilesContext } from '../context';
import { Container } from './styles';

const CHECKLIST_EHS = 'checklist-ehs';

export function Reports() {
	const history = useHistory();
	const { file, type, visible, onClose, setType } = useContext(FilesContext);

	const styleIcons = { fontSize: '35px', opacity: 0.7 };

	if (type) {
		const reports = {
			score_parts: <ScoreParts item={file} visible={visible} onClose={onClose} />,
			frequency_duration: <FrequencyDuration item={file} visible={visible} onClose={onClose} />
		};

		return reports[type];
	}

	const options = [
		{
			id: 1,
			type: 'score_parts',
			title: I18n.get('Movement by score'),
			icon: <PieChartOutlined style={styleIcons} />
		},
		{
			id: 2,
			type: 'frequency_duration',
			title: I18n.get('Frequency and duration'),
			icon: <LineChartOutlined style={styleIcons} />
		},
		{
			id: 3,
			type: 'checklist-ehs',
			title: I18n.get('Checklist EHS'),
			icon: <CheckSquareOutlined style={styleIcons} />
		}
	];

	function handleReportPathAssembly({ organization_id, company_id, file_id, type }) {
		const url = `/ehs/reporting/${type}/${file_id}/${organization_id}/${company_id}`;
		return url;
	}

	function handleOnSelectReport(type) {
		const { organization_id, company_id, id: file_id } = file;

		const path = handleReportPathAssembly({
			organization_id,
			company_id,
			file_id,
			type
		});

		if (type === CHECKLIST_EHS) {
			history.push(path);
		}

		setType(type);
	}

	return (
		<Modal width={750} footer={false} open={visible} onCancel={onClose} bodyStyle={{ height: 'auto' }}>
			<Row justify="center">
				<Col sm={24}>
					<h2>{I18n.get('Reports')}</h2>
				</Col>
				<Col sm={24}>
					<p>{I18n.get('Select the data visualization model')}</p>
				</Col>
				<Container sm={24} style={{ padding: '10px 0px 10px 0px' }}>
					<Row gutter={[8, 8]} type="flex" justify="start" style={{ width: '100%' }}>
						{options.map(({ id, type, icon, title }) => (
							<Col sm={8} key={id} onClick={() => handleOnSelectReport(type)}>
								<Card hoverable style={{ textAlign: 'center', height: '90%' }}>
									{icon}
									<p style={{ paddingTop: '15px' }}>{title}</p>
								</Card>
							</Col>
						))}
					</Row>
				</Container>
			</Row>
		</Modal>
	);
}
