import React from 'react';
import { Row, Typography } from 'antd';
import { Description } from './styles';

const { Paragraph } = Typography;

interface TaskProps {
	description: string;
}

export function Task({ description }: TaskProps) {
	return (
		<Row align="middle" justify="space-between">
			<Description lg={18} xl={20}>
				<Paragraph>{description}</Paragraph>
			</Description>
		</Row>
	);
}
