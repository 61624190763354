import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export function CustomTableSkeleton() {
	const renderSkeletonRow = (key: number) => (
		<Row style={{ marginBottom: '20px', marginTop: '20px' }} key={key}>
			<Col lg={24} key={key}>
				<Skeleton.Button block style={{ width: '100%' }} active />
			</Col>
		</Row>
	);

	return (
		<>
			<Row>
				<Col lg={6}>
					<Skeleton.Button block style={{ width: '50%' }} active />
				</Col>
				<Col lg={6}>
					<Skeleton.Button block style={{ width: '50%' }} active />
				</Col>
				<Col lg={6}>
					<Skeleton.Button block style={{ width: '50%' }} active />
				</Col>
				<Col lg={6}>
					<Skeleton.Button block style={{ width: '50%' }} active />
				</Col>
			</Row>
			{Array.from({ length: 4 }).map((_, index) => renderSkeletonRow(index))}
			<Row style={{ display: 'flex', justifyContent: 'end' }}>
				<Col lg={4}>
					<Skeleton.Button block style={{ width: '100%' }} active />
				</Col>
			</Row>
		</>
	);
}
