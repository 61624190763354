import React, { useEffect } from 'react';
import { Form, Select, message } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetSectors } from '@/hooks/useGetSectors';
import { useAETCreateContext } from '../../context';

const { useFormInstance } = Form;

export function Sector() {
	const form = useFormInstance();

	const { organization_id, company_id, setSectorId, queryParams, setQueryParams, setWorkstation } =
		useAETCreateContext();

	const { isInitialLoading, data, isError, refetch } = useGetSectors({ organization_id, company_id });

	useEffect(() => {
		if (isError) {
			message.error(I18n.get('Failed to fetch sectors'));
		}
	}, [isError]);

	useEffect(() => {
		if (company_id.length) {
			refetch();
		}
	}, [organization_id, company_id, refetch]);

	function handleOnClearForm(): void {
		form.resetFields(['workstation']);
	}

	function handleOnClearHierarchy(): void {
		setWorkstation([]);
	}

	function handleOnChange(value: string): void {
		setSectorId(value);
		handleOnClearHierarchy();
		handleOnClearForm();
		setQueryParams({ ...queryParams, sector_id: value });
	}

	return (
		<Form.Item name="sector_id">
			<Select
				showSearch
				onChange={handleOnChange}
				loading={isInitialLoading}
				placeholder={I18n.get('Sector')}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
