import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/company';
import type { GetCompaniesParams } from '@/infra/services/dto/company.dto';

export const useGetCompanies = (params: GetCompaniesParams) => {
	return useQuery([QUERY_KEYS.GET_COMPANIES, params], () => Service.index(params), {
		enabled: !!params.organization_id
	});
};
