import moment from 'moment';

export function formatFileSize(size) {
	if (size < 1024) return size + ' B';
	let i = Math.floor(Math.log(size) / Math.log(1024));
	let num = size / Math.pow(1024, i);
	let round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${num} ${'KMGTPEZY'[i - 1]}B`;
}

export const convertSecondsToMinutes = (seconds) => {
	if (!seconds) return undefined;
	return `${moment.unix(seconds).utc().format('mm:ss')}`;
};

export function documentMaskBRL(value) {
	value = value.replace(/\D/g, '');

	if (value.length <= 11) {
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		value = value.replace(/^(\d{2})(\d)/, '$1.$2');
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
		value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
		value = value.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return value;
}

export function formatSecondsDuration(seconds) {
	const format = (val) => `0${Math.floor(val)}`.slice(-2);
	const hours = seconds / 3600;
	const minutes = (seconds % 3600) / 60;

	return [hours, minutes, seconds % 60].map(format).join(':');
}

export function textParseGender(gender) {
	const word = gender.toLowerCase();
	const firstLatter = word[0].toUpperCase();
	const restWord = word.substring(1);
	return `${firstLatter + restWord}`;
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
