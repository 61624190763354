import React, { useState, useEffect } from 'react';
import { Row as Container, message } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { List } from './List';
import { Filter } from './Filter';
import { Create } from './Create';
import { Update } from './Update';
import { Header } from './Header';
import { Delete } from './Delete';
import { Activate } from './Activate';
import { History } from './History/index';
import { Restriction } from './Restrictions/index';
import { Complaint } from './ComplaintOrCertificate/Complaint';
import { ComplaintOrCertificate } from './ComplaintOrCertificate';
import { MedicalCertificate } from './ComplaintOrCertificate/MedicalCertificate';

import { RootState, Worker } from './types';
import { ManagementContext, ManagementState } from './context';

import Api from '@/services/api';
import Spinner from '@/components/SpinnerDeprecated';
import { useIsMounted } from '@/hooks/useIsMounted';

const defaultState: ManagementState = {
	companies: [],
	selectCompanyId: null,
	sectors: [],
	selectSectorId: null,
	lines: [],
	selectLineId: null,
	workstations: [],
	selectWorkstationId: null,
	workers: [],
	isFetchWorkers: true,
	workerCreateModal: false,
	isLoadingWorkerCreate: false,
	workerUpdateModal: false,
	triggerReload: false,
	errors: null,
	complaintOrCertificateModal: false,
	complaintModal: false,
	medicalCertificateModal: false,
	workstationsByWorker: null,
	cidm: [],
	worker: null,
	workerDeleteModal: false,
	restrictionModal: false,
	newRestrictionModal: false,
	updateRestrictionModal: false,
	workerRestrictionId: null,
	employeeHistoryModal: false,
	workerActivateModal: false
};

export function Management() {
	const isMounted = useIsMounted();
	const [state, setState] = useState<ManagementState>(defaultState);
	const { type, companyId, sectorId, lineId } = useParams<{
		type?: string;
		companyId?: string;
		sectorId?: string;
		lineId?: string;
	}>();

	const redirected = type ? true : false;

	const organizationId = useSelector((state: RootState) => state.organization.organization?.id);

	function setSelectCompanyId(value: string | null): void {
		setState((prev) => ({
			...prev,
			selectCompanyId: value,
			selectSectorId: null,
			selectLineId: null
		}));
	}

	function setSelectSectorId(value: string | null): void {
		setState((prev) => ({
			...prev,
			selectSectorId: value,
			selectLineId: null
		}));
	}

	function setSelectLineId(value: string | null): void {
		setState((prev) => ({ ...prev, selectLineId: value }));
	}

	function setSelectWorkstationId(value: string | null): void {
		setState((prev) => ({ ...prev, selectWorkstationId: value }));
	}

	async function onFilterWorker(values: Record<string, any> = {}): Promise<void> {
		if (!organizationId) return;

		const {
			company,
			sector,
			line,
			workstation,
			register_id,
			name,
			pcd,
			gender,
			restrict,
			active,
			inactive,
			temporary,
			indefinite
		} = values || {};

		let url = `/worker?`;

		const params = {
			organization_id: organizationId,
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(register_id && { register_id }),
			...(name && { name }),
			...(pcd && { pcd }),
			...(gender && { gender }),
			...(restrict && { restrict }),
			...(active && { active }),
			...(inactive && { inactive }),
			...(temporary && { temporary }),
			...(indefinite && { indefinite })
		};

		const paramsMounting = new URLSearchParams(params);

		url += paramsMounting.toString();

		try {
			const { data: workers } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workers }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isFetchWorkers: false }));
		}
	}

	function showWorkerCreateModal(): void {
		setState((prev) => ({ ...prev, workerCreateModal: true }));
	}

	function closeWorkerCreateModal(): void {
		setState((prev) => ({ ...prev, workerCreateModal: false }));
	}

	function showWorkerEditModal(worker: Worker): void {
		setState((prev) => ({ ...prev, workerUpdateModal: true, worker }));
	}

	function closeWorkerEditModal(): void {
		setState((prev) => ({ ...prev, workerUpdateModal: false }));
	}

	function showComplaintOrCertificateModal(worker: Worker): void {
		setState((prev) => ({
			...prev,
			complaintOrCertificateModal: true,
			worker
		}));
	}

	function showWorkerDeleteModal(worker: Worker): void {
		setState((prev) => ({ ...prev, workerDeleteModal: true, worker }));
	}

	function closeWorkerDeleteModal(): void {
		setState((prev) => ({ ...prev, workerDeleteModal: false }));
	}

	function showWorkerActivateModal(worker: Worker): void {
		setState((prev) => ({ ...prev, workerActivateModal: true, worker }));
	}

	function closeWorkerActivateModal(): void {
		setState((prev) => ({ ...prev, workerActivateModal: false }));
	}

	function closeComplaintOrCertificateModal(): void {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
	}

	function showComplaintModal(worker: Worker, workstationsByWorker: any): void {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
		setState((prev) => ({
			...prev,
			complaintModal: true,
			worker,
			workstationsByWorker
		}));
	}

	function closeComplaintModal(): void {
		setState((prev) => ({ ...prev, complaintModal: false }));
		setSelectWorkstationId('');
	}

	function showMedicalCertificateModal(worker: Worker, workstationsByWorker: any): void {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
		setState((prev) => ({
			...prev,
			medicalCertificateModal: true,
			worker,
			workstationsByWorker
		}));
	}

	function showRestrictionModal(): void {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
		setState((prev) => ({
			...prev,
			restrictionModal: true
		}));
	}

	function closeRestrictionModal(): void {
		setState((prev) => ({ ...prev, restrictionModal: false }));
		setSelectWorkstationId('');
	}

	function closeMedicalCertificateModal(): void {
		setState((prev) => ({ ...prev, medicalCertificateModal: false }));
	}

	function showNewRestrictionModal(): void {
		setState((prev) => ({
			...prev,
			newRestrictionModal: true
		}));
	}

	function closeNewRestrictionModal(): void {
		setState((prev) => ({
			...prev,
			newRestrictionModal: false
		}));
	}

	function setWorkstationsByWorker(workstationsByWorker: any): void {
		setState((prev) => ({ ...prev, workstationsByWorker }));
	}

	function setTriggerReload(): void {
		setState((prev) => ({ ...prev, triggerReload: !prev.triggerReload }));
	}

	function setResetState(): void {
		setState((prev) => ({
			...prev,
			selectWorkstationId: '',
			selectCompanyId: '',
			selectSectorId: '',
			selectLineId: ''
		}));
	}

	function showUpdateRestrictionModal(id: string): void {
		setState((prev) => ({
			...prev,
			updateRestrictionModal: true,
			workerRestrictionId: id
		}));
	}

	function closeUpdateRestrictionModal(): void {
		setState((prev) => ({
			...prev,
			updateRestrictionModal: false
		}));
	}

	function showEmployeeHistoryModal(worker: Worker): void {
		setState((prev) => ({ ...prev, employeeHistoryModal: true, worker }));
	}

	function closeEmployeeHistoryModal(): void {
		setState((prev) => ({ ...prev, employeeHistoryModal: false }));
	}

	function setErrors(errors: any): void {
		setState((prev) => ({ ...prev, errors }));
	}

	async function onCreateWorker(values: any): Promise<void> {
		const { workstations, register_id, restrict, company, gender, sector, line, name, pcd, form } = values;

		setState((prev) => ({ ...prev, isLoadingWorkerCreate: true }));

		try {
			const body = {
				organization_id: organizationId,
				workstations: workstations,
				company_id: company,
				sector_id: sector,
				line_id: line,
				worker: {
					register_id,
					restrict,
					gender,
					name,
					pcd: JSON.parse(pcd)
				}
			};

			const { data } = await Api.post('/worker', body);
			message.success(I18n.get(data.message));
			closeWorkerCreateModal();
			setTriggerReload();
			setResetState();
			form.resetFields();
		} catch (errors: any) {
			setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isLoadingWorkerCreate: false }));
		}
	}

	async function getCompanies(organizationId: string): Promise<void> {
		if (!organizationId) return;
		let url = `/company/user_list/${organizationId}`;
		try {
			const { data: companies } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, companies }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getSectors(organizationId: string, companyId: string): Promise<void> {
		if (!organizationId || !companyId) return;
		let url = `/sector/${organizationId}/${companyId}`;
		try {
			const { data: sectors } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, sectors }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getLines(organizationId: string, companyId: string, sectorId: string): Promise<void> {
		if (!organizationId || !companyId || !sectorId) return;
		let url = `/line/${organizationId}/${companyId}/${sectorId}`;
		try {
			const { data: lines } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, lines }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkstations(
		organizationId: string,
		companyId: string,
		sectorId: string,
		lineId: string
	): Promise<void> {
		if (!organizationId || !companyId || !sectorId || !lineId) return;
		let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
		try {
			const { data: workstations } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workstations }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkers(organizationId: string): Promise<void> {
		if (!organizationId) return;
		let url = `/worker/?organization_id=${organizationId}`;
		try {
			const { data: workers } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workers }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			isMounted() && setState((prev) => ({ ...prev, isFetchWorkers: false }));
		}
	}

	async function getCidM(organizationId: string): Promise<void> {
		if (!organizationId) return;
		let url = `/medical_certificate/cidm/?organization_id=${organizationId}`;
		try {
			const { data: cidm } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, cidm }));
		} catch (errors: any) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function handleDeactivateWorker(worker: Worker): Promise<void> {
		const url = `/worker/${worker?.id}?organization_id=${organizationId}`;
		try {
			const { data } = await Api.delete(url);
			message.success(I18n.get(data.message));
			setResetState();
			setTriggerReload();
			closeWorkerDeleteModal();
		} catch (errors: any) {
			setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isLoadingWorkerCreate: false }));
		}
	}

	async function handleActivateWorker(workerId: any): Promise<void> {
		const url = `/worker/activate_worker/${
			workerId?.id ? workerId?.id : workerId
		}?organization_id=${organizationId}`;
		try {
			const { data } = await Api.put(url);
			message.success(I18n.get(data.message));
			setResetState();
			setTriggerReload();
			closeWorkerActivateModal();
		} catch (errors: any) {
			setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isLoadingWorkerCreate: false }));
		}
	}

	const { selectCompanyId, selectSectorId, selectLineId, isFetchWorkers, triggerReload, errors } = state;

	useEffect(() => {
		getCompanies(organizationId);
		if (redirected) return;
		getWorkers(organizationId);
		getCidM(organizationId);
	}, [triggerReload, organizationId]);

	useEffect(() => {
		if (selectCompanyId) {
			getSectors(organizationId, selectCompanyId);
		}
	}, [selectCompanyId]);

	useEffect(() => {
		if (selectCompanyId && selectSectorId) {
			getLines(organizationId, selectCompanyId, selectSectorId);
		}
	}, [selectCompanyId, selectSectorId]);

	useEffect(() => {
		if (selectCompanyId && selectSectorId && selectLineId) {
			getWorkstations(organizationId, selectCompanyId, selectSectorId, selectLineId);
		}
	}, [selectCompanyId, selectSectorId, selectLineId]);

	useEffect(() => {
		if (!redirected) return;

		const urlParams = {
			company: companyId,
			sector: sectorId,
			line: lineId,
			...(type === 'pcd' && { pcd: true }),
			...(type === 'women' && { gender: 'FEMALE' }),
			...(type === 'restrict' && { restrict: 'BOTH' })
		};
		onFilterWorker(urlParams);
	}, [redirected]);

	useEffect(() => {
		if (errors) {
			const result = errors?.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const context = {
		...state,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		onFilterWorker,
		setTriggerReload,
		onCreateWorker,
		showWorkerCreateModal,
		closeWorkerCreateModal,
		showWorkerEditModal,
		closeWorkerEditModal,
		setErrors,
		getCompanies,
		getSectors,
		getLines,
		getWorkstations,
		showComplaintOrCertificateModal,
		closeComplaintOrCertificateModal,
		showComplaintModal,
		closeComplaintModal,
		showMedicalCertificateModal,
		closeMedicalCertificateModal,
		setWorkstationsByWorker,
		getCidM,
		handleDeactivateWorker,
		handleActivateWorker,
		showWorkerDeleteModal,
		closeWorkerDeleteModal,
		showRestrictionModal,
		closeRestrictionModal,
		showNewRestrictionModal,
		closeNewRestrictionModal,
		showUpdateRestrictionModal,
		closeUpdateRestrictionModal,
		showEmployeeHistoryModal,
		closeEmployeeHistoryModal,
		showWorkerActivateModal,
		closeWorkerActivateModal
	};

	if (isFetchWorkers) {
		return <Spinner />;
	}

	return (
		<ManagementContext.Provider value={context}>
			<Container>
				<Header />
				<Filter />
				<List />
				<ComplaintOrCertificate />
				<Complaint />
				<MedicalCertificate />
				<Create />
				<Update />
				<Delete />
				<Activate />
				<Restriction />
				<History />
			</Container>
		</ManagementContext.Provider>
	);
}
