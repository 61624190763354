import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import _ from 'lodash';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { useCustomer } from '@/hooks';
import { useSelector, useDispatch } from 'react-redux';

import { ChangePassword } from './ChangePassword';
import { UserInformation } from './UserInformation';
// import { UpdateCustomer } from './UserInformation/UpdateCustomer';
import { updateUserSession, updatePlanConsumed } from '@/redux/auth/actions';
import { Description, Value, Title } from './styles';

momentDurationFormat(moment);

export function Account(props) {
	const dispatch = useDispatch();

	const { customer } = useCustomer();
	const { user, plan } = useSelector((state) => state.auth.user);

	const [alertDowngrade, setAlertDowngrade] = useState({
		confirmed: false,
		visible: false,
	});

	const [updateSignature, setUpdateSignature] = useState(null);
	const [controlModalChangePassword, setControlModalChangePassword] =
		useState(false);

	const [updateCustomer, setUpdateCustomer] = useState(false);

	const [consumedPlan, setConsumedPlan] = useState({
		type: '-',
		recurrence: '-',
		created_users: 0,
		processed_videos: 0,
		seconds_processed: 0,
	});

	useEffect(() => {
		if (customer.data && !customer.data.document) {
			warningDocument();
		}
	}, [customer.data]);

	useEffect(() => {
		async function getDifference(info) {
			const { selectedPlan, recurrence } = info;

			try {
				const endpoint = '/plan/calculate-difference';
				const parameters = { plan: selectedPlan, recurrence };

				const { data } = await Api.post(endpoint, parameters);

				Modal.confirm({
					title: 'Atenção!',
					icon: <ExclamationCircleOutlined />,
					content: data.description,
					okText: 'Confirmar',
					cancelText: 'Cancelar',
					onOk() {
						setAlertDowngrade((current) => ({
							...current,
							confirmed: true,
							visible: false,
						}));
					},
					onCancel() {
						setAlertDowngrade((current) => ({
							...current,
							confirmed: false,
							visible: false,
						}));
					},
				});
			} catch (error) {
				throw error;
			}
		}
		if (alertDowngrade.visible) {
			getDifference(alertDowngrade);
		}
	}, [alertDowngrade]);

	useEffect(() => {
		async function getConsumedPlan() {
			try {
				const {
					data: {
						plan,
						change_plan,
						plan_consumed: {
							users,
							uploads,
							seconds,
							expiration,
							canceled_on,
						},
					},
				} = await Api.post('/plan/checking-limits');

				if (
					users === undefined ||
					uploads === undefined ||
					seconds === undefined
				) {
					throw new Error('Failed to fetch requested information');
				}

				setConsumedPlan({
					type: plan?.name,
					created_users: users,
					processed_videos: uploads,
					seconds_processed: Number(seconds),
					recurrence: plan?.recurrence || '-',
				});

				dispatch(updatePlanConsumed(plan));
				dispatch(
					updateUserSession({
						expiration_plan: expiration,
						canceled_on: canceled_on,
					})
				);

				return {};
			} catch (error) {
				if (error.response) {
					const messageError = error.response.data.message;
					message.error(messageError);
				} else {
					message.error('Falha ao buscar informações solicitadas');
				}
			}
		}

		getConsumedPlan();

		return () => setUpdateSignature(null);
	}, [updateSignature]);

	const warningDocument = () => {
		message.warning(I18n.get('Fill in your personal information'));
	};

	const onChangePassword = () => {
		setControlModalChangePassword((current) => !current);
	};

	const onUpdateCustomer = () => {
		setUpdateCustomer((current) => !current);
	};

	return (
		<Fragment>
			<UserInformation
				user={user}
				customer={customer}
				onChangePassword={onChangePassword}
				onUpdateCustomer={onUpdateCustomer}
			/>
			{/* <UpdateCustomer
				customer={customer}
				visible={updateCustomer}
				triggerReload={updatedInformations}
				onUpdateCustomer={onUpdateCustomer}
			/> */}
			<Row gutter={[8, 16]}>
				<ChangePassword
					onClose={onChangePassword}
					onControlVisible={controlModalChangePassword}
				/>
				{plan && (
					<>
						<Col sm={24}>
							<Title>{I18n.get('Current plan')}</Title>
						</Col>
						<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
							<Description>
								<label>{I18n.get('Subscription type')}</label>
							</Description>
							<Value>
								<label>{I18n.get(consumedPlan.type)}</label>
							</Value>
						</Col>
						<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
							<Description>
								<label>{I18n.get('Recurrence')}</label>
							</Description>
							<Value>
								<label>
									{I18n.get(consumedPlan.recurrence)}
								</label>
							</Value>
						</Col>
						<Col xs={24} sm={8} md={8} lg={12} xxl={12}>
							{user.expiration_plan && !user.canceled_on ? (
								<>
									<Description>
										<label>
											{I18n.get('Expiration date')}
										</label>
									</Description>
									<Value>
										<label>
											{moment(
												user.expiration_plan
											).format('DD/MM/YYYY')}
										</label>
									</Value>
								</>
							) : (
								<>
									<Description>
										<label>
											{I18n.get('Plan canceled in')}
										</label>
									</Description>
									<Value>
										<label>
											{moment(user.canceled_on).format(
												'DD/MM/YYYY'
											)}
										</label>
									</Value>
								</>
							)}
						</Col>
						<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
							<Description>
								<label>{I18n.get('Active users')}</label>
							</Description>
							<Value>
								<label>
									{consumedPlan.created_users}/
									{plan?.max_users || 0}
								</label>
							</Value>
						</Col>
						<Col xs={24} sm={8} md={8} lg={6} xxl={6}>
							<Description>
								<label>{I18n.get('Processed videos')}</label>
							</Description>
							<Value>
								<label>
									{consumedPlan.processed_videos}/
									{plan?.max_upload || 0}
								</label>
							</Value>
						</Col>
						<Col xs={24} sm={8} md={8} lg={12} xxl={12}>
							<Description>
								<label>{I18n.get('Processing time')}</label>
							</Description>
							<Value>
								<label>
									{moment
										.duration(
											consumedPlan.seconds_processed,
											'seconds'
										)
										.format('m')}{' '}
									de{' '}
									{moment
										.duration(
											plan?.max_minutes || 0,
											'seconds'
										)
										.format('m', {
											trim: false,
											useGrouping: false,
										})}{' '}
									minutos
								</label>
							</Value>
						</Col>
					</>
				)}
			</Row>
		</Fragment>
	);
}
