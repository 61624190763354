import React from 'react';
import { I18n } from '@aws-amplify/core';
import { TrueOrFalseList } from './TrueOrFalseList';

export function JobOrganization({ data, openActionPlan }) {
	const items = [
		{
			name: I18n.get('Are spontaneous pauses allowed during the workday?'),
			checked: data?.checklist?.interval,
		},
		{
			name: I18n.get(
				'Is there a diversification of tasks in the performance of your functions?'
			),
			checked: data?.checklist?.task_diversification,
		},
		{
			name: I18n.get(
				'Materials are always available at the workstation, avoiding rush, delay or overload?'
			),
			checked: data?.checklist?.missing_material,
		},
		{
			name: I18n.get('Intensity of work'),
			value: {
				1: I18n.get('High Risk and Requires Action'),
				2: I18n.get('Moderate risk and no action required'),
				3: I18n.get('Low Risk and No Action Required'),
			}[data?.work_intensity],
			action_plan: true,
		},
	];

	const title = I18n.get('Work organization');

	return <TrueOrFalseList title={title} items={items}  openActionPlan={openActionPlan} />;
}
