import React from 'react';
import _ from 'lodash';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, message, Input, Typography, Divider } from 'antd';
import { UpdateChecklistEhsCommentDTO, useUpdateChecklistEhsComment } from '@/hooks';

interface CommentProps {
	disableEditComment: boolean;
	file_id: string;
	checklist_id: string;
	company_id: string;
	organization_id: string;
	initialValue: string;
}

const { useForm } = Form;
const { TextArea } = Input;

export const Comment: React.FC<CommentProps> = ({
	disableEditComment,
	file_id,
	checklist_id,
	company_id,
	organization_id,
	initialValue
}) => {
	const [form] = useForm();
	const [, contextHolder] = message.useMessage();
	const { mutateAsync, isLoading } = useUpdateChecklistEhsComment();

	const setComment = _.debounce(async (value) => {
		await updateComment(value);
		message.success(I18n.get('Comment updated successfully'));
	}, 1200);

	async function updateComment(text: string) {
		const parameters: UpdateChecklistEhsCommentDTO = {
			file_id,
			company_id,
			checklist_id,
			comment: text,
			organization_id
		};

		const updatedComment = await mutateAsync(parameters);
		if (updatedComment?.id) {
			form.setFieldValue('comment', updatedComment.comment);
		}
	}

	function handleChange(text: string) {
		setComment(text);
	}

	return (
		<Form
			form={form}
			initialValues={{
				comment: initialValue
			}}
		>
			<Divider style={{ borderColor: '#00000026' }} />
			<Row gutter={[0, 24]} style={{ marginBottom: '4rem', marginTop: '3rem' }}>
				<Col xs={24}>
					<Row gutter={[16, 16]}>
						<Col xxl={2} xl={3} lg={24}>
							<Typography.Title style={{ margin: 0 }} level={5}>
								{I18n.get('Comment')}
							</Typography.Title>
							{contextHolder}
						</Col>
						<Col xxl={22} xl={21} lg={24}>
							<Form.Item name={['comment']} labelCol={{ xs: 24 }}>
								<TextArea
									rows={4}
									maxLength={500}
									showCount={true}
									onChange={(event) => handleChange(event.target.value)}
									disabled={isLoading || disableEditComment}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
